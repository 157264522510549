import {_hostMedia} from "../redux/api";
import { defaultEmptyLogo, defaultUserLogo} from "../assets/images";

export function generateUserImageMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${_hostMedia}/images${path}` : defaultUserLogo;
}
export function generateImageMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? encodeURI(`${_hostMedia}/images${path}`) : defaultEmptyLogo;
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? encodeURI(`${_hostMedia}/files${path}`) : defaultEmptyLogo;
}
