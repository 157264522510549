import { request, _urlBanner } from "../api";
import { BANNER_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetBanners = () => {
    const requestData = {
        url: _urlBanner,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: BANNER_CONSTS.GET_BANNERS,
                    payload: data
                });
            })
    }
};


export const GetBannerById = id => {
    const requestData = {
        url: `${_urlBanner}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: BANNER_CONSTS.GET_BANNER_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};
export const DeleteBannerById = id => {
    const requestData = {
        url: `${_urlBanner}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: BANNER_CONSTS.DELETE_BANNER_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateBanner = data => {
    const requestData = {
        url: _urlBanner,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BANNER_CONSTS.CREATE_BANNER,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Զեղչը հաջողությամբ ստեղծվեց!"
                })
                history.push('/banner')
            })
            .catch(() => {
            })
    }
};

export const UpdateBannerById = (id, data) => {
    const requestData = {
        url: `${_urlBanner}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BANNER_CONSTS.UPDATE_BANNER_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Զեղչը հաջողությամբ փոփոխվեց!"
                })
                history.push('/banner')
            })
            .catch(() => {
            })
    }
};

export const UpdateBannersPositions = items => {
    const requestData = {
        url: _urlBanner,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: BANNER_CONSTS.UPDATE_BANNERS_POSITIONS
                });
                notificationSuccess({
                    description: "Զեղչը հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};

export const clearBannerData = () => {
    return dispatch => {
        dispatch({
            type: BANNER_CONSTS.CLEAR_BANNER_DATA
        });
    }
};
