// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';

import Dashboard from '../containers/Dashboard';
import AddEditPost from "../containers/post/AddEditPost";

import NotFound from '../containers/NotFound';
import Contact from '../containers/Contact';

import Moderators from '../containers/Moderators/Moderators';
import AddEditModerator from "../containers/Moderators/AddEditModerator";

import Users from '../containers/users/Users';
import UserDetails from "../containers/users/UserDetails";

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";

import Slider from "../containers/slider/Slider";
import AddEditSlider from "../containers/slider/AddEditSlider";

import Category from "../containers/category/Category";
import AddEditCategory from "../containers/category/AddEditCategory";

import Filters from "../containers/filters/Filters";
import AddFilter from "../containers/filters/AddFilter";
import EditFilter from "../containers/filters/EditFilter";

import Attributes from "../containers/attributes/Attributes";
import AddAttribute from "../containers/attributes/AddAttribute";
import EditAttribute from "../containers/attributes/EditAttribute";

import Badges from "../containers/badges/Badges";
import AddEditBadge from "../containers/badges/AddEditBadges";

import Banner from "../containers/banner/Banner";
import AddEditBanner from "../containers/banner/AddEditBanner";

import StaticTexts from "../containers/staticTexts/StaticTexts";
import CustomPages from "../containers/customPages/CustomPages";
import AddEditCustomPages from "../containers/customPages/AddEditCustomPages";

import Information from "../containers/information/Information";
import EditBlock from "../containers/information/EditHeaderBlock";

import Subscribe from "../containers/subscribe/Subscribe";

import RoomTypes from "../containers/roomTypes/RoomTypes";
import AddEditRoomType from "../containers/roomTypes/AddEditRoomType";
import FAQ from "../containers/faq/FAQ";
import AddEditFAQ from "../containers/faq/AddEditFAQ";
import Notifications from "../containers/notification/Notifications";
import Reservations from "../containers/reservations/reservations";


export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/posts" component={Dashboard}/>
                    <ProtectedRoute exact path="/posts/add" component={AddEditPost}/>
                    <ProtectedRoute exact path="/posts/edit/:id" component={AddEditPost}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/users" component={Users}/>
                    <ProtectedRoute exact path="/users/user/:id" component={UserDetails}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/categories" component={Category}/>
                    <ProtectedRoute exact path="/categories/add" component={AddEditCategory}/>
                    <ProtectedRoute exact path="/categories/edit/:id" component={AddEditCategory}/>

                    <ProtectedRoute exact path="/banner" component={Banner}/>
                    <ProtectedRoute exact path="/banner/add" component={AddEditBanner}/>
                    <ProtectedRoute exact path="/banner/edit/:id" component={AddEditBanner}/>

                    <ProtectedRoute exact path="/roomTypes" component={RoomTypes}/>
                    <ProtectedRoute exact path="/roomTypes/add" component={AddEditRoomType}/>
                    <ProtectedRoute exact path="/roomTypes/edit/:id" component={AddEditRoomType}/>

                    <ProtectedRoute exact path="/badges" component={Badges}/>
                    <ProtectedRoute exact path="/badges/add" component={AddEditBadge}/>
                    <ProtectedRoute exact path="/badges/edit/:id" component={AddEditBadge}/>

                    <ProtectedRoute exact path="/slider" component={Slider}/>
                    <ProtectedRoute exact path="/slider/add" component={AddEditSlider}/>
                    <ProtectedRoute exact path="/slider/edit/:id" component={AddEditSlider}/>

                    <ProtectedRoute exact path="/filters" component={Filters}/>
                    <ProtectedRoute exact path="/filters/add" component={AddFilter}/>
                    <ProtectedRoute exact path="/filters/edit/:id" component={EditFilter}/>

                    <ProtectedRoute exact path="/attributes" component={Attributes}/>
                    <ProtectedRoute exact path="/attributes/add" component={AddAttribute}/>
                    <ProtectedRoute exact path="/attributes/edit/:id" component={EditAttribute}/>

                    <ProtectedRoute exact path="/customPages" component={CustomPages}/>
                    <ProtectedRoute exact path="/customPages/add" component={AddEditCustomPages}/>
                    <ProtectedRoute exact path="/customPages/edit/:id" component={AddEditCustomPages}/>

                    <ProtectedRoute exact path="/faq" component={FAQ}/>
                    <ProtectedRoute exact path="/faq/add" component={AddEditFAQ}/>
                    <ProtectedRoute exact path="/faq/edit/:id" component={AddEditFAQ}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <ProtectedRoute exact path="/information" component={Information}/>
                    <ProtectedRoute exact path="/information/:type/:id" component={EditBlock}/>

                    <ProtectedRoute exact path="/subscribe" component={Subscribe}/>
                    <ProtectedRoute exact path="/notifications" component={Notifications}/>

                    <ProtectedRoute exact path="/reservations" component={Reservations}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
