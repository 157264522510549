//media modal
export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};

export const MEDIA_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
};

export const UPLOAD_TYPES = {
    SELF: 'SELF',
    URL: 'URL',
};

const STATUS_COLORS = {
    NEW: '#FF9112',
    CANCELLED: '#828B99',
    APPROVED: '#13C712',
    REJECTED: '#FF1212',
};
export const REVIEW_STATUS_TYPES = {
    INITIAL: {
        key: 'INITIAL',
        title: 'Նոր',
        color: STATUS_COLORS.NEW
    },
    APPROVED: {
        key: 'APPROVED',
        title: 'Հաստատված',
        color: STATUS_COLORS.APPROVED
    },
    REJECTED: {
        key: 'REJECTED',
        title: 'Մերժված',
        color: STATUS_COLORS.REJECTED
    },
};

export const STATUS_LIST = {
    NEW: {
        title: "Հաստատել",
        key: '5ee8dd7c4ac0e93f4336fcda',
        color: STATUS_COLORS.NEW,
    },
    CANCELLED: {
        title: 'Չեղարկված',
        key: '5ee8dda24ac0e93f4336fcdd',
        color: STATUS_COLORS.CANCELLED,
    },
    APPROVED: {
        title: 'Հաստատված',
        key: '5ee8dd984ac0e93f4336fcdb',
        color: STATUS_COLORS.APPROVED,
    },
    REJECTED: {
        title: 'Մերժված',
        key: '5ee8dda24ac0e93f4336fcdc',
        color: STATUS_COLORS.REJECTED,
    },
};

export const MEASUREMENT_TYPES = {
    LITER: 'Լ',
    KILOGRAM: 'Կգ',
    PIECES: 'Հատ',
    BOX: 'Արկղ',
};

export const PROMO_CODE_STATUS = {
    USED: "Օգտագործված",
    OPEN: "Չօգտագործված"
};


export const SLIDER_RESOURCE_TYPES = {
    PRODUCT: {
        title: 'Ապրանք',
        key: 'PRODUCT',
    },
    MARKET: {
        title: 'Մինի-Մարկետ',
        key: 'MARKET',
    },
    BANNER:{
        title: 'Զեղչ',
        key: 'BANNER',
    },
    CUSTOM_PAGE:{
        title: 'Դինամիկ Էջ',
        key: 'CUSTOM_PAGE',
    },
};

export const ADVERTISING_RESOURCE_TYPES = {
    PRODUCT: {
        title: 'Ապրանք',
        key: 'PRODUCT',
    },
    URL: {
        title: 'Հղում',
        key: 'URL',
    },
    CUSTOM_PAGE:{
        title: 'Դինամիկ Էջ',
        key: 'CUSTOM_PAGE',
    },
};

export const HISTORY_TYPES = {
    BONUS_FROM_ORDER: 'BONUS_FROM_ORDER',
    ORDER_VIA_BONUS_CARD: 'ORDER_VIA_BONUS_CARD'
};

export const POST_TYPES = {
    SELL: {
        key: 'SELL',
        name: 'Վաճառվող'
    },
    RENT: {
        key: 'RENT',
        name: 'Վարձով'
    },
};

export const CONDITION_TYPES = {
    NEW_HOME: {
        key: 'NEW_HOME',
        name: 'Նորակառույց'
    },
    GOOD_CONDITION: {
        key: 'GOOD_CONDITION',
        name: 'Վերանորոգված'
    },
    NEED_RENOVATION: {
        key: 'NEED_RENOVATION',
        name: 'Չվերանորոգված'
    },
};

export const PRICE_TYPES = {
    MONTHLY: 'Ամսեկան',
    DAILY: 'Օրական',
};

export const CONTACT_PHONE_TYPES = {
    MOBILE: {
        key: 'MOBILE',
        name: 'Բջջային'
    },
    OFFICE: {
        key: 'OFFICE',
        name: 'Աշխատանքային'
    },
    HOME: {
        key: 'HOME',
        name: 'Քաղաքային'
    },
};


export const CURRENCIES = {
    AMD: {
        key: "AMD",
        suffix: '֏',
    },
    USD: {
        key: 'USD',
        suffix: '$',
    },
    RUB: {
        key: "RUB",
        suffix: '₽',
    },
};
