import moment from "moment";
import {REVIEW_STATUS_TYPES} from "../../constants/constsTypes";


export const reviewListHeaderInfo = {
    generalInfo: ["Ապրանք", "Անուն", "Էլ․ հասցե", "Գնահատական", "Ամսաթիվ", "Մեկնաբանություն"],
    largeItems: [2, 5],
    status: true,
    sizePercentage: 1.7,
    actions: ['delete', 'details']
};

export function reviewsDataParser(reviews, deleteReview) {
    return reviews?.itemsList && Array.isArray(reviews?.itemsList) && reviews.itemsList.map((review) => {
        const date = review?.createdAt ? moment(review?.createdAt).format("HH:MM:DD / HH:mm") : '-';
        return {
            id: review?.id,
            newItem: !(review?.seen ?? true),
            status: {
                title: REVIEW_STATUS_TYPES[review.status]?.title || '_',
                color: REVIEW_STATUS_TYPES[review.status]?.color || '',
            },
            deleteCb: deleteReview && deleteReview.bind(null, review?.id),
            values: [
                review?.product?.title || '-',
                review?.reviewer?.firstName ? review?.reviewer?.firstName + " " + review?.reviewer?.lastName : '-',
                review?.reviewer?.email || '-',
                review?.rate || '-',
                date,
                review?.text || "-",
            ],
        }
    });
}
