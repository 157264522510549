import {ATTRIBUTE_CONSTS} from "../constants";

export const initialState = {
    attributesList: [],
    attributeById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ATTRIBUTE_CONSTS.GET_ATTRIBUTES:
            return {
                ...state,
                attributesList: action.payload || [],
            };
        case ATTRIBUTE_CONSTS.GET_ATTRIBUTE_BY_ID:
            return {
                ...state,
                attributeById: action.payload
            };
        case ATTRIBUTE_CONSTS.CREATE_ATTRIBUTE:
            return {
                ...state,
                attributesList: [action.payload].concat(state.attributesList)
            };
        case ATTRIBUTE_CONSTS.DELETE_ATTRIBUTE_BY_ID:
            return {
                ...state,
                attributesList: state.attributesList.attribute(attribute => attribute.id !== action.payload)
            };
        default:
            return state;
    }
}
