// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetBanners,
    DeleteBannerById,
    UpdateBannersPositions
} from "../../redux/actions";


class Banners extends Component {
    constructor(props) {
        super(props);
        this.deleteItem = deleteItem.bind(this, props.DeleteBannerById, 'Զեղչը');
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        this.props.GetBanners();
    }

    getNestableItems(list, parent = null) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `/banner/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedSliderId = item.id;
        const {bannerList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~bannerList.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateBannersPositions(positionedItems);
            this.props.GetBanners();
        }
    }

    render() {
        let {bannerList} = this.props;
        let items = this.getNestableItems(bannerList);
        return <PageWrapper pageTitle={'Մինի-Մարկետներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/banner/add"}/>
            {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    maxDepth={1}
                    onChange={this.onPositionChange}
                    renderItem={NestableItem}
                />}
        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetBanners,
    DeleteBannerById,
    UpdateBannersPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
