import React, {useEffect} from "react";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {DeleteVisitDataById, GetVisitData} from "../../redux/actions";
import moment from "moment";
import {deleteItem} from "../../utils/helperFunctions";

function Reservations(props) {
    const {visitModelItems} = props


    useEffect(() => {
        props.GetVisitData()
    },[])

    let itemListHeaderInfo = {
        generalInfo: ["Անուն Ազգանուն","Հեռախոս","Ժամ","Ամսաթիվ","Բնակարան"],
        actions: ["delete"],
    };
    let itemListInfo = visitModelItems?.map((items) => {

        const {id,fullName,phoneNumber,time,date,post} = items;
        return {
            id,
            values: [
                fullName,
                phoneNumber,
                time,
                moment(date).format('DD/MM/YYYY'),
                post?.title
            ],
            deleteCb: deleteItem.bind(this, props.DeleteVisitDataById,'հայտը',id)
        }
    });
    return <PageWrapper pageTitle={'Հայտեր'}>
        <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                   itemListInfo={itemListInfo}
                   infiniteScroll={true}/>
    </PageWrapper>
}

const mapDispatchToProps = {
    GetVisitData,
    DeleteVisitDataById,
};
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'visitModelItems'
    ])
}
export default connect(mapStateToProps, mapDispatchToProps)(Reservations);