import {FAQ_CONSTS} from "../constants";

export const initialState = {
    faqList: [],
    faqById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FAQ_CONSTS.GET_FAQS:
            return {
                ...state,
                faqList: action.payload || [],
            };
        case FAQ_CONSTS.CREATE_FAQ:
            return {
                ...state,
                faqList: [action.payload].concat(state.faqList)
            };
        case FAQ_CONSTS.DELETE_FAQ_BY_ID:
            return {
                ...state,
                faqList: state.faqList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
