import React from "react";
import {Skeleton} from "antd";
import '../../assets/styles/components/skelaton-item.scss';

export const SkeletonSmallItem = ({loading}) => {
    return <div className={'skelton-small-item'}>
        <Skeleton loading={true}
                  paragraph={{
                      rows: 1,
                      width: "50"
                  }}
                  active
        />
    </div>
}
