import moment from "moment";
import {HISTORY_TYPES} from "../../constants/constsTypes";

export const bonusCardsHistoryHeaderInfo = {
    generalInfo: ["Տիպ", "Համար", "Օգտատեր", "Բոնուսի չափ", "Գումար", "Պատվերի համար", "Ամսաթիվ"],
};

export const bonusCardsHistoryTypes = {
    BONUS_FROM_ORDER: 'Բոնուս պատվերից',
    ORDER_VIA_BONUS_CARD: 'Պատվեր բոնուս քարտով'
}


export function parseBonusCardHistoryData(historyData) {
    //console.log('historyData', historyData)
    return historyData?.itemsList?.map(history => {
        //console.log('history', history)
        const {user, order, transactionNumber, type, amount, sum, date} = history || {};
        return historyData = {
            id: history.id,

            values: [
                bonusCardsHistoryTypes[type] || '-',
                transactionNumber ?? '-',
                {
                    title:  `${user?.firstName || "-"} ${user?.lastName || "-"}`,
                    redirect: user?.id && `/users/user/${user?.id}`,
                },

                type === HISTORY_TYPES.BONUS_FROM_ORDER ? amount : '-' + amount || '-',
                sum || '-',
                {
                    title: order?.counter || '-',
                    redirect: order?.id && `/orders/order/${order?.id}`,
                },
                moment(date).format("DD.MM.YY / HH:mm"),
            ],
        };
    });
}
