import {request, _urlFaq} from "../api";
import {FAQ_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetFaqs = () => {
    const requestData = {
        url: _urlFaq,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: FAQ_CONSTS.GET_FAQS,
                    payload: data
                });
            })
    }
};


export const DeleteFaqById = id => {
    const requestData = {
        url:  `${_urlFaq}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FAQ_CONSTS.DELETE_FAQ_BY_ID,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreateFaq = data => {
    const requestData = {
        url: _urlFaq,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FAQ_CONSTS.CREATE_FAQ,
                    payload: res.data
                });
                notificationSuccess({
                    description: "FAQ հաջողությամբ ստեղծվեց!"
                })
                history.push('/faq')
            })
            .catch(() => {
            })
    }
};

export const UpdateFaqById = (id, data) => {
    const requestData = {
        url: `${_urlFaq}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FAQ_CONSTS.UPDATE_FAQ_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "FAQ հաջողությամբ փոփոխվեց!"
                })
                history.push('/faq')
            })
            .catch(() => {
            })
    }
};

export const UpdateFaqsPositions = items => {
    const requestData = {
        url: _urlFaq,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FAQ_CONSTS.UPDATE_FAQS_POSITIONS
                });
                notificationSuccess({
                    description: "FAQ հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};
