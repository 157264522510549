// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Checkbox, Switch} from "antd";
import {MaterialIcon} from "../../components/utils/Icon";
import {history} from "../../configs/history";

// Import Styles
import '../../assets/styles/containerStyles/filter-attr-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField,
    getInputValues,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetAttributeById,
    UpdateAttributeById,
    CreateAttributeValue,
    DeleteAttributeValueById,
    UpdateAttributeValueById,
} from "../../redux/actions";

import {validateTranslations} from "../../utils/validateTranslations";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";


class EditAttribute extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                isHidden: false,
                icon: undefined,
                translations: initTranslations(this.translationsFields, props.activeLanguages),
            },
            isEditing: !!props?.id,
            errors: {},
            errorsTabs: [],
            languageTab: props.mainLanguage,
            mediaModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.removedIds = [];
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addAttributeValue = this.addAttributeValue.bind(this);
        this.removeAttributeValue = this.removeAttributeValue.bind(this);
        this.getAttributeValues = this.getAttributeValues.bind(this);
        this.editAttribute = this.editAttribute.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
    }

    async componentDidMount() {
        if (this.props?.id) {
            await this.props.GetAttributeById(this.props.id);
            const editingData = this.props.attributeById;
            const translationsData = {...this.state.fieldsData.translations};
            let initValues = [];

            this.props.activeLanguages.forEach(lg => {
                const TrItem = editingData?.translations.find(tr => tr.languageId === lg.id);
                translationsData[lg.id] = {
                    title: TrItem?.title || '',
                };

            });

            editingData.values?.forEach(value => {
                let valueTR = {};
                this.props.activeLanguages.forEach(lg => {
                    const valueTr = value?.translations.find(tr => tr.languageId === lg.id);
                    valueTR[lg.id] = {
                        title: valueTr?.title || '',
                    };
                });
                initValues.push({
                    isHidden: value.isHidden,
                    position: value?.position,
                    icon:value,
                    id: value.id,
                    translations: valueTR,
                })
            });

            this.currentData = {
                isHidden: editingData.isHidden,
                icon:editingData?.icon,
                translations: translationsData,
            };
            this.setState({
                fieldsData: JSON.parse(JSON.stringify(this.currentData)),
            });

        } else {
            history.push('/attributes');
        }
    }

    getAttributeValues({name, value, index}) {
        const {languageTab} = this.state;
        const {values, isHidden} = this.state.fieldsData;
        let attributeIsHidden = isHidden;

        const editingValue = values[index];
        let newAttributesList = [...values];

        if (name === 'isHidden') {
            if (editingValue.id) {
                this.currentData?.values?.find(v => v.id === editingValue.id)?.isHidden !== !values?.[index]?.isHidden
                    ? this.updatedDataMap.set(`${editingValue.id}_value`, editingValue.id)
                    : this.updatedDataMap.delete(`${editingValue.id}_value`);
            }
            newAttributesList[index].isHidden = !newAttributesList[index].isHidden;
            const allAttributesDisabled = newAttributesList.every(f => f.isHidden);
            attributeIsHidden = allAttributesDisabled ? true : isHidden;
            if (allAttributesDisabled) {
                this.updatedDataMap.set(`isHidden`, true)
            }
        } else {
            if (editingValue.id) {
                // console.log(this.currentData?.values)
                this.currentData?.values?.find(v =>
                    v.id === editingValue.id
                )?.translations?.[languageTab]?.title !== value
                    ? this.updatedDataMap.set(`${editingValue.id}_value_${languageTab}`, editingValue.id)
                    : this.updatedDataMap.delete(`${editingValue.id}_value_${languageTab}`);
            }
            newAttributesList[index].translations[languageTab].title = value;
        }
// console.log(this.updatedDataMap)
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: newAttributesList,
                isHidden: attributeIsHidden
            }
        })
    }

    addAttributeValue() {
        const {values} = this.state.fieldsData;
        let position = 0;
        values.forEach(v => {
            if (v.position > position) {
                position = v.position
            }
        })
        let attributeItem = {
            translations: initTranslations(this.translationsFields, this.props.activeLanguages),
            isHidden: false,
            position: position + 1,
            fakeId: Date.now()
        };
        this.updatedDataMap.set(`newItem_${attributeItem.fakeId}`, attributeItem.fakeId)
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: [...this.state.fieldsData.values, attributeItem]
            },
        })
    }

    removeAttributeValue(position) {
        const {values} = this.state.fieldsData;
        let newAttributesList = values.filter((item, index) => {
            if (index === position) {
                if (item?.id) {
                    this.removedIds.push(item.id);
                    this.updatedDataMap.set('deleted-item', null)
                } else {
                    this.updatedDataMap.delete(`newItem_${item.fakeId}`)
                }
            }
            return index !== position
        });
        this.setState({
            ...this.state,
            fieldsData: {
                ...this.state.fieldsData,
                values: newAttributesList,
            },
        })
    }

    getMedia (mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.icon?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('icon', mediaArray[0]?.id)
                : this.updatedDataMap.delete('icon');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: mediaArray[0],
            },
            errors: {
                ...errors,
                icon: false
            }
        })
    }

    deleteMedia () {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.icon ?
                this.updatedDataMap.set("icon", null) :
                this.updatedDataMap.delete("icon");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: null
            }
        })
    }

    async editAttribute() {
        const {fieldsData} = this.state;
        const {translations, isHidden,icon} = fieldsData;
        await this.setState({
            requestLoading: true
        });
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = validationTr.errors;
        let TR_data = getTranslationData(translations);

        if (!icon) {
            errors.icon = true;
            result = false;
        }

        if (result) {
            const reqData = {
                isHidden,
                icon:icon?.id,
                translations: TR_data
            };
            await this.props.UpdateAttributeById(this.props.id,reqData)
                .finally(() => {
                    this.setState({requestLoading: false});
                    history.push('/attributes');
                });
        } else {
            this.setState({
                requestLoading: false,
                errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, languageTab, requestLoading,mediaModalOpen, errors, errorsTabs} = this.state;
        const currentData = fieldsData.translations[languageTab] || {};
        const initData = this.currentData?.translations[languageTab] || {};
        const initValues = this.currentData?.values || {};
        return <PageWrapper pageTitle={'Փոփոխել Բնութագիրը'}>
            <section className="items-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>

                <div className="top-side">
                    <label>պատկեր <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.icon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.icon}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                              errorsTabs={errorsTabs}
                />
                <div className="section-wrapper">
                    <div className="section-left-part">
                        <div className="w-100">
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Անվանում"
                                        placeholder="Անվանում"
                                        name="title"
                                        value={currentData?.title}
                                        initValue={initData?.title}
                                        error={errors['title' + languageTab]}
                                        maxLength={100}
                                        required={true}
                                        onChange={this.getTranslationValues}/>
                        </div>
                    </div>
                    {/*<div className="section-items">*/}
                    {/*    <label className={'label'}>Բնութագրեր <span className={'required-badge'}>*</span></label>*/}
                    {/*    {fieldsData?.values?.map((attribute, index) => {*/}
                    {/*        return <div className="item-row" key={index}>*/}
                    {/*            <InputGroup*/}
                    {/*                value={attribute.translations?.[languageTab]?.title}*/}
                    {/*                initValue={initValues?.[index]?.translations[languageTab]?.title}*/}
                    {/*                error={errors['value' + index + languageTab]}*/}
                    {/*                name={index}*/}
                    {/*                inputType={"input"}*/}
                    {/*                placeholder={"Բնութագիր"}*/}
                    {/*                maxLength={100}*/}
                    {/*                onChange={(data) => this.getAttributeValues({...data, index})}*/}
                    {/*            />*/}

                    {/*            <div className="checkbox-wrapper">*/}
                    {/*                <Checkbox checked={!attribute.isHidden}*/}
                    {/*                          onChange={() => this.getAttributeValues({*/}
                    {/*                              name: "isHidden",*/}
                    {/*                              index,*/}
                    {/*                          })}/>*/}
                    {/*            </div>*/}
                    {/*            {fieldsData?.values?.length > 1*/}
                    {/*            && <LinkButton className="bg-red delete-item-btn"*/}
                    {/*                           title={<MaterialIcon icon="delete"/>}*/}
                    {/*                           cb={() => this.removeAttributeValue(index)}/>}*/}

                    {/*        </div>*/}
                    {/*    })}*/}
                    {/*    <LinkButton title={<MaterialIcon icon="add"/>}*/}
                    {/*                cb={this.addAttributeValue}/>*/}
                    {/*</div>*/}


                </div>

                <div className="flex-wrapper-right">
                    <LinkButton
                        title={"Փոփոխել"}
                        loading={requestLoading}
                        disabled={!this.updatedDataMap.size}
                        cb={this.editAttribute}
                    />
                </div>

            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAttributeById,
    UpdateAttributeById,
    CreateAttributeValue,
    DeleteAttributeValueById,
    UpdateAttributeValueById,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAttribute)
