// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

//import styles
import '../../assets/styles/containerStyles/information.scss';

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {Editor} from "../../components/uiElements/Editor";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetInformationData, UpdateInformationData} from "../../redux/actions";
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues, getTranslationData, initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {validateTranslations} from "../../utils/validateTranslations";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";

class Information extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'pageName',
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaArray: [],
                backgroundImage: undefined,
                realEstateCount: '',
                commercialsCount: '',
                apartmentsCount: '',
                workersCount: '',
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleBgMediaModal = toggleStateField.bind(this, 'bgMediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setInformationData = this.setInformationData.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.checkMediaListDifferent = this.checkMediaListDifferent.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getBgMedia = this.getBgMedia.bind(this);
        this.updateInformation = this.updateInformation.bind(this);
    }

    async componentDidMount() {
        await this.props.GetInformationData()
        const {information} = this.props;
        this.setInformationData(information)
    }

    setInformationData(information) {
        const {fieldsData} = this.state;
        const translationsData = {};

        if (information) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = information.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    pageName: trItem?.pageName || '',
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
            mediaArray: information?.mediaArray,
            backgroundImage: information?.backgroundImage,
            realEstateCount: information?.realEstateCount || '',
            commercialsCount: information?.commercialsCount || '',
            apartmentsCount: information?.apartmentsCount || '',
            workersCount: information?.workersCount || '',
        }
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }

    getListInfo(data) {
        let {mainLanguage} = this.props;
        return data?.map((item,index) => {
            const {slug, translations} = item;
            const mainTranslations = translations?.find((item) => item.languageId === mainLanguage);
            const {title} = mainTranslations || {};
            return {
                id:index,
                values: [
                    title ?? '-',
                ],
            }
        });
    }

    onMediaPositionChange(items) {
        const {fieldsData, isEditing} = this.state;
        const mediaArray = items.map(item => item.media);
        if (isEditing) {
            this.checkMediaListDifferent(mediaArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaArray: mediaArray
            }
        })
    }

    checkMediaListDifferent(currMedias) {
        let result = false;
        const initMedias = this.currentData.mediaArray;
        if (!Array.isArray(currMedias) || (currMedias?.length !== initMedias.length)) {
            result = true;
        } else {
            currMedias.forEach((item, index) => {
                if (initMedias[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }
        result ? this.updatedDataMap.set('mediaArray', currMedias?.map(m => m.id))
            : this.updatedDataMap.delete('mediaArray');
    }

    deleteMedia(id) {
        // console.log(id,'id')
        const {fieldsData, isEditing} = this.state;
        let mediaArray = fieldsData.mediaArray.filter(m => m.id !== id);

        if (isEditing) {
            this.checkMediaListDifferent(mediaArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaArray: mediaArray,
            }
        })
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.checkMediaListDifferent(mediaArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaArray: mediaArray,
            },
        })
    }

    getBgMedia (mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.backgroundImage?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('backgroundImage', mediaArray[0]?.id)
                : this.updatedDataMap.delete('backgroundImage');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                backgroundImage: mediaArray[0],
            },
            errors: {
                ...errors,
                backgroundImage: false
            }
        })
    }

    mediaCardWrapper(data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia}/>
    }

    async updateInformation() {
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaArray, backgroundImage} = this.state.fieldsData;
        const translationRequiredFields = ['pageName', 'title', 'description']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaArray) {
            errors.mediaArray = true;
            result = false;
        }
        if (!backgroundImage) {
            errors.backgroundImage = true;
            result = false;
        }
        if (result) {
            const trData = getTranslationData(translations);
            const reqData = this.getPatchReqData(trData);
            this.props.UpdateInformationData(reqData).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setInformationData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({requestLoading: false});
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {information} = this.props;
        const {requestLoading, mediaModalOpen,bgMediaModalOpen, fieldsData, errors, languageTab, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [0],
            actions: ["edit"],
        };
        const blocksListInfo = this.getListInfo(information?.blocks);

        const mediaItems = [];

        fieldsData.mediaArray.forEach(media => {
            mediaItems.push({
                id: media?.id,
                media: media
            })
        });

        return <PageWrapper pageTitle={'Տվյալներ'}>
            <section className="about">
                <div className={'posts-medias-list-wrapper '}>
                    <div className={'label-and-action'}>
                        <label>Նկարներ</label>
                        <LinkButton title="Ընտրել Մեդիադարանից"
                                    className={`media-select-btn`}
                                    cb={this.toggleMediaModal}/>
                    </div>
                    <section className={'items-wrapper media-items'}>
                        {mediaItems.length ? <Nestable
                                items={mediaItems}
                                maxDepth={1}
                                onChange={this.onMediaPositionChange}
                                renderItem={this.mediaCardWrapper}
                            /> :
                            <p className={'empty-text'}>Նկարներ չկան</p>}
                    </section>
                </div>

                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.backgroundImage ? 'media-select-error' : ''}`}
                                cb={this.toggleBgMediaModal}
                    />
                </div>
                {fieldsData?.backgroundImage && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.backgroundImage}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <div className="info-wrapper">
                    <div className={'numbers-part'}>
                        <div className="left-part">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"ԱՆՇԱՐԺ ԳՈՒՅՔ"}
                                        placeholder="ԱՆՇԱՐԺ ԳՈՒՅՔ"
                                        name="realEstateCount"
                                        integerNumber={true}
                                        value={fieldsData?.realEstateCount}
                                        required={true}
                                        initValue={this.currentData?.realEstateCount}
                                        error={errors?.realEstateCount}
                                        onChange={this.getInputValues}/>

                            <InputGroup inputType="input"
                                        type="text"
                                        label={"ԲՆԱԿԱՐԱՆՆԵՐ"}
                                        placeholder="ԲՆԱԿԱՐԱՆՆԵՐ"
                                        name="apartmentsCount"
                                        integerNumber={true}
                                        value={fieldsData?.apartmentsCount}
                                        required={true}
                                        initValue={this.currentData?.apartmentsCount}
                                        error={errors?.apartmentsCount}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="right-part">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"ԿՈՄԵՐՑԻՈՆ"}
                                        placeholder="ԿՈՄԵՐՑԻՈՆ"
                                        name="commercialsCount"
                                        integerNumber={true}
                                        value={fieldsData?.commercialsCount}
                                        required={true}
                                        initValue={this.currentData?.commercialsCount}
                                        error={errors?.commercialsCount}
                                        onChange={this.getInputValues}/>

                            <InputGroup inputType="input"
                                        type="text"
                                        label={"ԱՇԽԱՏԱԿԻՑՆԵՐ"}
                                        placeholder="ԱՇԽԱՏԱԿԻՑՆԵՐ"
                                        name="workersCount"
                                        integerNumber={true}
                                        value={fieldsData?.workersCount}
                                        required={true}
                                        initValue={this.currentData?.workersCount}
                                        error={errors?.workersCount}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Էջի Անվանում"
                                placeholder="Էջի Անվանում"
                                name="pageName"
                                value={currentData.pageName}
                                initValue={initData.pageName}
                                error={errors['pageName' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    {/*<InputGroup inputType="input"*/}
                    {/*            type="text"*/}
                    {/*            label="Ենթավերնագիր"*/}
                    {/*            placeholder="Ենթավերնագիր"*/}
                    {/*            name="description"*/}
                    {/*            value={currentData.description}*/}
                    {/*            initValue={initData.description}*/}
                    {/*            error={errors['description' + languageTab]}*/}
                    {/*            maxLength={1000}*/}
                    {/*            required={true}*/}
                    {/*            onChange={this.getTranslationValues}/>*/}
                    <div className="editor-wrapper">
                        <label>Նկարագրություն <span className={'required-badge'}>*</span></label>
                        <Editor value={currentData?.description || ''}
                                name={'description'}
                                initValue={initData?.description}
                                error={errors['description' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateInformation}/>
                </div>

                <label className={'list-label'}>Բաժիններ</label>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={blocksListInfo}
                           loading={requestLoading}
                           editLink={"/information/block"}/>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                multiSelect={true}
                closeModal={this.toggleMediaModal}
            />
            <MediaSelectorModal
                isOpen={!!bgMediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getBgMedia}
                multiSelect={true}
                closeModal={this.toggleBgMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetInformationData,
    UpdateInformationData
};

export default connect(mapStateToProps, mapDispatchToProps)(Information)
