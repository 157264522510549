export const userDetailsTabs = [
    {
        name: "Պատվերներ",
        key: "orders"
    },
    // {
    //     name: "Մեկնաբանություններ",
    //     key: "reviews"
    // },
    // {
    //     name: "Բոնուս քարտի պատմություն",
    //     key: "history"
    // },
];
