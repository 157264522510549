//--------------------------------------- Official-server --------------------------------------------
const OFFICIAL_URLS = {
    AUTH_URL: "https://app.aprealty.am/auth/api/v1",
    USER_URL: "https://app.aprealty.am/user/api/v1",
    ADMIN_URL: "https://app.aprealty.am/admin/api/v1",
    FILE_MANAGER_URL: "https://app.aprealty.am/resources/api/v1",
    SOCKET_URL: "",
    HOST_MEDIA_URL: "https://app.aprealty.am/resources",
}
//--------------------------------------- 4Steps-server --------------------------------------------

const TESTING_URLS = {
    AUTH_URL: "http://173.249.20.192:8800/api/v1",
    USER_URL: "http://173.249.20.192:8801/api/v1",
    ADMIN_URL: "http://173.249.20.192:8802/api/v1",
    FILE_MANAGER_URL: "http://173.249.20.192:8803/api/v1",
    SOCKET_URL: "",
    HOST_MEDIA_URL: "http://173.249.20.192:8803",
}
const { AUTH_URL, USER_URL, ADMIN_URL, FILE_MANAGER_URL, SOCKET_URL, HOST_MEDIA_URL, } = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? OFFICIAL_URLS
    : TESTING_URLS;

export const _urlOauth = AUTH_URL + "/jwt";
export const _urlMedia = FILE_MANAGER_URL + "/admin/files";
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlUsers = ADMIN_URL + "/users";
export const _urlRegion = USER_URL + "/regions";
export const _urlOthers = ADMIN_URL + "/others";
export const _urlContact = ADMIN_URL + "/contacts";
export const _urlBadges = ADMIN_URL + "/badges";
export const _urlNotif = ADMIN_URL + "/notifications";
export const _urlModerator = ADMIN_URL + "/moderators";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlFilters = ADMIN_URL + "/filters";
export const _urlAttributes = ADMIN_URL + "/attributes";
export const _urlCategories = ADMIN_URL + "/categories";
export const _urlSlider = ADMIN_URL + "/slider";
export const _urlProject = ADMIN_URL + "/project";
export const _urlPosts = ADMIN_URL + "/posts";
export const _urlCustomPages = ADMIN_URL + "/custom-pages";
export const _urlInformation = ADMIN_URL + "/information";
export const _urlRequest = ADMIN_URL + "/request";
export const _urlSubscribe = ADMIN_URL + "/subscribe";
export const _urlRoomTypes = ADMIN_URL + "/room-types";
export const _urlBanner = ADMIN_URL + "/banners";
export const _urlFaq = ADMIN_URL + "/faq";
export const _urlVisitReservation = ADMIN_URL + "/visit-requests";

export const _urlSocket = SOCKET_URL;
export const _hostMedia = HOST_MEDIA_URL;

