import {BADGE_CONSTS} from "../constants";

export const initialState = {
    badgesList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BADGE_CONSTS.GET_BADGES:
            return {
                ...state,
                badgesList: action.payload || [],
            };
        case BADGE_CONSTS.CREATE_BADGE:
            return {
                ...state,
                badgesList: [action.payload].concat(state.badgesList)
            };
        case BADGE_CONSTS.UPDATE_BADGE:
            return {
                ...state,
                badgesList: state.badgesList.map(item => item.id === action.payload.id
                    ? action.payload : item)
            };
        case BADGE_CONSTS.DELETE_BADGE_BY_ID:
            return {
                ...state,
                badgesList: state.badgesList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
