// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateFaq, UpdateFaqById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {SLIDER_RESOURCE_TYPES} from "../../constants/constsTypes";

class AddEditFAQ extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'question',
            'answer',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addEditFAQ = this.addEditFAQ.bind(this);

        this.faqResourceOptions = Object.keys(SLIDER_RESOURCE_TYPES).map(key => {
            return {
                id: SLIDER_RESOURCE_TYPES[key].key,
                name: SLIDER_RESOURCE_TYPES[key].question,
            }
        })
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        if (isEditing) {
            const translationsData = {};
            const {faqList} = this.props;
            const editingData = faqList.find(faq => faq.id === this.props.id);
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        question: trItem?.question || '',
                        answer: trItem?.answer || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    async addEditFAQ() {
        await this.setState({
            requestLoading: true
        });
        const {translations, isHidden} = this.state.fieldsData;
        const translationRequiredFields = ['question', 'answer']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    translations: getTranslationData(translations)
                };

                this.props.CreateFaq(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {

                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateFaqById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {
            fieldsData,
            errors,
            languageTab,
            requestLoading,
            isEditing,
            errorsTabs
        } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել FAQ' : 'Փոփոխել FAQ'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="slider-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                label="Հարց"
                                placeholder="Հարց"
                                name="question"
                                value={currentData.question}
                                initValue={initData.question}
                                error={errors['question' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Պատասխան"
                                placeholder="Պատասխան"
                                name="answer"
                                value={currentData.answer}
                                initValue={initData.answer}
                                error={errors['answer' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditFAQ}/>
                </div>

            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateFaq,
    UpdateFaqById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFAQ)
