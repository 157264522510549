import {request, _urlSubscribe} from "../api";
import {SUBSCRIBER_CONSTS} from "../constants";

export const GetSubscribers = () => {
    const requestData = {
        url: _urlSubscribe,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SUBSCRIBER_CONSTS.GET_SUBSCRIBERS,
                    payload: data
                });
            })
    }
};

export const DeleteSubscriberById = id => {
    const requestData = {
        url: `${_urlSubscribe}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SUBSCRIBER_CONSTS.DELETE_SUBSCRIBER_BY_ID,
                    payload: id
                });
            })
    }
};
