// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {history} from "../configs/history";
import {withRouter} from 'react-router-dom';

//Import styles
import "../assets/styles/containerStyles/adminLayout.scss";

//Import components
import {LeftSideMenu} from "../components/navbars/LeftSideMenu";
import {MainHeader} from "../components/headerViews/mainHeader";
import {Footer} from "./Footer";
import {Icon} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {
    ToggleLeftSideMenu,
    TokenValidation,
    GetLanguages,
    GetFilters,
    GetCategories,
    GetMedia,
    GetBadges,
    SetRoutes,
    GetAttributes
} from "../redux/actions";
import {LogOut} from "../redux/actions";
import {ACCESS_PAGES} from "../constants/accessPages";


class AdminLayout extends Component {
    state = {
        isMounted: false,
    };

    async componentDidMount() {
        let redirectLoginPage = false;
        const {location} = this.props;
        if (!this.props.isLoggedIn) {
            redirectLoginPage = true;
        } else {
            // if (!socketNotificationConnection._instance) {
            //     socketNotificationConnection.connectToSocket();
            // }
            await this.props.TokenValidation().then(async () => {
                await this.props.GetLanguages();
                // if (!socketNotificationConnection._instance) {
                //     socketNotificationConnection.connectToSocket();
                // }
            }).catch(() => {
                redirectLoginPage = true;
            });
        }
        if (redirectLoginPage) {
            this.props.LogOut();
        } else {
            this.props.GetMedia();
            this.props.GetCategories();
            this.props.GetFilters();
            this.props.GetBadges();
            this.props.GetAttributes();
            if (location && location.pathname.includes('moderators') && !this.props.isAdmin) {
                history.push('/404');
            }
            this.setState({
                isMounted: true,
            })

            history.listen((location, action) => {
                // console.log(
                //     `The current URL is ${location.pathname}${location.search}${location.hash}`
                // );
                // console.log(`The last navigation action was ${action}`);

                const {routesList} = this.props
                const path = location.pathname.slice(1)
                if (path === "login") {
                    this.props.SetRoutes([])
                    return
                }
                if (action === "POP" && routesList.length === 1) {
                    if (path.split("/").length !== 1) {
                        history.push("/" + routesList[0]?.path)
                    }
                }
                if (path.split("/").length === 1) {
                    // Object.keys(ACCESS_PAGES).includes(path)
                    if (path !== routesList[routesList.length - 1]?.path) {
                        // console.log("back");
                        this.props.SetRoutes([{
                            path: path,
                            name: ACCESS_PAGES[path]?.name
                        }])
                    }
                } else {
                    const pathInRoutesList = routesList.find(item => item.path === path)
                    // console.log("routesList", routesList);
                    if (pathInRoutesList) {
                        let newRoutesList = routesList.slice(0, routesList.indexOf(pathInRoutesList) + 1)
                        // console.log("newRoutesList", newRoutesList)
                        this.props.SetRoutes(newRoutesList)
                    } else {
                        this.props.SetRoutes([...routesList, {
                            path: path,
                            name: ""
                        }])
                    }
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;
        if (location?.pathname !== prevProps?.location?.pathname) {

            // console.log("update layout")
            window.scrollTo(0, 0);
        }
        if (location && location.pathname.includes('moderators') && !this.props.isAdmin) {
            history.push('/404');
        }
    }

    render() {
        const {isMounted} = this.state;
        const {
            children, leftSideMenuOpen, ToggleLeftSideMenu, LogOut, isAdmin, location,
            accessPages, notSeenReviewsCount, notSeenOrdersCount, notSeenInsuranceCount, user,
            repairingList, sparesList, tyreChangeList,
        } = this.props;
        const spinIcon = <LoadingOutlined style={{fontSize: 54, color: "#1F3A62"}}/>;

        const allOffersCount = (repairingList?.notSeenCount ?? 0) +
            (sparesList?.notSeenCount ?? 0) + (tyreChangeList?.notSeenCount ?? 0);

        return isMounted ? <div className="admin-layout">
                <MainHeader toggleMenu={ToggleLeftSideMenu}
                            leftSideMenuOpen={leftSideMenuOpen}
                            isAdmin={isAdmin}
                            user={user}
                            LogOut={LogOut}/>
                <LeftSideMenu toggleMenu={ToggleLeftSideMenu}
                              leftSideMenuOpen={leftSideMenuOpen}
                              isAdmin={isAdmin}
                              accessPages={accessPages}
                              notSeenReviewsCount={notSeenReviewsCount}
                              notSeenOrdersCount={notSeenOrdersCount}
                              notSeenInsuranceCount={notSeenInsuranceCount}
                              allOffersCount={allOffersCount}
                              path={location && location.pathname}/>

                {children}
                <Footer leftSideMenuOpen={leftSideMenuOpen}/>
            </div> :
            <div className={'loading'}>
                {spinIcon}
            </div>
    }
}

const mapDispatchToProps = {
    ToggleLeftSideMenu,
    LogOut,
    GetLanguages,
    TokenValidation,
    GetFilters,
    GetCategories,
    GetBadges,
    GetMedia,
    SetRoutes,
    GetAttributes
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLayout));
