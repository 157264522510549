// Import packages
import React, { useEffect} from "react";
import {connect} from "react-redux";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetNotifications} from "../../redux/actions";
import ItemsList from "../../components/ItemsList";
import PageWrapper from "../../components/pageContentViews/pageWrapper";

// Import components
import moment from "moment";

function Notifications (props){
    const {notifications} = props
    useEffect(() => {
        props.GetNotifications()
    },[])

    let itemListHeaderInfo = {
        generalInfo: ["Անուն","Ծանուցում", "Ամսաթիվ"],
    };

    let itemListInfo = notifications?.map((items) => {
        const {id,title,description, createdAt,} = items;
        return {
            id,
            values: [
                title,
                description,
                moment(createdAt).format('DD/MM/YYYY HH:mm')
            ],
        }
    });

    return<PageWrapper pageTitle={'Բաժանորդներ'}>
        <ItemsList
            itemListHeaderInfo={itemListHeaderInfo}
                   itemListInfo={itemListInfo}
        />
    </PageWrapper>
}

const mapDispatchToProps = {
    GetNotifications,
};
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'notifications'
    ])
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);