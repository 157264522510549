export const ContactAddingList = [
    {
        label: "Էլ․ հասցե",
        inputType: "input",
        type: "text",
        placeholder: "Էլ․ հասցե",
        name: "email",
        maxLength: 500
    },
    {
        label: "Հեռախոս",
        inputType: "input",
        type: "text",
        // regExp:/^[+\d]\d*$/,
        placeholder: "Հեռախոս",
        name: "phoneNumber",
    },
    {
        label: "Հեռախոս1",
        inputType: "input",
        type: "text",
        // regExp:/^[+\d]\d*$/,
        placeholder: "Հեռախոս1",
        name: "phoneNumber1",
    },
{
        inputType: "input",
        type: "text",
        placeholder: "Facebook",
        name: "facebookUrl",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Linkedin",
        name: "linkedinUrl",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Instagram",
        name: "instagramUrl",
        maxLength: 1000
    },
];
