import {initialState as auth} from "../reducers/auth";
import {initialState as utils} from "../reducers/utils";
import {initialState as languages} from "../reducers/languages";
import {initialState as users} from "../reducers/users";
import {initialState as moderators} from "../reducers/moderators";
import {initialState as staticTexts} from "../reducers/staticTexts";
import {initialState as media} from "../reducers/media";
import {initialState as composite} from "../reducers/composite";
import {initialState as filter} from "../reducers/filter";
import {initialState as category} from "../reducers/category";
import {initialState as post} from "../reducers/post";
import {initialState as slider} from "../reducers/slider";
import {initialState as badges} from "../reducers/badges";
import {initialState as customPages} from "../reducers/customPages";
import {initialState as attributes} from "../reducers/attributes";
import {initialState as general} from "../reducers/general";
import {initialState as information} from "../reducers/information";
import {initialState as subscribers} from "../reducers/subscribers";
import {initialState as notifications} from "../reducers/notifications";
import {initialState as roomType} from "../reducers/roomType";
import {initialState as banner} from "../reducers/banner";
import {initialState as faq} from "../reducers/faq";
import {initialState as reservation} from "../reducers/reservation";


const reducers = {
    auth,
    utils,
    languages,
    users,
    moderators,
    staticTexts,
    media,
    composite,
    filter,
    category,
    post,
    slider,
    badges,
    customPages,
    attributes,
    general,
    information,
    subscribers,
    notifications,
    banner,
    roomType,
    faq,
    reservation
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};

