// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";


// Import Styles
import '../../assets/styles/containerStyles/users.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {LinkButton} from "../../components/buttons/buttons";
import ModalSendNotification from "../../components/uiElements/ModalSendNotification";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {DatePicker} from "antd";
// import DownloadUsersExcel from "../../components/excel/DownloadUsersExcel";
import InfiniteScroll from 'react-infinite-scroller';
import UserCard from '../../components/cards/UserCart';

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetUsers} from "../../redux/actions";
import {disabledStartDate, disabledEndDate} from "../../utils/helperFunctions";
import {defaultEmptyView} from "../../assets/images";
import {ACCESS_PAGES} from "../../constants/accessPages";


const isVerifiedOptions = [{id: 'true', name: 'Հաստատված'}, {id: 'false', name: 'Չհաստատված'}];
const isBlockedOptions = [{id: 'false', name: 'Չարգելափակված'}, {id: 'true', name: 'Արգելափակված'}];
const dateFormat = 'DD.MM.YYYY';

class Users extends Component {
    constructor() {
        super();
        this.state = {
            filterData: {
                text: '',
                createdAtStartDate: null,
                createdAtEndDate: null,
                makeOrdersStartDate: null,
                makeOrdersEndDate: null,
                isVerified: undefined,
                isBlocked: undefined,
            },
            selectingActive: false,
            selectedUsers: [],
            sendModalOpen: false,
            allSelected: false,
        };
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
        this.toggleSelecting = this.toggleSelecting.bind(this);
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.selectAll = this.selectAll.bind(this);
    }

    componentDidMount() {
        this.props.GetUsers();
    }

    getSearchValue(value) {
        this.setState({
            filterData: {
                ...this.state.filterData,
                text: value
            }
        }, () => !value && this.searchItems())
    }

    handleChangeData(field, value) {
        this.setState({
            filterData: {
                ...this.state.filterData,
                [field]: value
            }
        }, this.searchItems)
    }

    toggleSelecting() {
        const newState = {selectingActive: !this.state.selectingActive};
        if (this.state.selectingActive) {
            newState.selectedUsers = [];
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    toggleNotificationModal(notificationSuccessfulSend = false) {
        const newState = {
            sendModalOpen: !this.state.sendModalOpen
        }
        this.state.allSelected && (newState.allSelected = false)
        if (notificationSuccessfulSend) {
            newState.selectingActive = false;
            newState.selectedUsers = [];
            newState.sendModalOpen = false;
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    searchItems(updatedData) {
        const {
            text, createdAtStartDate, createdAtEndDate, makeOrdersStartDate, makeOrdersEndDate,
            isVerified, isBlocked
        } = this.state.filterData;
        let filterData = {text, isVerified, isBlocked};
        updatedData && (filterData = {...filterData, ...updatedData})
        createdAtStartDate && (filterData.createdAtStartDate = createdAtStartDate.format("YYYY-MM-DD") + "T00:00:00.000Z")
        createdAtEndDate && (filterData.createdAtEndDate = createdAtEndDate.format("YYYY-MM-DD") + "T23:59:59.000Z")
        makeOrdersStartDate && (filterData.makeOrdersStartDate = makeOrdersStartDate.format("YYYY-MM-DD") + "T00:00:00.000Z")
        makeOrdersEndDate && (filterData.makeOrdersEndDate = makeOrdersEndDate.format("YYYY-MM-DD") + "T23:59:59.000Z")
        !updatedData?.offset && this.setState({
            selectedUsers: []
        })
        // console.log('filterData', filterData)
        return this.props.GetUsers({filters: filterData});
    }

    loadMoreItems() {
        // console.log('users get more');
        const {usersList} = this.props;
        usersList?.hasMore && this.searchItems({
            reset: false,
            offset: usersList?.itemsList?.length,
            text: usersList?.text
        });
    };

    selectUser(userId) {
        let {selectingActive, selectedUsers} = this.state;
        if (selectingActive) {
            const filteredUsers = selectedUsers.filter(id => id !== userId);
            this.setState({
                selectedUsers: filteredUsers.length === selectedUsers.length ?
                    [userId, ...selectedUsers] : filteredUsers
            })
        }
    }

    selectAll() {
        this.setState({
            allSelected: true,
            selectedUsers: [],
            sendModalOpen: true
        })
    }

    render() {
        const {selectingActive, selectedUsers, sendModalOpen, allSelected, filterData,} = this.state;
        const {
            text, createdAtStartDate, createdAtEndDate, makeOrdersStartDate, makeOrdersEndDate,
            isVerified, isBlocked
        } = filterData;
        const {usersList, isAdmin, accessPages} = this.props;

        const haveNotificationsAccess = isAdmin || accessPages?.includes(ACCESS_PAGES.notifications?.key);

        const bottomRightPartJsx = haveNotificationsAccess && <div className={'right-part-wrapper'}>
            {selectingActive && <LinkButton title={'Առաջ'}
                                            className={`bg-white size-sm`}
                                            disabled={!selectedUsers?.length}
                                            cb={this.toggleNotificationModal}/>}
            {selectingActive && <LinkButton title={'Բոլորին'}
                                            className={`bg-white size-sm`}
                                            cb={this.selectAll}/>}
            <LinkButton title={selectingActive ? 'Չեղարկել' : 'Ուղարկել ծանուցում'}
                        className={`send-notification-btn size-sm ${selectingActive ? "bg-default-important" : ''}`}
                        cb={this.toggleSelecting}/>
            {/*          <DownloadUsersExcel usersList={usersList}
                                fuelList={fuelList}
                                mainLanguage={mainLanguage}
                                GetUsers={this.searchItems}/>*/}
        </div>;

        const filterJsx = <div className="user-filters-wrapper">
            <div>
                <InputGroup inputType={"wrapper"}>
                    <DatePicker value={createdAtStartDate}
                                format={dateFormat}
                                showToday={false}
                                allowClear={true}
                                disabledDate={(date) => disabledStartDate(date, createdAtEndDate)}
                                placeholder="Գրանցման ամսաթիվ - սկիզբ"
                                className={`date-picker`}
                                onChange={(value) =>
                                    this.handleChangeData('createdAtStartDate', value)}>
                    </DatePicker>
                </InputGroup>
                <InputGroup inputType={"wrapper"}>
                    <DatePicker value={createdAtEndDate}
                                format={dateFormat}
                                showToday={false}
                                allowClear={true}
                                disabledDate={(date) => disabledEndDate(date, createdAtStartDate)}
                                placeholder="Գրանցման ամսաթիվ - ավարտ"
                                className={`date-picker`}
                                onChange={(value) =>
                                    this.handleChangeData('createdAtEndDate', value)}>
                    </DatePicker>
                </InputGroup>
            </div>
            <div>
                <InputGroup inputType={"wrapper"}>
                    <DatePicker value={makeOrdersStartDate}
                                format={dateFormat}
                                showToday={false}
                                allowClear={true}
                                disabledDate={(date) => disabledStartDate(date, makeOrdersEndDate)}
                                placeholder="Պատվեր կատարելու ամս․ - սկիզբ"
                                className={`date-picker`}
                                onChange={(value) =>
                                    this.handleChangeData('makeOrdersStartDate', value)}>
                    </DatePicker>
                </InputGroup>
                <InputGroup inputType={"wrapper"}>
                    <DatePicker value={makeOrdersEndDate}
                                format={dateFormat}
                                showToday={false}
                                allowClear={true}
                                disabledDate={(date) => disabledEndDate(date, makeOrdersStartDate)}
                                placeholder="Պատվեր կատարելու ամս․ - ավարտ"
                                className={`date-picker`}
                                onChange={(value) =>
                                    this.handleChangeData('makeOrdersEndDate', value)}>
                    </DatePicker>
                </InputGroup>
            </div>
            <div>
                <InputGroup inputType="selectCustom"
                            placeholder="էլ․ հասցեի Կարգավիճակ"
                            name="isVerified"
                            showSearch={false}
                            value={isVerified}
                            onChange={({value}) => this.handleChangeData('isVerified', value)}
                            options={isVerifiedOptions}
                            withClear={'Բոլորը'}>
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Արգելափակման Կարգավիճակ"
                            name="isBlocked"
                            showSearch={false}
                            value={isBlocked}
                            onChange={({value}) => this.handleChangeData('isBlocked', value)}
                            options={isBlockedOptions}
                            withClear={'Բոլորը'}>
                </InputGroup>
            </div>
        </div>

        return <PageWrapper pageTitle={'Օգտատերեր'}>
            <section className="users">
                <PageHeader search={true}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={text}
                            rightBottomProps={bottomRightPartJsx}
                            filters={filterJsx}
                />
                <InfiniteScroll
                    pageStart={0}
                    className="users-wrapper"
                    initialLoad={false}
                    useWindow={true}
                    loadMore={this.loadMoreItems}
                    hasMore={usersList.hasMore}>
                    {
                        usersList.itemsList && !!usersList.itemsList?.length ? usersList.itemsList.map(user => {
                            const checkboxData = {
                                id: user.id,
                                handleSelect: this.selectUser.bind(this, user.id),
                                selected: selectingActive && (selectedUsers.find(userId => userId === user.id) || allSelected),
                            };
                            return <UserCard data={user}
                                             key={user?.id}
                                             selectingActive={selectingActive}
                                             checkboxData={checkboxData}
                                             allSelected={allSelected}
                                             selectAll={this.selectAll}
                            />
                        }) : <div className={'empty-view'}>
                            <img src={defaultEmptyView}/>
                        </div>

                    }
                </InfiniteScroll>
            </section>
            <ModalSendNotification visible={sendModalOpen}
                                   sendToAllUsers={allSelected}
                                   userIds={selectedUsers}
                                   closeModal={this.toggleNotificationModal}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(Users)
