// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Menu } from "antd";

// Import Styles
import '../../assets/styles/cards/product-card.scss';
import {IconEye, iconEyeHide, IconPhone} from "../../assets/images";

// Import components
import { LinkButton } from "../buttons/buttons";
import { MaterialIcon } from "../utils/Icon";
import swal from "sweetalert";
import { MoreOutlined } from '@ant-design/icons';

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { generateImageMediaUrl } from "../../utils/generateMemberMediaUrl";
import { DeletePost, TogglePostVisibility } from "../../redux/actions";
import { resizeImage } from "../../utils/resizeImage";
import { ACCESS_PAGES } from "../../constants/accessPages";
import { iconChekMark } from "../../assets/images";
import { CURRENCIES } from "../../constants/constsTypes";


class PostCard extends Component {
    constructor () {
        super();
        this.state = {};
    }

    deletePost = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ապրանքը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeletePost(id);
            }
        });
    };

    getScrollPosition () {
        return document.body.scrollTop || document.documentElement.scrollTop;
    }

    render () {
        const { data, inSelectorModal, viewCard, isSelected, isDisabled = false, accessPages, isAdmin } = this.props;
        const activeEditing = isAdmin || accessPages.includes(ACCESS_PAGES.posts.key);
        const menu = (
            <Menu>
                <Menu.Item key="1">
                    <Link to={"/posts/edit/" + data?.id}>Edit</Link>
                </Menu.Item>
                <Menu.Item key="2" onClick={() => {
                    this.props.TogglePostVisibility(data?.id, { isHidden: !data?.isHidden })
                }}>
                    {data?.isHidden ? 'Show' : 'Hide'}
                </Menu.Item>
                <Menu.Item key="3">
                    <span onClick={this.deletePost.bind(this, data?.id)}>Delete</span>
                </Menu.Item>
            </Menu>

        );
        return <div className={`product-card ${isSelected ? 'isSelected' : ""}`} onClick={() => {
            inSelectorModal && !isDisabled && this.props.onSelect(data, isSelected);
        }}>
            <div className={'badges'}>
                {data?.isUrgent && <div className={'urgent'}>Շտապ</div>}
                {data?.isNew && <div className={'new_badgs'}>Նոր</div>}
                {data?.isSpecialOffer && <div className={`top ${!data?.isUrgent ? "single": ""}`}>Տոպ</div>}
            </div>
            {inSelectorModal && <div className={`check-mark ${isSelected ? 'show' : ''}`}>
                <img src={iconChekMark}/>
            </div>}
            {viewCard && <div className={`delete-btn`} onClick={() => this.props.onDelete(data?.id)}>
                <MaterialIcon icon="delete"/>
            </div>}
            {!viewCard && !inSelectorModal && activeEditing &&
            <Dropdown overlay={menu}
                      placement={"bottomRight"}>
                <MoreOutlined className="more"/>
            </Dropdown>}
            {data?.isHidden && <img className="hidden-product-icon" src={iconEyeHide}/>}
            <div>
                <img className="product-img"
                     src={resizeImage(generateImageMediaUrl(data?.mediaMain?.path || data?.medias?.[0]?.path))} alt=""/>
                <div className="product-title">{data?.title}</div>
                {!viewCard && !inSelectorModal &&
                <div className={'phone-seen-count'}><IconEye/> Դիտվել է՝ {data?.postWatchedCount || 0}</div>}
                <div className={'id'}><span>id:</span>{data?.postNumber}</div>

                {!viewCard && !inSelectorModal && <>
                    <div className="old-price">{data?.oldPrice ? data?.oldPrice + " Դ" : ""}</div>
                    <div
                        className="discounted-price">
                        {data && data?.type.length < 1 ? data?.type.map((type) => {
                            if(type === 'SELL'){
                                return <div className="post_price">
                        <span>
                            {data?.priceSell ? `${data?.priceSell} ${CURRENCIES[data?.currency]?.suffix}` : 'Պայմանագրային'}
                        </span>
                                </div>
                            }
                        }) : data?.type[0] === 'SELL' ?
                            <div className="post_price">
                        <span>
                             {data?.priceSell ? `${data?.priceSell} ${CURRENCIES[data?.currency]?.suffix}` : "Պայմանագրային"}
                        </span>
                            </div> :  <div className="post_price">
                        <span>
                            {data?.priceRent ? `${data?.priceRent} ${CURRENCIES[data?.currency]?.suffix}` : "Պայմանագրային"}
                        </span>
                                {data?.priceRent ? data?.rentPriceType === 'MONTHLY' ?
                                    <span className={'rent'}> / month</span> :
                                    <span className={'rent'}> / day</span> : "Պայմանագրային"
                                }
                            </div>
                        }
                    </div>
                    <div className="details-btn">
                        <LinkButton link={`/posts/edit/${data?.id}`}
                                    getScrollPosition={this.getScrollPosition}
                                    disabled={!activeEditing}
                                    title={<MaterialIcon icon="keyboard_arrow_right"/>}/>
                    </div>
                </>}
            </div>
        </div>

    }
}

const mapDispatchToProps = {
    DeletePost,
    TogglePostVisibility
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCard)
