// Import packages
import React from "react";
import {NavLink} from "react-router-dom";

// Import styles
import "../../assets/styles/navbars/leftSideMenu.scss";

// Import components
import {Icon, MaterialIcon} from "../utils/Icon";

//import utils
import {ACCESS_PAGES} from "../../constants/accessPages";

export function LeftSideMenu(props) {
    const {
        toggleMenu, leftSideMenuOpen, accessPages, isAdmin, notSeenReviewsCount, notSeenOrdersCount
    } = props;

    return <aside className={`left-side-menu ${leftSideMenuOpen ? "open" : "close"}`}>

        <ul className="aside-menu-list custom-scrollbar-y ">
            <li>
                <NavLink to={`/posts`} activeClassName='active'>
                    <MaterialIcon icon="dashboard"/>
                    <span>Ապրանքներ</span>
                </NavLink>
            </li>
       {/*     {props.isAdmin && <li>
                <NavLink to="/moderators" activeClassName='active'>
                    <MaterialIcon icon="supervised_user_circle"/>
                    <span>Մոդերատորներ</span>
                </NavLink>
            </li>}*/}
            {
                Object.keys(ACCESS_PAGES).map(path => {
                    const page = ACCESS_PAGES?.[path];

                    const haveAccess = isAdmin || accessPages?.includes(page?.key);
                    return haveAccess && !page?.isHidden ? <li key={path}>

                        <NavLink to={`/${path}`} activeClassName='active'>
                            <MaterialIcon icon={page?.icon}/>
                            <span>{page?.name}</span>
                            {path === 'reviews' && !!notSeenReviewsCount &&
                            <span className={'badge'}>{notSeenReviewsCount > 99 ? '99+' : notSeenReviewsCount}</span>}
                            {path === 'orders' && !!notSeenOrdersCount &&
                            <span className={'badge'}>{notSeenOrdersCount > 99 ? '99+' : notSeenOrdersCount}</span>}
                        </NavLink>
                    </li> : null
                })
            }
        </ul>
        <div className="menu-toggle-btn-wrapper">
            {<MaterialIcon icon={`${leftSideMenuOpen ? "keyboard_arrow_left" : "keyboard_arrow_right"}`}
                           onClick={toggleMenu}
                           className="menu-toggle-btn"/>}
        </div>

    </aside>
}
