import { BANNER_CONSTS } from "../constants";

export const initialState = {
    bannerList: [],
    bannerById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case BANNER_CONSTS.GET_BANNERS:
            return {
                ...state,
                bannerList: action.payload || [],
            };
        case BANNER_CONSTS.CREATE_BANNER:
            return {
                ...state,
                bannerList: [action.payload].concat(state.bannerList)
            };
        case BANNER_CONSTS.GET_BANNER_BY_ID:
            return {
                ...state,
                bannerById: action.payload
            };
        case BANNER_CONSTS.UPDATE_BANNER_BY_ID:
            return {
                ...state,
                bannerList: state.bannerList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case BANNER_CONSTS.DELETE_BANNER_BY_ID:
            return {
                ...state,
                bannerList: state.bannerList.filter(item => item.id !== action.payload)
            };
        case BANNER_CONSTS.CLEAR_BANNER_DATA:
            return {
                ...state,
                bannerById: null
            };
        default:
            return state;
    }
}
