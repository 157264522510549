// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateCategory, GetCategoryById, UpdateCategoryById } from "../../redux/actions";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { validateTranslations } from "../../utils/validateTranslations";


class AddEditCategory extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                color: '',
                prefixLetter: '',
                icon: undefined,
                parent: undefined,
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            displayColorPicker: false,
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getColor = this.getColor.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditCategory = this.addEditCategory.bind(this);
    }

    async componentDidMount () {
        const { isEditing } = this.state;
        const translationsData = {};

        // init Categories Options
        this.categoriesOptions = [];
        Array.isArray(this.props.categoriesList) && this.props.categoriesList.forEach(item => {
            this.categoriesOptions.push({
                name: item?.title,
                id: item?.id,
            })
            Array.isArray(item?.subCategories) && item.subCategories.forEach(sub => this.categoriesOptions.push({
                name: sub?.title,
                id: sub?.id,
            }))
        });

        if (isEditing) {
            await this.props.GetCategoryById(this.props.id);
            const editingData = this.props.categoryById;
            this.categoriesOptions = this.categoriesOptions.filter(item => item.id !== this.props.id);
            if (editingData) {

                editingData.translations.forEach(item => {
                    translationsData[item.languageId] = {
                        ...translationsData[item.languageId],
                        title: item.title || '',
                    };
                });

            }
            const initStateData = {
                translations: translationsData,
                icon: editingData?.icon,
                prefixLetter: editingData?.prefixLetter,
                color: editingData?.color,
                parent: editingData?.parent,
                isHidden: editingData?.isHidden,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData
            });

        } else {
            //for categories options
            this.forceUpdate()
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.icon?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('icon', mediaArray[0]?.id)
                : this.updatedDataMap.delete('icon');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: mediaArray[0],
            },
        })
    }

    deleteMedia () {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {

            this.currentData.icon ?
                this.updatedDataMap.set("icon", null) :
                this.updatedDataMap.delete("icon");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: null
            }
        })
    }

    getColor ({ color, haveChanges }) {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            haveChanges
                ? this.updatedDataMap.set('color', color.hex)
                : this.updatedDataMap.delete('color');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                color: color.hex
            }
        })
    };

    addEditCategory () {
        this.setState({
            requestLoading: true
        });
        const { translations, icon, isHidden, prefixLetter, parent } = this.state.fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        let errors = { ...validationTr.errors };

        if (!prefixLetter) {
            result = false;
                errors = {
                    ...errors,
                    prefixLetter: true
                }
        }
        if (result) {
            const trData = getTranslationData(translations)
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    prefixLetter,
                    translations: trData,
                };
                if (icon) reqData.icon = icon.id;
                if (parent) reqData.parent = parent;
                this.props.CreateCategory(reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    })
            } else {
                const reqData = this.getPatchReqData(trData);
                // console.log(reqData);
                this.props.UpdateCategoryById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const { fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Կատեգորիա' : 'Փոփոխել Կատեգորիան'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>

                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData.icon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.icon}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <InputGroup inputType="wrapper"
                            label={"Ծնող Կատեգորիա"}>
                    <InputGroup inputType="selectCustom"
                                name="parent"
                                value={fieldsData.parent}
                                initValue={this.currentData?.parent}
                                withClear={true}
                                placeholder={'Ծնող Կատեգորիա'}
                                onChange={this.getInputValues}
                                options={this.categoriesOptions}
                    />
                </InputGroup>
                {!isEditing ? <InputGroup inputType="input"
                             type="text"
                             label="Նախածանց"
                             placeholder="Նախածանց"
                             name="prefixLetter"
                             value={fieldsData.prefixLetter}
                             error={errors['prefixLetter']}
                             regExp={/^[a-z]$/}
                             maxLength={1}
                             required={true}
                             onChange={this.getInputValues}/>:
                    <div className={'prefixLetter'} style={{marginBottom: "20px"}}>
                        Նախածանց: <span style={{fontWeight: 'bold'}}>{fieldsData.prefixLetter}</span>
                    </div>}

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}
                />
                <div className="category-left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditCategory}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateCategory,
    GetCategoryById,
    UpdateCategoryById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategory)
