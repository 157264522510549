import { _urlAttributes, request } from "../api";
import {ATTRIBUTE_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetAttributes = () => {
    const requestData = {
        url: _urlAttributes,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {

                dispatch({
                    type: ATTRIBUTE_CONSTS.GET_ATTRIBUTES,
                    payload: data
                });
            })
    }
};

export const GetAttributeById = (id) => {
    const requestData = {
        url: `${_urlAttributes}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ATTRIBUTE_CONSTS.GET_ATTRIBUTE_BY_ID,
                    payload: res.data
                })
            })
    }
};

export const DeleteAttributeById = id => {
    const requestData = {
        url: `${_urlAttributes}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ATTRIBUTE_CONSTS.DELETE_ATTRIBUTE_BY_ID,
                    payload: id
                });
            })
    }
};

export const UpdateAttributesPositions = items => {
    const requestData = {
        url: _urlAttributes,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Բնութագիրը հաջողությամբ թարմացվեց!"
                })
            })
    }
};

export const CreateAttribute = data => {
    const requestData = {
        url: _urlAttributes,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ATTRIBUTE_CONSTS.CREATE_ATTRIBUTE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Բնութագիրը հաջողությամբ ստեղծվեց!"
                })
                history.push('/attributes');
            })
    }
};

export const UpdateAttributeById = (id, data) => {
    const requestData = {
        url: `${_urlAttributes}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ATTRIBUTE_CONSTS.UPDATE_ATTRIBUTE_BY_ID,
                    payload: res.data
                });
            })

    }
};


export const CreateAttributeValue = (id, data) => {
    const requestData = {
        url: `${_urlAttributes}/${id}/values`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};

export const UpdateAttributeValueById = (id, valueId, data) => {
    const requestData = {
        url: `${_urlAttributes}/${id}/values/${valueId}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};

export const DeleteAttributeValueById = (id, valueId) => {
    const requestData = {
        url: `${_urlAttributes}/${id}/values/${valueId}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
    }
};
