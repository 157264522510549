export const ACCESS_PAGES = {
    moderators: {
        key: 'MODERATORS',
        name: 'Մոդերատորներ',
        icon: 'supervised_user_circle',
        crud: false,
        isHidden: false,
    },
    posts: {
        key: 'POSTS',
        name: 'Ապրանքներ',
        icon: 'add_shopping_cart',
        crud: true,
        isHidden: true,
    },
    users: {
        key: 'USERS',
        name: 'Օգտատերեր',
        icon: 'person',
        crud: true,
        isHidden: false,
    },
    media: {
        key: 'FILES',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    // history: {
    //     key: 'HISTORY',
    //     name: 'Պատմություն',
    //     icon: 'history',
    //     crud: true,
    //     isHidden: false,
    // },
    banner: {
        key: 'BANNERS',
        name: 'Բաներ',
        icon: 'redeem',
        crud: true,
        isHidden: false,
    },
    slider: {
        key: 'SLIDERS',
        name: 'Սլայդեր',
        icon: 'amp_stories',
        crud: true,
        isHidden: false,
    },
    categories: {
        key: 'CATEGORIES',
        name: 'Կատեգորիաներ',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    roomTypes: {
        key: 'ROOM_TYPES',
        name: 'Սենյակի տեսակներ',
        icon: 'vertical_split',
        crud: true,
        isHidden: false,
    },
    filters: {
        key: 'FILTERS',
        name: 'Ֆիլտրեր',
        icon: 'style',
        crud: true,
        isHidden: false,
    },
    attributes: {
        key: 'ATTRIBUTES',
        name: 'Բնութագրեր',
        icon: 'scatter_plot',
        crud: true,
        isHidden: false,
    },
    subscribe: {
        key: 'SUBSCRIBERS',
        name: 'Բաժանորդներ',
        icon: 'supervisor_account',
        crud: true,
        isHidden: false,
    },
    information: {
        key: 'INFORMATION',
        name: 'Տվյալներ',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    contact: {
        key: 'CONTACT',
        name: 'Կապ',
        icon: 'contact_phone',
        crud: true,
        isHidden: false,
    },
    customPages: {
        key: 'CUSTOM_PAGES',
        name: 'Դինամիկ էջեր',
        icon: 'web',
        crud: false,
        isHidden: true,
    },
    faq: {
        key: 'FAQ',
        name: 'FAQ',
        icon: 'live_help',
        crud: true,
        isHidden: false,
    },
    languages: {
        key: 'LANGUAGES',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    notifications: {
        key: 'NOTIFICATIONS',
        name: 'Ծանուցումներ',
        icon: 'notifications',
        crud: true,
        isHidden:false,
    },
    reservations: {
        key: 'RESERVATIONS',
        name: 'Հայտեր',
        icon: 'book_online',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'TRANSLATIONS',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
};
