// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

// Import styles
import "../assets/styles/components/products-selector-modal.scss";
import { iconCloseDark, iconSearch } from "../assets/images";

// Import Components
import {Modal} from "antd";
import {LinkButton} from "./buttons/buttons";
import {InputGroup} from "./uiElements/inputGroup";
import PostCard from "./cards/PostCard";

// Import utils
import {GetPosts} from "../redux/actions";
import {mapStateToProps} from "../redux/mapStateToProps";
import {changeStateInputValue} from "../utils/helperFunctions";

class PostsSelectorModal extends Component {
    constructor() {
        super();
        this.state = {
            selectedPosts: [],
            text: '',
        };
        this.inputElement = React.createRef();
        this.getSearchValue = changeStateInputValue.bind(this);
        this.selectPost = this.selectPost.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.insertMedia = this.insertMedia.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.getPosts = this.getPosts.bind(this);
    }

    componentDidMount() {
        const {posts} = this.props;
        (posts?.filtered || !posts?.itemsList?.length) && this.props.GetPosts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isOpen, appendPosts} = this.props;
        if (!prevProps.isOpen && isOpen && Array.isArray(appendPosts)) {
            this.setState({
                selectedPosts: appendPosts
            })
        }
    }

    selectPost(item, isSelected) {
        const {multiSelect} = this.props;
        const {selectedPosts} = this.state;
        if (multiSelect) {
            this.setState({
                selectedPosts: isSelected ? selectedPosts.filter(m => m.id !== item.id) : [item, ...selectedPosts]
            })
        } else {
            this.setState({
                selectedPosts: isSelected ? [] : [item]
            })
        }
    }

    insertMedia() {
        const {selectedPosts} = this.state;
        this.props.getSelectedPosts(selectedPosts);
        this.closeModal();
    }

    closeModal() {
        this.setState({
            selectedPosts: []
        });
        this.props.closeModal();
    }

    getPosts() {
        const {text} = this.state;
        this.props.GetPosts({
            text
        });
    }

    loadMoreItems() {
        const {text} = this.state;
        const {posts} = this.props;
        const {hasMore} = posts;
        hasMore && this.props.GetPosts({
            reset: false,
            offset: posts.itemsList.length,
            text
        });
    };


    render() {
        const {isOpen, posts, disabledPosts} = this.props;
        const {selectedPosts, text} = this.state;
        return <Modal
            visible={isOpen}
            onCancel={this.closeModal}
            wrapClassName={"custom-modal"}>

            <div className="posts-selector-modal">
                <div className="posts-selector-modal-inner">
                    <div className="selector-modal-header">
                        <div className="selector-modal-title">
                            <h1>Ապրանքներ</h1>
                        </div>
                        <InputGroup
                            inputType={"input"}
                            type={'text'}
                            name={'text'}
                            value={text}
                            placeholder={'Փնտրել...'}
                            onChange={this.getSearchValue}>
                            <div className={'search-icon'} onClick={this.getPosts}>
                                <img src={iconSearch} alt=""/>
                            </div>
                        </InputGroup>
                        <button className="selector-modal-closing-btn"
                                onClick={this.closeModal}>
                            <img src={iconCloseDark}
                                 className="close_icon" alt=""/>
                        </button>
                    </div>
                    <div className={'posts-items-wrapper'}>
                        <InfiniteScroll
                            hasMore={posts.hasMore}
                            loadMore={this.loadMoreItems}
                            pageStart={0}
                            className={'posts'}
                            useWindow={false}
                            initialLoad={false}>
                            {posts && !!posts?.itemsList?.length && posts.itemsList.map((item) => {
                                const isSelected = selectedPosts?.find(v => v.id === item.id)
                                const isDisabled = disabledPosts?.find(id => id === item.id);
                                return <PostCard key={item?.id}
                                                    isSelected={!!isSelected}
                                                    isDisabled={isDisabled}
                                                    inSelectorModal={true}
                                                    onSelect={this.selectPost}
                                                    data={item}/>
                            })}
                        </InfiniteScroll>

                    </div>
                    <div className="choose-button">
                        <LinkButton title={'Ընտրել'}
                                    disabled={!selectedPosts.length}
                                    cb={this.insertMedia}/>
                    </div>

                </div>
            </div>
        </Modal>
    }
}

PostsSelectorModal.defaultProps = {
    multiSelect: true,
}
const mapDispatchToProps = {
    GetPosts
};

export default connect(mapStateToProps, mapDispatchToProps)(PostsSelectorModal);
