import { request, _urlPosts } from "../api";
import { POST_CONSTS, UTIL_CONSTS } from "../constants";
import { LIMIT_GET_POSTS } from "../../constants/constLimitCounts";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetPosts = ({
                             reset = true,
                             offset = 0,
                             text = '',
                             filterValueId,
                             categoryId,
                             minPrice,
                             maxPrice,
                             isUrgent = null,
                             isSpecialOffer = null,
                             isHidden = null,
                             limit = LIMIT_GET_POSTS,
                         } = {}) => {
    let url = `${_urlPosts}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    categoryId && (url += `&category=${categoryId}`);
    isUrgent !== null && (url += `&isUrgent=${isUrgent}`);
    isSpecialOffer !== null && (url += `&isSpecialOffer=${isSpecialOffer}`);
    isHidden !== null && (url += `&isHidden=${isHidden}`);
    filterValueId && (url += `&filterValueIds=${filterValueId}`);
    minPrice && (url += `&minPrice=${minPrice}`);
    maxPrice && (url += `&maxPrice=${maxPrice}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                const filtered = !!(text || filterValueId || categoryId
                    || minPrice || maxPrice || isUrgent !== null || isSpecialOffer !== null || isHidden !== null);
                data && dispatch({
                    type: POST_CONSTS.GET_POSTS,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data.length === limit,
                        text,
                        filterValueId,
                        categoryId,
                        minPrice,
                        maxPrice,
                        filtered,
                        isUrgent,
                        isSpecialOffer,
                        isHidden,
                    }
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetPostById = (id) => {
    const requestData = {
        url: `${_urlPosts}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: POST_CONSTS.GET_POST_BY_ID,
                    payload: res.data
                })
            })
            .catch(() => {
            })
    }
};

export const AddPost = (data) => {
    const requestData = {
        url: _urlPosts,
        token: true,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ ստեղծվեց!"
                })
                history.push('/posts')
            })
    }
};

export const UpdatePost = (id, data) => {
    const requestData = {
        url: `${_urlPosts}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: POST_CONSTS.UPDATE_POST,
                    payload: data
                });
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ փոփոխվեց!"
                })
                history.push({
                    pathname: '/posts',
                })
            })
    }
};

export const TogglePostVisibility = (id, data) => {
    const requestData = {
        url: `${_urlPosts}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: POST_CONSTS.TOGGLE_POST_VISIBILITY,
                    payload: {
                        id,
                        isHidden: data.isHidden
                    }
                });
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ փոփոխվեց!"
                })
            })
    }
};

export const DeletePost = (id) => {
    const requestData = {
        url: `${_urlPosts}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: POST_CONSTS.DELETE_POST,
                    payload: id
                });
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ ջնջվեց!"
                })

            })
    }
};
