import {request, _urlRoomTypes} from "../api";
import {ROOM_TYPE_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetRoomTypes = () => {
    const requestData = {
        url: _urlRoomTypes,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: ROOM_TYPE_CONSTS.GET_ROOM_TYPES,
                    payload: data
                });
            })
    }
};


export const DeleteRoomTypeById = id => {
    const requestData = {
        url: `${_urlRoomTypes}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ROOM_TYPE_CONSTS.DELETE_ROOM_TYPE_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateRoomType = data => {
    const requestData = {
        url: _urlRoomTypes,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ROOM_TYPE_CONSTS.CREATE_ROOM_TYPE,
                    payload: res?.data
                });
                notificationSuccess({
                    description: "Սենյակի տեսակը հաջողությամբ ստեղծվեց!"
                })
                history.push('/roomTypes')
            })
    }
};

export const UpdateRoomTypeById = (id, data) => {
    const requestData = {
        url: `${_urlRoomTypes}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                dispatch({
                    type: ROOM_TYPE_CONSTS.UPDATE_ROOM_TYPE,
                    payload: res?.data
                });
                notificationSuccess({
                    description: "Սենյակի տեսակը հաջողությամբ փոփոխվեց!"
                })
                history.push('/roomTypes')
            })
    }
};

export const UpdateRoomTypesPositions = items => {
    const requestData = {
        url: _urlRoomTypes,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Սենյակի տեսակը հաջողությամբ թարմացվեց!"
                })
            })
    }
};
