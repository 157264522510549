import {useLocation} from "react-router-dom";
import {setPageOffset} from "../../redux/actions";
import {history} from "../../configs/history";
import {Spin} from "antd";
import React, {useEffect, useRef, useState} from "react";

//import assets
import "../../assets/styles/components/uiElements/custom-dropdown.scss"

export function CustomDropdown(props) {
    const [active, setActive] = useState(false);

    const ref = useRef();

    useEffect(() => {
        if (active) {
            window.addEventListener('click', onClicked)
        } else {
            window.removeEventListener('click', onClicked)
        }
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, [active]);

    function onClicked(e) {
        // if (!e.path.includes(ref.current)) {
            setActive(false);
        // }
    }

    const toggleCustomDropdown = () => {
        setActive(!active)
    }

    const {inner, content, className, title, rightProps} = props;

    return <div className={`dropdown-component ${className ? className : ''}`}>
        <div className={`custom-dropdown-inner ${active ? "active" : ""}`} onClick={toggleCustomDropdown}>
            {inner}
        </div>
        <div className={`custom-dropdown-content ${active ? "showDropdown" : ""}`} ref={ref}>
            {title && <div className="custom-dropdown-title-wrapper">
                <h3 className="custom-dropdown-title">{title}</h3>
                {rightProps}
            </div>}
            {content}
        </div>
    </div>
}