// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";

//import styles
import '../../assets/styles/containerStyles/appended-product-section.scss';

// Import components
import Nestable from "react-nestable";
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";
import PostsSelectorModal from "../../components/PostsSelectorModal";
import PostCard from "../../components/cards/PostCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    clearBannerData,
    CreateBanner,
    GetBannerById,
    UpdateBannerById,
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { history } from "../../configs/history";


const dateFormat = 'DD.MM.YYYY';

class AddEditBanner extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                posts: [],
                slug: '',
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            mediaModalOpen: false,
            postsModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.togglePostsModal = toggleStateField.bind(this, 'postsModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.checkPostListDifferent = this.checkPostListDifferent.bind(this);
        this.postCardWrapper = this.postCardWrapper.bind(this);
        this.getPosts = this.getPosts.bind(this);
        this.deletePost = this.deletePost.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.addEditBanner = this.addEditBanner.bind(this);
    }

    async componentDidMount () {
        const { isEditing, fieldsData } = this.state;

        if (isEditing) {
            await this.props.GetBannerById(this.props.id);
            const editingData = this.props.bannerById
            const translationsData = {};
            if (editingData) {
                // console.log(editingData, 'editingData');
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                        slug: trItem?.slug || '',
                    };
                });
                const posts = Array.isArray(editingData?.posts) ?
                    editingData.posts : []
                const initStateData = {
                    translations: translationsData,
                    mediaMain: editingData?.mediaMain,
                    posts: posts,
                    slug: editingData?.slug || '',
                    isHidden: editingData?.isHidden,
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            }
        }
    }

    componentWillUnmount () {
        this.props.clearBannerData()
    }

    checkPostListDifferent (currPosts) {
        let result = false;
        const initPosts = this.currentData.posts;
        if (!Array.isArray(currPosts) || (currPosts?.length !== initPosts.length)) {
            result = true;
        } else {
            currPosts.forEach((item, index) => {
                if (initPosts[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }
        result ? this.updatedDataMap.set('posts', currPosts.map(p => p.id))
            : this.updatedDataMap.delete('posts');
    }

    getPosts (postsArray) {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.checkPostListDifferent(postsArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                posts: postsArray,
            }
        })
    }

    deletePost (id) {
        const { fieldsData, isEditing } = this.state;
        let posts = fieldsData.posts.filter(p => p.id !== id);

        if (isEditing) {
            this.checkPostListDifferent(posts)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                posts: posts,
            }
        })
    }

    onPositionChange (items) {
        const { fieldsData, isEditing } = this.state;
        const posts = items.map(item => item.post);
        if (isEditing) {
            this.checkPostListDifferent(posts)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                posts: posts
            }
        })
    }

    getMedia (mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia () {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditBanner () {
        await this.setState({
            requestLoading: true
        });
        const { translations, mediaMain, posts, slug } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!slug && slug.length < 5) {
            errors.slug = true;
            result = false;
        }
        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (!posts.length) {
            errors.posts = true;
            result = false;
        }
        if (result) {
            const trData = getTranslationData(translations);
            if (!this.state.isEditing) {
                const reqData = this.getPostReqData(this.state.fieldsData);
                reqData.mediaMain = mediaMain.id
                reqData.posts = posts.map(i=> i.id)
                this.props.CreateBanner(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                const reqData = this.getPatchReqData(trData);
                // reqData.posts = posts.map(i=> i.id)
                if (Object.keys(reqData).length) {
                    this.props.UpdateBannerById(this.props.id, reqData)
                        .finally(() => {
                            this.setState({ requestLoading: false });
                        });
                } else {
                    await this.setState({ requestLoading: false });
                    history.push('/banner')
                }

            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    postCardWrapper (data) {
        return <PostCard data={data?.item?.post}
                            onDelete={this.deletePost}
                            viewCard={true}/>
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            postsModalOpen,
            requestLoading,
            isEditing,
            errorsTabs
        } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const items = [];
        fieldsData.posts.forEach(post => {
            items.push({
                id: post.id,
                post: post
            })
        })
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Մինի-Մարկետ' : 'Փոփոխել Մինի-Մարկետը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <InputGroup inputType="input"
                            type="text"
                            label="Էջի հղում"
                            placeholder="Էջի հղում"
                            name="slug"
                            required={true}
                            value={fieldsData?.slug}
                            initValue={this.currentData?.slug}
                            error={errors?.slug}
                            maxLength={100}
                            onChange={this.getInputValues}/>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            required={true}
                            value={currentData.description}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>
                <section className={'posts-medias-list-wrapper'}>
                    <div className={'label-and-action'}>
                        <label>Ապրանքներ <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել Ապրանքներից"
                                    className={`media-select-btn ${errors.posts ? 'media-select-error' : ''}`}
                                    cb={this.togglePostsModal}/>
                    </div>
                    <div className={'items-wrapper'}>

                        {items.length ? <Nestable
                                items={items}
                                maxDepth={1}
                                onChange={this.onPositionChange}
                                renderItem={this.postCardWrapper}
                            /> :
                            <p className={'empty-text'}>Կցված ապրանքներ չկան</p>}
                    </div>
                </section>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditBanner}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
            <PostsSelectorModal
                isOpen={!!postsModalOpen}
                getSelectedPosts={this.getPosts}
                appendPosts={fieldsData.posts}
                closeModal={this.togglePostsModal}
                multiSelect={false}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateBanner,
    GetBannerById,
    UpdateBannerById,
    clearBannerData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBanner)
