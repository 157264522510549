import { _urlVisitReservation, request} from "../api";
import {RESERVATION_CONST} from "../constants";


export const GetVisitData = () => {

    const requestData = {
        url: _urlVisitReservation,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type:RESERVATION_CONST.GET_VISIT_DATA,
                    payload: data
                });
            })
    }
}

export const DeleteVisitDataById = id => {

    const requestData = {
        url: `${_urlVisitReservation}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type:RESERVATION_CONST.DELETE_VISIT_DATA,
                    payload: id
                });
            })
    }
};