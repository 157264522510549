import React, {useState} from "react";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import { defaultEmptyLogo } from "../../assets/images";

export function Image(props) {
    const {path, className = '', alt = ''} = props;
    const [brokenImage, setBrokenImage] = useState(false);

    const imageUrl = brokenImage ? defaultEmptyLogo : resizeImage(generateImageMediaUrl(path), 200, 200);
    // brokenImage &&  console.log(imageUrl);
    return <img src={imageUrl}
                className={className}
                alt={alt}
                onError={() => {
                    // console.log('broken')
                    setBrokenImage(true)
                }}/>
}
