import { INFORMATION_CONSTS } from "../constants";

export const initialState = {
    information: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case INFORMATION_CONSTS.SET_INFORMATION_DATA:
            return {
                ...state,
                information: action.payload ,
            };
        default:
            return state;
    }
}
