// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetCategories,
    DeleteCategoryById,
    UpdateCategoryById,
    UpdateCategoriesPositions
} from "../../redux/actions";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {deleteItem} from "../../utils/helperFunctions";


class Category extends Component {
    constructor(props) {
        super(props);

        this.deleteCategoryItem = this.deleteCategoryItem.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        this.props.GetCategories();
    }

    deleteCategoryItem(id){
        this.props.DeleteCategoryById(id).then(()=>{
            this.props.GetCategories();
        })
    }


    findParent(currentItem, id) {
        if (currentItem.children && currentItem.children.length &&
            currentItem.children.find(item => item.id === id)) {
            let movedItemNewIndex = -1;
            const positionedItems = currentItem.children.map((item, index) => {
                item.id === id && (movedItemNewIndex = index);
                return {id: item.id, position: index}
            });
            return {
                parent: currentItem.id,
                movedItemNewIndex: movedItemNewIndex,
                positionedItems
            };
        } else {
            let resData = null;
            currentItem.children && currentItem.children.length && currentItem.children.forEach(item => {
                let result = this.findParent(item, id);
                if (result) {
                    resData = result;
                }
            });
            return resData;
        }
    }

    getNestableItems(list, parent = null) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.title || '';

            items.push({
                id: item.id,
                parent: parent,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.icon?.path,
                link: {
                    pathname: `categories/edit/${item.id}`,
                },
                deleteCb: deleteItem.bind(this, this.deleteCategoryItem, 'կատեգորիան', item.id),
                children: !!item.subCategories?.length ? this.getNestableItems(item.subCategories, item.id) : []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedCategoryId = item.id;
        let movedCategoryParent = null;
        items && items.forEach(item => {
            let currItemRes = this.findParent(item, movedCategoryId);
            if (currItemRes) {
                movedCategoryParent = currItemRes;
            }
        });
        if (movedCategoryParent) {
            if ((movedCategoryParent.parent !== item.parent) || (movedCategoryParent.movedItemNewIndex !== item.index)) {
                const resData = {
                    parent: movedCategoryParent.parent,
                };
                (movedCategoryParent.parent !== item.parent) &&
                await this.props.UpdateCategoryById(movedCategoryId, resData, false);
                await this.props.UpdateCategoriesPositions(movedCategoryParent.positionedItems);
                this.props.GetCategories();
            }
        } else {
            // first level menu was re-positioned in first level
            const newIndex = items.findIndex(m => m.id === movedCategoryId);
            if (item.parent || item.index !== newIndex) {
                const positionedItems = items.map((item, index) => {
                    return {id: item.id, position: index}
                });
                const resData = {
                    parent: null,
                };

                item.parent && this.props.UpdateCategoryById(movedCategoryId, resData, false);
                await this.props.UpdateCategoriesPositions(positionedItems);
                this.props.GetCategories();
            }
        }
    }

    render() {
        let {categoriesList} = this.props;
        let items = this.getNestableItems(categoriesList);
        return <PageWrapper pageTitle={'Կատեգորիաներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/categories/add"}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={3}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCategories,
    DeleteCategoryById,
    UpdateCategoryById,
    UpdateCategoriesPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
