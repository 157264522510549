import {POST_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    posts: {
        itemsList: [],
        hasMore: false,
        text: '',
        filterValueId: undefined,
        categoryId: undefined,
        minPrice: '',
        maxPrice: '',
        filtered: false,
        isUrgent: null,
        isSpecialOffer: null,
        isHidden: null,
    },
    postById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_CONSTS.GET_POSTS:
            return {
                ...state,
                posts: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.posts.itemsList, ...action.payload.data],
                    text: action.payload.text,
                    categoryId: action.payload.categoryId,
                    filterValueId: action.payload.filterValueId,
                    minPrice: action.payload.minPrice,
                    maxPrice: action.payload.maxPrice,
                    filtered: action.payload.filtered,
                    hasMore: action.payload.hasMore,
                    isUrgent: action.payload.isUrgent,
                    isSpecialOffer: action.payload.isSpecialOffer,
                    isHidden: action.payload.isHidden,
                }
            };
        case POST_CONSTS.GET_POST_BY_ID:
            return {
                ...state,
                postById: action.payload
            };
        case POST_CONSTS.ADD_POST:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    itemsList: [action.payload, ...state.posts.itemsList],
                    count: state.posts.count + 1
                }
            };
        case POST_CONSTS.UPDATE_POST:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    itemsList: state?.posts?.itemsList?.map(post => post.id === action.payload.id ?
                        action.payload : post)
                }
            };
        case POST_CONSTS.TOGGLE_POST_VISIBILITY:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    itemsList: state?.posts?.itemsList?.map(post => post.id === action.payload.id ?
                        {
                            ...post,
                            isHidden: action.payload.isHidden
                        } : post)
                }
            };
        case POST_CONSTS.DELETE_POST:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    itemsList: state.posts.itemsList.filter(post => post.id !== action.payload),
                    count: state.posts.count - 1
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
