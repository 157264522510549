import {RESERVATION_CONST} from "../constants";


export const initialState = {
    visitModelItems:[]
}

export default (state = initialState,action) => {
    switch (action.type){
        case RESERVATION_CONST.GET_VISIT_DATA :
            return {
                ...state,
                visitModelItems:action.payload
            }
        case RESERVATION_CONST.DELETE_VISIT_DATA:
            return {
                ...state,
                visitModelItems: state.visitModelItems.filter(items => items.id !== action.payload)
            };

        default:
            return state;    }
}