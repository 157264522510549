export const LIMIT_GET_REVIEWS= 20;
export const LIMIT_GET_STATIONS = 20;
export const LIMIT_GET_USERS = 50;
export const LIMIT_GET_HISTORY = 20;
export const LIMIT_GET_POSTS = 20;
export const LIMIT_GET_PROMO_CODES = 20;
export const LIMIT_GET_ORDERS = 20;
export const LIMIT_GET_REQUESTS = 20;
export const LIMIT_GET_OFFERS = 20;
export const LIMIT_GET_BLOGS = 20;
