import {request, _urlUsers, _urlNotif, _urlFaq} from "../api";
import {FAQ_CONSTS, USERS_CONSTS, UTIL_CONSTS} from "../constants";
import { LIMIT_GET_USERS } from "../../constants/constLimitCounts";

export const GetUsers = ({
                             reset = true,
                             offset = 0,
                             limit = LIMIT_GET_USERS,
                             getResponse = false,
                             filters
                         } = {}) => {
    const { text = '', createdAtStartDate = null, createdAtEndDate = null, makeOrdersStartDate = null,
        makeOrdersEndDate = null, isVerified, isBlocked, } = filters || {}

    let url = `${_urlUsers}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    isVerified && (url += `&isVerified=${isVerified}`);
    isBlocked && (url += `&isBlocked=${isBlocked}`);
    createdAtStartDate && (url += `&createdAtStartDate=${createdAtStartDate}`);
    createdAtEndDate && (url += `&createdAtEndDate=${createdAtEndDate}`);
    makeOrdersStartDate && (url += `&lastOrderStartDate=${makeOrdersStartDate}`);
    makeOrdersEndDate && (url += `&lastOrderEndDate=${makeOrdersEndDate}`);
    const requestData = {
        url: url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && !getResponse && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then((res) => {
                const data = res.data;
                //console.log('%c users', 'color:red', data);
                data && !getResponse && dispatch({
                    type: USERS_CONSTS.GET_USERS,
                    payload: {
                        data,
                        reset,
                        hasMore: data?.length === limit,
                        filters
                    }
                });
                if (getResponse) {
                    return data
                }
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


export const GetUserById = (id) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(async({ data }) => {
                await dispatch({
                    type: USERS_CONSTS.GET_USER_BY_ID,
                    payload: data
                });
                dispatch({ type: UTIL_CONSTS.END_LOADING })
                //  console.log("user",data);
            })
    }
};

export const ToggleUserBlockState = (id, data) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: USERS_CONSTS.TOGGLE_USER_BLOCK_STATE,
                    payload: {
                        id,
                        state: data.isBlocked
                    }
                });
            })
    }
};

export const SendNotification = (data) => {
    const requestData = {
        url: `${_urlNotif}`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};

export const GetNotifications = () => {
    const requestData = {
        url:_urlNotif,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type:USERS_CONSTS.GET_NOTIFICATION,
                    payload: data
                });
            })
    }
};

export const clearUserById = () => {
    return dispatch => {
        dispatch({
            type: USERS_CONSTS.CLEAR_USER_BY_ID,
        });
    }
};
