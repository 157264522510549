// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import swal from "sweetalert";

// Import styles
import '../../assets/styles/containerStyles/moderators.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetModerators, DeleteModerator} from "../../redux/actions";

class Moderators extends Component {

    componentDidMount() {
        const {moderatorsList} = this.props;
        !moderatorsList?.itemsList?.length && this.props.GetModerators();
    }

    deleteModerator(removingId) {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել մոդերատորին?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteModerator(removingId);
            }
        });

    }

    render() {
        const {moderatorsList, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անուն", "Էլ․ հասցե",],
            largeItems: [1],
            status: true,
            actions: ["edit", 'delete'],
        };
        let itemListInfo = moderatorsList?.map(moderator => {
            const {id, username, firstName, lastName, isBlocked,} = moderator;
            return {
                id: id,
                values: [`${firstName} ${lastName}`, username],
                status: !isBlocked,
                deleteCb: this.deleteModerator.bind(this, id)
            };
        });
        return <PageWrapper pageTitle={'Մոդերատորներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/moderators/add"}
                        renderingItemsTitleWithCount={`Մոդերատորների քանակ ${moderatorsList?.length}`}/>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       editLink={"/moderators/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetModerators,
    DeleteModerator
};

export default connect(mapStateToProps, mapDispatchToProps)(Moderators)
