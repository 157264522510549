// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetStaticTexts, UpdateStaticTexts} from "../../redux/actions";
import {StaticTextFields} from "../../constants/staticTextFields";

// Import styles
import "../../assets/styles/containerStyles/staticTexts.scss";

// Import components
import {StaticTextRow} from "../../components/uiElements/StaticTextRow";

import {LanguageTabs} from "../../components/uiElements/Tabs";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {Editor as PrivacyPolicyEditor} from "../../components/uiElements/Editor";
import PageWrapper from "../../components/pageContentViews/pageWrapper";

const areaStaticTexts = ['apps_section1_description', 'apps_section2_description', 'shipping_description']; //"static-text-key"


class StaticTexts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            translations: {},
            editingField: null,
            editingFieldIndex: null,
            temporaryInfo: "",
            selectedPage: undefined,
            mounted: false,
            languageTab: props.mainLanguage,
            languageCode: props?.languages?.find(lg => lg.id === props?.mainLanguage)?.code,
        };
        this.changeLanguageTab = this.changeLanguageTab.bind(this);
        this.getTempValue = this.getTempValue.bind(this);
        this.getSelectedPage = this.getSelectedPage.bind(this);
        this.saveStaticTexts = this.saveStaticTexts.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.startEditing = this.startEditing.bind(this);
    }

    async componentDidMount() {
        await this.props.GetStaticTexts();
        const {languageCode} = this.state;
        this.setState({
            fields: this.props.staticTextsList && this.props.staticTextsList[languageCode || 'en'],
            mounted: true
        });
    }

    changeLanguageTab(activeTab) {
        const {languages} = this.props;
        const languageCode = languages?.find(lg => lg.id === activeTab)?.code;
        this.setState({
            languageTab: activeTab,
            fields: this.props.staticTextsList && this.props.staticTextsList[languageCode],
            languageCode
        })
    }

    startEditing(name) {
        this.setState({
            editingField: name,
            temporaryInfo: this.state.fields?.[name]
        })
    }

    cancelEditing() {
        this.setState({
            editingField: null,
            temporaryInfo: ""
        })
    }

    getTempValue({value}) {
        this.setState({
            temporaryInfo: value
        })
    }

    getSelectedPage({value}) {
        this.setState({
            selectedPage: value ?? undefined
        });
    }


    saveStaticTexts(continueEditing = null) {
        const {fields, editingField, temporaryInfo, languageCode} = this.state;
        const updatingData = {
            value: temporaryInfo
        };
        languageCode && this.props.UpdateStaticTexts(updatingData, languageCode, editingField).then(() => {
            this.setState({
                fields: {
                    ...fields,
                    [editingField]: temporaryInfo
                },
                editingField: continueEditing,
                temporaryInfo: ""
            })
        });
    }


    render() {

        const {fields, editingField, temporaryInfo, languageTab, selectedPage, mounted} = this.state;
        let staticList = [];
        if (selectedPage){
            staticList=StaticTextFields[selectedPage]?.list
        }else{
            Object.keys(StaticTextFields).forEach(page => {
                staticList = staticList?.concat(StaticTextFields[page]?.list);
            })
        }
        const selectOptions = Object.keys(StaticTextFields).map(page => ({
            id: page,
            name: StaticTextFields[page]?.pageName
        }));

        return <PageWrapper pageTitle={'Static texts'}>
            <section className="admin-static">
                {selectedPage !== 'privacyPolicy' &&
                <p className={'warning'}><span>*</span>Don’t delete '%d', it will be replaced with the username</p>}
                <div className="fixed-top-right translation-select-wrapper">

                    <InputGroup
                        inputType={"selectCustom"}
                        value={selectedPage}
                        placeholder={'Pages...'}
                        onChange={this.getSelectedPage}
                        withClear={'Բոլորը'}
                        showSearch={true}
                        options={selectOptions}
                    />
                </div>
                <div className="translation-tabs">

                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  activeTab={languageTab}
                    />
                    {
                        selectedPage !== 'privacyPolicy' ? staticList.map((row, index) => {
                                const inputType = areaStaticTexts.includes(row.key) ? 'textarea' : 'input';
                                return row.key !== 'privacy-policy-text'
                                    && <StaticTextRow key={index}
                                                      inputType={ inputType}
                                                      editingField={editingField}
                                                      value={(fields && fields[row.key]) || ""}
                                                      name={row.key}
                                                      label={row.label}
                                                      tempValue={temporaryInfo}
                                                      getTempValue={this.getTempValue}
                                                      startEditing={this.startEditing}
                                                      saveStaticTexts={this.saveStaticTexts}
                                                      cancelEditing={this.cancelEditing}/>
                            }) :
                            <div className="privacy-policy-wrapper">
                                {mounted && <PrivacyPolicyEditor
                                    value={fields && fields['privacy_policy']}
                                    name={'privacy_policy'}
                                    tempValue={temporaryInfo}
                                    getTempValue={this.getTempValue}
                                    startEditing={this.startEditing}
                                    saveStaticTexts={this.saveStaticTexts}
                                    cancelEditing={this.cancelEditing}
                                />}
                            </div>

                    }


                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetStaticTexts,
    UpdateStaticTexts,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticTexts);
