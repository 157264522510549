// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import Styles
import '../assets/styles/containerStyles/dashboard.scss';

// Import components
import InfiniteScroll from "react-infinite-scroller";
import PageWrapper from '../components/pageContentViews/pageWrapper';
import PageHeader from "../components/pageContentViews/pageHeader";
import PostCard from "../components/cards/PostCard";

// Import utils
import { mapStateToProps } from "../redux/mapStateToProps";
import { GetPosts, setPageOffset } from "../redux/actions";
import { ACCESS_PAGES } from "../constants/accessPages";
import { InputGroup } from "../components/uiElements/inputGroup";
import { defaultEmptyView } from "../assets/images";

const MAX_PRICE = 1000000;
const MIN_PRICE = 0;

const isHiddenOptions = [
    {
        name: 'Ակտիվ',
        id: 'false',
    },
    {
        name: 'Ոչ ակտիվ',
        id: 'true',
    }
];

const isUrgentOptions = [
    {
        name: 'Ոչ շտապ',
        id: 'false',
    },
    {
        name: 'Շտապ',
        id: 'true',
    }
];

const isSpecialOfferOptions = [
    {
        name: 'Ոչ հատուկ առաջարկ',
        id: 'false',
    },
    {
        name: 'Հատուկ առաջարկ',
        id: 'true',
    }
];

class Dashboard extends Component {
    constructor (props) {
        super(props);
        this.state = {
            text: '',
            filterValueId: undefined,
            categoryId: undefined,
            minPrice: '',
            maxPrice: '',
            isHidden: undefined,
            isUrgent: undefined,
            isSpecialOffer: undefined,
        };
        this.getSearchValue = this.getSearchValue.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount () {
        const { pageOffset } = this.props
        if (pageOffset.pageName === 'dashboard' && pageOffset.offset) {
            setTimeout(() => {
                window.scrollTo(0, 1000);
            }, 0)
            setPageOffset(null);
        } else {
            this.props.GetPosts();
        }

    }

    loadMoreItems () {
        const { posts } = this.props;
        const { hasMore } = posts;
        const { categoryId, filterValueId, minPrice, maxPrice, isHidden, isUrgent, isSpecialOffer, text } = this.state;
        // console.log(name, value)
        const filters = {
            filterValueId,
            categoryId,
            minPrice,
            maxPrice,
            text,
            isHidden,
            isUrgent,
            isSpecialOffer
        };
        hasMore && this.props.GetPosts({
            reset: false,
            offset: posts.itemsList.length,
            ...filters,
        });
    };

    getSearchValue (value) {
        if (!value) {
            this.handleChange({ name: 'text', value })
        } else {
            this.setState({
                'text': value,
            })
        }
    }

    async handleChangePrice (target) {
        const { minPrice, maxPrice, } = this.state;
        // console.log(target?.name === 'minPrice')
        if (target?.value < MIN_PRICE || target?.value > MAX_PRICE) {
            return;
        }        // if (target?.name === 'minPrice') {
        //     if (target?.value < MIN_PRICE || target?.value > MAX_PRICE) {
        //         return;
        //     }
        //     if (maxPrice && Number(target?.value) > Number(maxPrice)) {
        //        await this.setState({
        //             maxPrice: target.value
        //         })
        //     }
        // } else {
        //
        //     if (target?.value < MIN_PRICE || target?.value > MAX_PRICE) {
        //         return;
        //     }
        //     if (minPrice && Number(target?.value) < Number(minPrice)) {
        //      await   this.setState({
        //             minPrice: target.value
        //         })
        //     }
        // }
        this.handleChange(target);
    }

    handleChange ({ name, value } = {}) {
        const { categoryId, filterValueId, minPrice, maxPrice, text, isUrgent, isSpecialOffer, isHidden } = this.state;
        const newFilter = {
            filterValueId,
            categoryId,
            minPrice,
            maxPrice,
            isHidden,
            isUrgent,
            isSpecialOffer,
            text,
        };
        name && (newFilter[name] = value !== 'remove' ? value : undefined);

        this.setState({
            ...newFilter,
        });
        if (newFilter?.text?.length === 1) {
            return;
        }
        this.props.GetPosts(newFilter);
    }

    render () {
        const {
            text, minPrice, maxPrice, categoryId, filterValueId, errMinPrice, errMaxPrice,
            isUrgent, isHidden, isSpecialOffer
        } = this.state;
        const { posts, accessPages, isAdmin, filtersList, categoriesList } = this.props;

        const havePostsAccess = isAdmin || accessPages?.includes(ACCESS_PAGES.posts?.key);
        const filters = <div className={'product-filters'}>
            <InputGroup inputType="selectCustom"
                        placeholder="Կարգավիճակ"
                        name="isHidden"
                        showSearch={false}
                        value={isHidden}
                        onChange={this.handleChange}
                        options={isHiddenOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Շտապ / Ոչ շտապ"
                        name="isUrgent"
                        showSearch={false}
                        value={isUrgent}
                        onChange={this.handleChange}
                        options={isUrgentOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Հատուկ առաջարկ / Ոչ հատուկ առաջարկ"
                        name="isSpecialOffer"
                        showSearch={false}
                        value={isSpecialOffer}
                        onChange={this.handleChange}
                        options={isSpecialOfferOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Ընտրել Կատեգորիա"
                        name="categoryId"
                        showSearch={false}
                        value={categoryId}
                        onChange={this.handleChange}
                        options={categoriesList}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Ֆիլտրեր"
                        name="filterValueId"
                        showSearch={false}
                        value={filterValueId}
                        onChange={this.handleChange}
                        options={filtersList}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="input"
                        placeholder="Գին․ սկսած"
                        name="minPrice"
                        regExp={/^[1-9]\d*$/}
                        showSearch={false}
                        value={minPrice}
                        error={errMinPrice}
                        onChange={this.handleChangePrice}/>
            <InputGroup inputType="input"
                        placeholder="Գին․ մինչև"
                        name="maxPrice"
                        regExp={/^[1-9]\d*$/}
                        showSearch={false}
                        value={maxPrice}
                        error={errMaxPrice}
                        onChange={this.handleChangePrice}/>
        </div>;
        return <PageWrapper pageTitle={'Ապրանքներ'}>
            <section className="dashboard">
                <PageHeader linkTitle={havePostsAccess && "Ավելացնել"}
                            addingLink={havePostsAccess && "/posts/add"}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.handleChange}
                            searchValue={text}
                            search={havePostsAccess}
                            filters={havePostsAccess && filters}>
                </PageHeader>
                <InfiniteScroll
                    hasMore={posts.hasMore}
                    loadMore={this.loadMoreItems}
                    pageStart={0}
                    className={'posts'}
                    useWindow={true}
                    initialLoad={false}>
                    {!!posts?.itemsList?.length ? posts.itemsList.map((item) => {
                            return <PostCard key={item?.id}
                                             data={item}/>
                        }) :
                        <div className={'empty-view'}>
                            <img src={defaultEmptyView}/>
                        </div>}
                </InfiniteScroll>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetPosts
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
