// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/user-details.scss';

// Import components
import ItemsList from "../../components/ItemsList";
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import ModalSendNotification from "../../components/uiElements/ModalSendNotification";
import {SkeletonSmallItem} from "../../components/uiElements/SkelatonISmalltem";
import {LinkButton} from "../../components/buttons/buttons";
import {CustomTabs} from "../../components/uiElements/Tabs";
import {Switch} from 'antd';


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateUserImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {changeStateField, toggleStateField} from "../../utils/helperFunctions";
import {
    GetUserById,
    clearUserById,
    ToggleUserBlockState

} from "../../redux/actions";
import {userDetailsTabs} from "./utils/constants";
import {ordersDataParser, ordersListHeaderInfo} from "../../utils/listDataHelpers/order";
import {reviewListHeaderInfo, reviewsDataParser} from "../../utils/listDataHelpers/review";
import {bonusCardsHistoryHeaderInfo, parseBonusCardHistoryData} from "../../utils/listDataHelpers/history";
import {resizeImage} from "../../utils/resizeImage";

class UserDetails extends Component {
    constructor() {
        super();
        this.state = {
            isBlockedUpdated: false,
            notificationModalOpen: false,
            orders: {
                itemsList: [],
                hasMore: false
            },
            reviews: {
                itemsList: [],
                hasMore: false
            },
            history: {
                itemsList: [],
                hasMore: false
            },
            loading: true,
            activeTab: userDetailsTabs[0].key,
            selectingActive: false,
            selectedUsers: [],
            sendModalOpen: false,
            allSelected: false,
        };
        this.changeTab = changeStateField.bind(this, "activeTab");
        this.loadMoreOrders = this.loadMoreOrders.bind(this);
        this.loadMoreReviews = this.loadMoreReviews.bind(this);
        this.loadMoreHistory = this.loadMoreHistory.bind(this);
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
    }

    async componentDidMount() {
        const id = this.props.id;
        await this.props.GetUserById(id);
        try {
            const orders = 'await this.props.GetOrders({userId: id, getResponse: true})';
            const reviews = 'await this.props.GetReviews({userId: id, getResponse: true})';
            const history = {};
            Array.isArray(orders?.data) && this.setState({
                orders: {
                    itemsList: orders.data,
                    hasMore: orders.hasMore
                },
                loading: false
            });
            Array.isArray(reviews?.data) && this.setState({
                reviews: {
                    itemsList: reviews.data,
                    hasMore: reviews.hasMore
                },
            })
            Array.isArray(history?.data) && this.setState({
                history: {
                    itemsList: history.data,
                    hasMore: history.hasMore
                },
            })
        } catch {
            this.setState({loading: false})
        }
    }

    componentWillUnmount() {
        this.props.clearUserById();
    }

    async loadMoreOrders() {
        // console.log('load-more-orders')
        const {orders} = this.state;
        if (!this.fetchingOrders && orders.hasMore) {
            this.fetchingOrders = true;
            try {
                const newData = {};
                Array.isArray(newData) && this.setState({
                    orders: {
                        itemsList: orders.itemsList.concat(newData.data),
                        hasMore: newData.hasMore
                    }
                })
                this.fetchingOrders = false
            } catch {
            }
        }
    };

    async loadMoreReviews() {
        // console.log('load-more-orders')
        const {reviews} = this.state;
        if (!this.fetchingReviews && reviews.hasMore) {
            this.fetchingReviews = true;
            try {
                const newData = {};
                Array.isArray(newData) && this.setState({
                    reviews: {
                        itemsList: reviews.itemsList.concat(newData.data),
                        hasMore: newData.hasMore
                    }
                })
                this.fetchingReviews = false
            } catch {
            }
        }
    };

    async loadMoreHistory() {
        // // console.log('load-more-orders')
        // const {history} = this.state;
        // if (!this.fetchingHistory && history.hasMore) {
        //     this.fetchingHistory = true;
        //     try {
        //         const newData = await this.props.GetUserHistory({
        //             userId: this.props.id,
        //             offset: history.itemsList.length
        //         });
        //         Array.isArray(newData) && this.setState({
        //             history: {
        //                 itemsList: history.itemsList.concat(newData.data),
        //                 hasMore: newData.hasMore
        //             }
        //         })
        //         this.fetchingHistory = false
        //     } catch {
        //     }
        // }
    };

    async upDateUser({value}) {
        const id = this.props.id;
        await this.setState({
            isBlockedUpdated: true,
        });
        const reqData = {isBlocked: value};
        this.props.ToggleUserBlockState(id, reqData).finally(() => {
            this.setState({
                isBlockedUpdated: false,
            });
        });
    }

    toggleNotificationModal(notificationSuccessfulSend = false) {
        const newState = {
            sendModalOpen: !this.state.sendModalOpen
        }
        this.state.allSelected && (newState.allSelected = false)
        if (notificationSuccessfulSend) {
            newState.selectingActive = false;
            newState.selectedUsers = [];
            newState.sendModalOpen = false;
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    render() {
        const {isBlockedUpdated, userById, requestLoading} = this.props;
        const { selectedUsers, sendModalOpen, allSelected} = this.state;
        const { activeTab, orders, reviews, history,} = this.state;

        const headerData = {
            orders: ordersListHeaderInfo,
            reviews: reviewListHeaderInfo,
            history: bonusCardsHistoryHeaderInfo
        };
        const listData = {
            orders: ordersDataParser(orders),
            reviews: reviewsDataParser(reviews),
            history: parseBonusCardHistoryData(history),
        };
        const itemListHeaderInfo = headerData[activeTab];
        const itemListInfo = listData[activeTab];

        // const link = activeTab !== userDetailsTabs[2].key && activeTab + '/' + activeTab.slice(0, -1);
        const pageTitle = userById?.firstName ? `${userById?.firstName} ${userById?.lastName}` : ""
        return <PageWrapper pageTitle={pageTitle}>
            <section className="user-details">
                <div className="top-part">
                    <div className="user-image">
                        <img src={resizeImage(generateUserImageMediaUrl(userById?.profilePicturePath?.path))}
                             alt="user"/>
                    </div>
                    <div className="user-info">
                        <span className="username">{
                            requestLoading ?
                                <SkeletonSmallItem/> : `${userById?.firstName} ${userById?.lastName}`
                        }
                        </span>
                        <div>{
                            requestLoading ?
                                <SkeletonSmallItem/> : `${userById?.username}`
                        }</div>
                        <div>{
                            requestLoading ?
                                <SkeletonSmallItem/> : userById?.region?.title && <span>
                                {userById?.region?.title}, {userById?.region?.residence?.title}
                            </span>
                        }</div>
                    </div>
                    <div className={'block-wrapper'}>
                        <label>Blocked</label>
                        <Switch checked={userById?.isBlocked} loading={isBlockedUpdated}
                                onChange={(checked) => this.upDateUser({
                                    name: "isBlocked",
                                    value: checked,
                                })}/>
                    </div>
                </div>
                <LinkButton title={'Ուղարկել ծանուցում'}
                            className={`bg-white size-sm`}
                            cb={this.toggleNotificationModal}/>
            </section>
            <ModalSendNotification visible={sendModalOpen}
                                   sendToAllUsers={allSelected}
                                   userIds={selectedUsers}
                                   closeModal={this.toggleNotificationModal}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetUserById,
    clearUserById,
    ToggleUserBlockState
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
