// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {history} from '../configs/history';

// Import components
import {Icon} from "../components/utils/Icon";
import {LinkButton} from "../components/buttons/buttons";
import { EyeInvisibleOutlined, EyeOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {LoginAdmin, LogOut} from "../redux/actions";


// Import styles
import "../assets/styles/containerStyles/loginPage.scss";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creds: {
                username: "",
                password: "",
                grantType: "password"
            },
            errors: {
                username: false,
                password: false
            },
            passwordVisible: false
        };

        this.getInputValues = this.getInputValues.bind(this);
        this.validateInputs = this.validateInputs.bind(this);
        this.changePassVisibility = this.changePassVisibility.bind(this);
        this.logUserIn = this.logUserIn.bind(this);
        this.resetAnimation = this.resetAnimation.bind(this);
    }

    componentDidMount() {
        this.props.isLoggedIn
            ? history.push('/products')
            : this.props.LogOut();
    }

    getInputValues(e) {
        const {name, value} = e.target;

        this.setState({
            creds: {
                ...this.state.creds,
                [name]: value
            }
        })
    }

    changePassVisibility() {
        this.setState({
            passwordVisible: !this.state.passwordVisible
        })
    }

    resetAnimation() {
        this.setState({
            errors: {
                username: false,
                password: false
            }
        })
    }

    async logUserIn() {
        if (this.validateInputs()) {
            await this.props.LoginAdmin(this.state.creds)
        }
    }

    validateInputs() {
        const {username, password} = this.state.creds;
        const errors = {username: false, password: false};
        let isValidated = true;

        if (username === "") {
            errors.username = true;
            isValidated = false;
        }

        if (password === "") {
            errors.password = true;
            isValidated = false;
        }

        this.setState({
            errors
        });
        return isValidated;
    }

    render() {
        const {requestLoading} = this.props;
       // console.log(requestLoading);
        const {creds, errors, passwordVisible} = this.state;
        return <div className="login-container">
            <div className="login-left-side">
                <div className="logo-title-container">
                    <h3 className="text-upper">Welcome To</h3>
                    <h1 className="page-title">A&P Reality CMS</h1>
                    <div className="animated-fadeIn">
                        <p className="text-info">Log In to access your page</p>
                    </div>
                </div>
            </div>
            <div className="login-right-side">
                <div className="animated-fadeInRightBig">
                    <h2 className="text-upper">Log In to A&P Reality CMS</h2>
                    <div className="form-group">
                        <div className={`input-group ${errors.username ? "invalid" : ""}`}>
                            <input type="email"
                                   value={creds.username}
                                   name="username"
                                   placeholder="Username"
                                   onChange={this.getInputValues}
                                   onAnimationEnd={this.resetAnimation}
                                   onKeyDown={(e) => {
                                       if (e.keyCode === 13) {
                                           this.logUserIn()
                                       }
                                   }}/>
                            <UserOutlined />
                        </div>
                        <div className={`input-group ${errors.password ? "invalid" : ""}`}>
                            <input type={`${passwordVisible ? "text" : "password"}`}
                                   value={creds.password}
                                   name="password"
                                   placeholder="Password"
                                   onChange={this.getInputValues}
                                   onAnimationEnd={this.resetAnimation}
                                   onKeyDown={(e) => {
                                       if (e.keyCode === 13) {
                                           this.logUserIn()
                                       }
                                   }}/>
                            <LockOutlined />
                            <span className="visibility-icon"
                                  onClick={this.changePassVisibility}>
                                {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </span>
                        </div>
                        <div className="submiting-button">
                            <LinkButton cb={this.logUserIn}
                                        loading={requestLoading}
                                        title="Log In"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapDispatchToProps = {
    LoginAdmin,
    LogOut
    //RedirectFirstTime
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
