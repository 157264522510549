// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import {Radio, Select, Switch, Tree,Space} from "antd";
import { history } from "../../configs/history";
import Nestable from "react-nestable";

// Import Styles
import '../../assets/styles/containerStyles/appended-product-section.scss';
import './post-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { Editor } from "../../components/uiElements/Editor";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { MediaFileCard } from "../../components/media/MediaFileCard";
import { MaterialIcon } from "../../components/utils/Icon";


// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    arraysEquals,
    changeStateField,
    getInputValues,
    getPatchReqData,
    getPostReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField,
} from "../../utils/helperFunctions";
import {
    AddPost,
    GetPostById,
    GetRegionResidences,
    GetRegions,
    GetRoomTypes,
    UpdatePost,
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import {
    CONDITION_TYPES,
    CONTACT_PHONE_TYPES,
    MEDIA_MODAL_TYPES,
    POST_TYPES,
    PRICE_TYPES
} from "../../constants/constsTypes";
import swal from "sweetalert";

const postTypesOptions = Object.keys(POST_TYPES).map(key => ({
    id: POST_TYPES[key].key,
    title: POST_TYPES[key].name
}));
const priceTypesOptions = Object.keys(PRICE_TYPES).map(key => ({
    id: key,
    name: PRICE_TYPES[key]
}));
const conditionOptions = Object.keys(CONDITION_TYPES).map(key => ({
    id: CONDITION_TYPES[key].key,
    name: CONDITION_TYPES[key].name
}));

const currencyOptions = [
    { id: 'AMD', name: 'AMD' },
    { id: 'USD', name: 'USD' },
    { id: 'RUB', name: 'RUB' },
];

const bathroomsOptions = [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3+' },
];
const { Option } = Select;


class AddEditPost extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'details',
            'description',
            'address',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                slug: '',
                type: [],
                panoramaView:'',
                area: 0,
                priceSell: 0,
                priceRent: 0,
                squareMeterPrice: '',
                oldPrice: "",
                rentPriceType: undefined,
                currency: undefined,
                condition: undefined,
                floorNumber: '',
                buildingFloorsCount: '',
                roomsCount: '',
                bathroomsCount: undefined,
                location: {
                    region: undefined,
                    residence: undefined,
                    latitude: undefined,
                    longitude: undefined,
                },
                category: undefined,
                subCategory: undefined,
                medias: [],
                filterValues: [],
                badges: [],
                attributes: [],
                rooms: [],
                isSpecialOffer: false,
                isHidden: false,
                isUrgent: false,
                contacts: [
                    {
                        phone: '',
                        type: CONTACT_PHONE_TYPES.MOBILE.key,
                        viber: false,
                        whatsapp: false,
                        telegram: false,
                    }, {
                        phone: '',
                        type: CONTACT_PHONE_TYPES.MOBILE.key,
                        viber: false,
                        whatsapp: false,
                        telegram: false,
                    }, {
                        phone: '',
                        type: CONTACT_PHONE_TYPES.MOBILE.key,
                        viber: false,
                        whatsapp: false,
                        telegram: false,
                    }
                ]
            },
            currentData: null,
            errors: {},
            errorsTabs: [],
            loading: false,
            requestLoading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
            mediaModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.checkMediaListDifferent = this.checkMediaListDifferent.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.onRoomsPositionChange = this.onRoomsPositionChange.bind(this);
        this.addRoom = this.addRoom.bind(this);
        this.removeRoom = this.removeRoom.bind(this);
        this.getRoomValues = this.getRoomValues.bind(this);
        this.roomCardWrapper = this.roomCardWrapper.bind(this);
        this.validateData = this.validateData.bind(this);
        this.onLocationChanged = this.onLocationChanged.bind(this);
        this.getPhoneInputValues = this.getPhoneInputValues.bind(this);
        this.addUpdatePost = this.addUpdatePost.bind(this);
    }

    async componentDidMount () {
        const { isEditing, fieldsData } = this.state;
        const { roomTypesList, regions } = this.props;
        !roomTypesList?.length && this.props.GetRoomTypes()
        !regions?.length && this.props.GetRegions()

        if (isEditing) {
            const { id } = this.props;
            await this.props.GetPostById(id);
            const { postById } = this.props;
            if (!postById) {
                history.push('/posts');
                return;
            }
            if (postById?.location?.region) {
                this.props.GetRegionResidences(postById?.location?.region)
            }
            // Translations
            const translationsData = {};
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = postById.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                    details: trItem?.details || '',
                    address: trItem?.address || '',
                };
            });

            //Badges -- multiSelect
            // const badges = postById?.badges ? postById.badges.map(b => b.id) : [];

            //Medias
            const medias = [];
            if (Array.isArray(postById?.medias)) {
                postById.medias && postById.medias.forEach(media => {
                    medias.push(media);
                })
            }

            const initStateData = {
                translations: translationsData,
                slug: postById?.slug || '',
                type: postById?.type || undefined,
                area: postById?.area || '',
                panoramaView:postById?.panoramaView || '',
                priceSell: postById?.priceSell || 0,
                priceRent: postById?.priceRent || 0,
                squareMeterPrice: postById?.squareMeterPrice || '',
                oldPrice: postById?.oldPrice || '',
                rentPriceType: postById?.rentPriceType || undefined,
                currency: postById?.currency || undefined,
                condition: postById?.condition || undefined,
                floorNumber: postById.floorNumber || '',
                buildingFloorsCount: postById?.buildingFloorsCount || '',
                roomsCount: postById?.roomsCount || '',
                bathroomsCount: postById?.bathroomsCount || undefined,
                location: {
                    region: postById?.location?.region || undefined,
                    residence: postById?.location?.residence || undefined,
                    latitude: postById?.location?.latitude || undefined,
                    longitude: postById?.location?.longitude || undefined,
                },
                category: postById?.category?.id || undefined,
                subCategory: postById?.subCategory?.id || undefined,
                medias: medias,
                filterValues: postById?.filterValues || [],
                badges: postById?.badges || [],
                attributes: postById?.attributes || [],
                rooms: postById?.rooms?.length ? postById.rooms : [],
                isSpecialOffer: !!postById?.isSpecialOffer,
                isHidden: !!postById?.isHidden,
                isUrgent: !!postById?.isUrgent,
                contacts: Array.from(Array(3)).map((_, index) => {
                    const contact = postById?.contacts?.[index]
                    return {
                        phone: contact?.phone,
                        type: contact?.type || CONTACT_PHONE_TYPES.MOBILE.key,
                        viber: contact?.viber,
                        whatsapp: contact?.whatsapp,
                        telegram: contact?.telegram,
                    }
                })
            }

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getPhoneInputValues ({ name, value, haveChanges }, index) {
        const { isEditing, fieldsData, errors = {} } = this.state
        if (isEditing) {
            haveChanges
                ? this.updatedDataMap.set('contacts_' + name + index, value)
                : this.updatedDataMap.delete('contacts_' + name + index);
        }
        let contacts = this.state.fieldsData?.contacts?.map((item, i) => {
            return i === index ? {
                ...item,
                [name]: value
            } : item
        })
        const newState = {
            fieldsData: {
                ...fieldsData,
                contacts: contacts
            }
        }
        if(name==='phone' && !index){
            newState.errors={
                ...errors,
                contacts: false
            }
        }
        this.setState(newState)
    }

    checkMediaListDifferent (currMedias) {
        let result = false;
        const initMedias = this.currentData.medias;
        if (!Array.isArray(currMedias) || (currMedias?.length !== initMedias.length)) {
            result = true;
        } else {
            currMedias.forEach((item, index) => {
                if (initMedias[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }
        result ? this.updatedDataMap.set('medias', currMedias?.map(m => m.id))
            : this.updatedDataMap.delete('medias');
    }

    onMediaPositionChange (items) {
        const { fieldsData, isEditing } = this.state;
        const medias = items.map(item => item.media);
        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias
            }
        })
    }

    getMedia (mediaArray) {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.checkMediaListDifferent(mediaArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: mediaArray,
            },
        })
    }

    deleteMedia (id) {
        const { fieldsData, isEditing } = this.state;
        let medias = fieldsData.medias.filter(m => m.id !== id);

        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias,
            }
        })
    }

    getMultiSelectData = (checkedKeys, name) => {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            const updatedValues = checkedKeys?.length ? checkedKeys : []
            arraysEquals(this.currentData?.[name], checkedKeys)
                ? this.updatedDataMap.delete(name)
                : this.updatedDataMap.set(name, updatedValues);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: checkedKeys,
            }
        })
    };

    onLocationChanged ({ name, value, haveChanges }) {
        console.log(name,value,haveChanges,'haveChanges')
        const { errors = {} } = this.state
        if (this.state.isEditing) {
            let mapValue = value
            if (name === 'region' || name === 'residence') {
                mapValue = value || null
            }
            haveChanges
                ? this.updatedDataMap.set('location_' + name, mapValue)
                : this.updatedDataMap.delete('location_' + name);
        }
        const updatedData = {
            [name]: value,
        }
        if (name === 'region') {
            this.props.GetRegionResidences(value)
            updatedData['residence'] = null
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                location: {
                    ...this.state.fieldsData.location,
                    ...updatedData,
                }
            },
            errors: {
                ...errors,
                [name]: false,
            }
        })
    }

    treeData = (data, children) => {
        const treeData = [];
        data.forEach(item => {
            const treeItem = {
                title: item.title,
                key: item.id,
            };
            if (item[children] && item[children]?.length) {
                treeItem.children = this.treeData(item[children], children)
            }
            treeData.push(treeItem);
        })
        return treeData;
    };

    onRoomsPositionChange (items) {
        const { fieldsData, isEditing } = this.state;
        const rooms = items?.length && items.map(item => {
            return {
                type: item.id,
                area: item?.area
            }
        });
        if (isEditing) {
            this.updatedDataMap.set('rooms', rooms)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                rooms: rooms
            }
        })
    }

    addRoom () {
        const { fieldsData } = this.state;
        const newRoom = {
            type: undefined,
            area: 0
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                rooms: [...fieldsData.rooms, newRoom]
            },
        })
    }

    removeRoom (position) {
        const { fieldsData } = this.state;
        const newRooms = fieldsData?.rooms?.filter((_, index) => position !== index)
        this.setState({
            fieldsData: {
                ...fieldsData,
                rooms: newRooms
            },
        })
        this.updatedDataMap.set('rooms', newRooms)
    }

    getRoomValues ({ name, value, haveChanges }) {
        // console.log('name, ', name)
        // console.log(' value', value)
        // console.log('haveChanges', haveChanges)
        const [field, position] = name.split('_')
        const roomList = [...this.state.fieldsData?.rooms]

        roomList[position] = {
            ...roomList[position],
            [field]: value,
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                rooms: roomList
            }
        })
        if (this.state.isEditing) {
            haveChanges
                ? this.updatedDataMap.set('rooms_' + name, value)
                : this.updatedDataMap.delete('rooms_' + name);
        }
    }

    roomCardWrapper (data) {
        const { roomTypesList, mainLanguage } = this.props;
        const { errors } = this.state;
        const { index, item } = data;

        const roomTypeOptions = roomTypesList?.map(type => {
            const tr = type?.translations?.find(tr => tr?.languageId === mainLanguage)
            return {
                id: type?.id,
                title: tr?.title || ' '
            }
        })

        return <div className={'room-wrapper'}>
            <InputGroup inputType="selectCustom"
                        label={"Սենյակի տեսակ"}
                        placeholder="Սենյակի տեսակ"
                        name={'type_' + index}
                        required={true}
                        showSearch={false}
                        value={item?.id}
                        initValue={this.currentData?.rooms?.[index]?.type}
                        error={errors?.['rooms_' + index + '_type']}
                        onChange={this.getRoomValues}
                        options={roomTypeOptions}/>
            <InputGroup inputType="input"
                        type="text"
                        label={"Մակերես"}
                        placeholder="Մակերես"
                        name={"area_" + index}
                        number={true}
                        required={true}
                        value={item?.area}
                        initValue={this.currentData?.rooms?.[index]?.area}
                        error={errors?.['rooms_' + index + '_area']}
                        onChange={this.getRoomValues}/>
            <LinkButton title={<MaterialIcon icon={'remove'}/>} className={'bg-red'}
                        cb={this.removeRoom.bind(this, index)}/>
        </div>
    }

    mediaCardWrapper (data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia}/>
    }

    validateData (requiredFields) {
        const { fieldsData } = this.state;
        const { rooms, contacts } = fieldsData;
        let errors = {}
        let result = true

        requiredFields.forEach(item => {
            let fieldValue = fieldsData[item]
            if (['region', 'residence', 'latitude', 'longitude'].includes(item)) {
                fieldValue = fieldsData?.location?.[item];
            }
            if (!fieldValue) {
                errors[item] = true;
                result = false
            }
            if (item === 'slug' && fieldValue?.length < 3) {
                errors[item] = true;
                result = false
            }
            if(item === 'type' && !fieldsData.type.length){
                result = false
                errors[item] = true
            }
        })
        if (rooms && rooms.length) {
            const roomErrors = {}
            rooms.forEach((roomItem, index) => {
                // !roomItem.type && (roomErrors['rooms_' + index + '_type'] = true);
                !roomItem.area && (roomErrors['rooms_' + index + '_area'] = true);
            })
            if (Object.keys(roomErrors).length) {
                errors = {
                    ...errors,
                    ...roomErrors,
                }
                result = false
            }
        }
        contacts.forEach((item, index) => {
            if (!item.phone && !index) {
                errors.contacts = true
                result = false
            }
        })
        return {
            result, errors
        }
    }

    async addUpdatePost () {
        await this.setState({
            requestLoading: true
        });
        const { fieldsData } = this.state;
        const { translations } = fieldsData;
        const translationRequiredFields = ['title']
        const requiredFields = ['type', 'slug', 'region', 'residence', 'latitude', 'longitude',
            'category', 'area']
        if (fieldsData.priceSell && fieldsData.priceRent) {
            requiredFields.push('currency')
            if (fieldsData.type && fieldsData.type === POST_TYPES.RENT.key) {
                requiredFields.push('rentPriceType')
            }
        }
        const validationTr = validateTranslations(translations, translationRequiredFields)
        const validate = this.validateData(requiredFields)
        let result = validationTr.result && validate.result;
        const errors = { ...validationTr.errors, ...validate.errors };
        // console.log("validate", validate)

        if (result) {
            if (!this.state.isEditing) {
                let reqData = this.getPostReqData(fieldsData);
                // console.log('reqData', reqData)
                this.props.AddPost(reqData).finally(() => {
                    this.setState({ requestLoading: false })
                }).catch((err) => {
                    const status = err?.response?.status
                    if (status === 409) {
                        swal({
                            title: "Չհաջողվեց!",
                            text: "Սլագը զբաղված է",
                            icon: "error",
                            button: "Լավ",
                        })
                        this.setState({
                            errors: {
                                slug: true
                            }
                        })
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }
                })
            } else {
                const trData = getTranslationData(translations);
                const whiteFields = ["slug", "type","panoramaView", "area", "priceSell","priceRent", "rentPriceType", "currency", "condition", "floorNumber", "buildingFloorsCount", "roomsCount", "bathroomsCount",
                    "category", "subCategory", "medias", "filterValues", "badges", "attributes", "rooms", "isSpecialOffer", "isHidden", "isUrgent",
                ]
                let reqData = this.getPatchReqData(trData, whiteFields);
                const mapKeys = Array.from(this.updatedDataMap.keys())
                if(mapKeys.some(key => key === 'currency')){
                    reqData.priceSell = fieldsData.priceSell || 0;
                    reqData.priceRent = fieldsData.priceRent || 0;
                    reqData.currency = fieldsData.currency;
                }
                if (mapKeys.some(key => key.startsWith('location'))) {
                    reqData.location = fieldsData.location
                    reqData.location.latitude = Number(fieldsData.location.latitude)
                    reqData.location.longitude = Number(fieldsData.location.longitude)
                }
                if (mapKeys.some(key => key.startsWith('contacts'))) {
                    reqData.contacts = fieldsData?.contacts?.filter(item => !!item?.phone)
                }
                if (mapKeys.some(key => key.startsWith('rooms'))) {
                    reqData.rooms = fieldsData.rooms
                }

                // console.log('reqData 1',reqData);
                if ('priceSell' in reqData || 'priceRent' in reqData) {
                    reqData.priceSell = fieldsData.priceSell;
                    reqData.priceRent = fieldsData.priceRent;
                    reqData.currency = fieldsData.currency;
                    if (!reqData.priceSell && !reqData.priceRent) {
                        delete reqData.currency
                    }
                }

                if (reqData.type === POST_TYPES.RENT) {
                    reqData.rentPriceType = fieldsData.rentPriceType;
                }
                this.props.UpdatePost(this.props.id, reqData).finally(() => {
                    this.setState({ requestLoading: false });
                }).catch((err) => {
                    const status = err?.response?.status
                    if (status === 409) {
                        swal({
                            title: "Չհաջողվեց!",
                            text: "Սլագը զբաղված է",
                            icon: "error",
                            button: "Լավ",
                        })
                        this.setState({
                            errors: {
                                slug: true
                            }
                        })
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }
                });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }

    render () {
        const {
            badgesList,
            regions,
            regionResidences,
            attributesList,
            categoriesList,
            filtersList,
            mainLanguage
        } = this.props;
        const {
            fieldsData, languageTab, isEditing, loading, errors,
            mediaModalOpen, errorsTabs
        } = this.state;

        const { translations, contacts } = fieldsData;
        const trData = translations[languageTab] || {};
        const initTrData = this.currentData?.translations[languageTab] || {};
        const mediaItems = [];

        fieldsData?.medias?.forEach(media => {
            mediaItems.push({
                id: media?.id,
                media: media
            })
        });

        const categoryOptions = categoriesList?.map(category => {
            return {
                id: category?.id,
                title: category?.title || ' '
            }
        })|| [];

        const subCategoryOptions = categoriesList?.find(category => category.id === fieldsData?.category)?.subCategories?.map(sub => {
            return {
                id: sub?.id,
                title: sub?.title || ''
            }
        });

        const badgesOptions = badgesList?.map(badge => {
            const tr = badge?.translations?.find(tr => tr?.languageId === mainLanguage)
            return {
                id: badge?.id,
                title: tr?.title || ' '
            }
        })|| [];

        const roomsItems = (fieldsData?.rooms?.length && fieldsData?.rooms?.map(room => {
            return {
                id: room.type,
                area: room.area
            }
        })) || [];
        const regionOptions = regions?.map(item => {
            return {
                id: item?.id,
                title: item?.title
            }
        }) || [];

        const residencesOptions = regionResidences?.map(item => {
            return {
                id: item?.id,
                title: item?.title
            }
        }) || [];
        // console.log(contacts, 'contacts');
        const phoneOptions = [
            { label: CONTACT_PHONE_TYPES.MOBILE.name, value: CONTACT_PHONE_TYPES.MOBILE.key },
            { label: CONTACT_PHONE_TYPES.HOME.name, value: CONTACT_PHONE_TYPES.HOME.key },
        ];
        return <PageWrapper pageTitle={`${!isEditing ? 'Ավելացնել' : trData?.title}`}>
            <section className="post-add-edit">
                {this.state.isEditing ?  <div className={'id'}><span>id:</span>{this.props.postById?.postNumber}</div> : ""}
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ"}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: 'isHidden',
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className={'fixed-top-right fixed-special'}>
                    <label>
                        {fieldsData.isSpecialOffer ? 'Հատուկ առաջարկ Է' : 'Հատուկ առաջարկ Չէ'}
                    </label>
                    <Switch checked={!!fieldsData.isSpecialOffer}
                            onChange={(checked) => this.getInputValues({
                                name: 'isSpecialOffer',
                                value: checked,
                                haveChanges: this.currentData?.isSpecialOffer !== checked //convert to number
                            })}/>
                </div>
                <div className={'fixed-top-right fixed-urgent'}>
                    <label>
                        {fieldsData.isUrgent ? 'Շտապ Է' : 'Շտապ Չէ'}
                    </label>
                    <Switch checked={!!fieldsData.isUrgent}
                            onChange={(checked) => this.getInputValues({
                                name: 'isUrgent',
                                value: checked,
                                haveChanges: this.currentData?.isUrgent !== checked
                            })}/>
                </div>
                <div className={'posts-medias-list-wrapper '}>
                    <div className={'label-and-action'}>
                        <label>Նկարներ</label>
                        <LinkButton title="Ընտրել Մեդիադարանից"
                                    className={`media-select-btn `}
                                    cb={this.toggleMediaModal}/>
                    </div>
                    <section className={'items-wrapper media-items'}>
                        {mediaItems.length ? <Nestable
                                items={mediaItems}
                                maxDepth={1}
                                onChange={this.onMediaPositionChange}
                                renderItem={this.mediaCardWrapper}
                            /> :
                            <p className={'empty-text'}>Նկարներ չկան</p>}
                    </section>
                </div>
                <InputGroup inputType="input"
                            type="text"
                            label="360° Հղում"
                            placeholder="360° Հղում"
                            name="panoramaView"
                            value={fieldsData?.panoramaView}
                            initValue={this.currentData?.panoramaView}
                            error={errors?.panoramaView}
                            maxLength={100}
                            onChange={this.getInputValues}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                              errorsTabs={errorsTabs}/>
                <div className="post-wrapper">
                    <div className="left-part">
                        <div className="input-form">
                            <div className="tree-item">
                                <label className={`${errors.type ? 'invalid-type' : ''}`}>Տեսակ <span className={'required-badge'}>*</span></label>
                                <Tree
                                    checkable
                                    checkedKeys={fieldsData.type}
                                    onCheck={(keys) => this.getMultiSelectData(keys, 'type')}
                                    showLine={true}
                                    treeData={this.treeData(postTypesOptions, "values")}
                                >
                                </Tree>
                            </div>
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Անվանում"}
                                        placeholder="Անվանում"
                                        name="title"
                                        value={trData?.title}
                                        required={true}
                                        initValue={initTrData?.title}
                                        error={errors?.['title' + languageTab]}
                                        onChange={this.getTranslatableInputValues}/>
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Հասցե"}
                                        placeholder="Հասցե"
                                        name="address"
                                        value={trData?.address}
                                        required={false}
                                        initValue={initTrData?.address}
                                        error={errors?.['address' + languageTab]}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <InputGroup inputType="input"
                                    label={"Սլագ (մին․ 3 սիմվոլ)"}
                                    placeholder="Սլագ"
                                    name="slug"
                                    showSearch={false}
                                    value={fieldsData?.slug}
                                    required={true}
                                    initValue={this.currentData?.slug}
                                    error={errors?.slug}
                                    onChange={this.getInputValues}/>

                        <div className="quarter-wrapper">
                            <InputGroup inputType="selectCustom"
                                        label="Մարզ"
                                        placeholder="Մարզ"
                                        name="region"
                                        value={fieldsData?.location?.region}
                                        initValue={this.currentData?.location?.region}
                                        error={errors?.region}
                                        required={true}
                                        showSearch={false}
                                        options={regionOptions}
                                        onChange={this.onLocationChanged}/>
                            <InputGroup inputType="selectCustom"
                                        label="Բնակավայր"
                                        placeholder="Բնակավայր"
                                        name="residence"
                                        withClear={true}
                                        required={true}
                                        disabled={!fieldsData?.location?.region}
                                        value={fieldsData?.location?.residence}
                                        initValue={this.currentData?.location?.residence}
                                        error={errors?.residence}
                                        onChange={this.onLocationChanged}
                                        options={residencesOptions}/>
                        </div>
                        <div className="quarter-wrapper">
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Լայնություն"
                                        placeholder="Լայնություն"
                                        name="latitude"
                                        value={fieldsData?.location?.latitude}
                                        initValue={this.currentData?.location?.latitude}
                                        error={errors?.latitude}
                                        required={true}
                                        onChange={this.onLocationChanged}/>
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Երկայնություն"
                                        placeholder="Երկայնություն"
                                        name="longitude"
                                        value={fieldsData?.location?.longitude}
                                        initValue={this.currentData?.location?.longitude}
                                        error={errors?.longitude}
                                        required={true}
                                        onChange={this.onLocationChanged}/>
                        </div>
                        {
                            contacts?.map((item, index) => {
                                return <div className="row" key={index}>
                                    <InputGroup inputType="input"
                                                type="text"
                                                label={`Հեռախոս ${index + 1}`}
                                                placeholder={`Հեռախոս ${index + 1}`}
                                                name="phone"
                                                maxLength={20}
                                                regExp={/^[+\d]\d*$/}
                                                required={!index}
                                                value={contacts[index]?.phone}
                                                initValue={this.currentData?.contacts?.[index]?.phone}
                                                error={errors?.contacts && !index}
                                                onChange={(e) => this.getPhoneInputValues(e, index)}/>

                                    <div className="type_wrapper">
                                        <Radio.Group
                                            options={phoneOptions}
                                            onChange={(e) => this.getPhoneInputValues({
                                                name: 'type',
                                                value: e.target.value,
                                                haveChanges: this.currentData?.contacts?.[index]?.type !== e.target.value
                                            }, index)}
                                            value={contacts[index]?.type}
                                        />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="right-part">
                        <div className="quarter-wrapper">
                            <InputGroup inputType="selectCustom"
                                        label={"Կատեգորիա"}
                                        placeholder="Կատեգորիա"
                                        name="category"
                                        showSearch={false}
                                        value={fieldsData?.category}
                                        error={errors?.category}
                                        initValue={this.currentData?.category}
                                        required={true}
                                        onChange={this.getInputValues}
                                        options={categoryOptions}/>
                            <InputGroup inputType="selectCustom"
                                        label={"Ենթակատեգորիա"}
                                        placeholder="Ենթակատեգորիա"
                                        name="subCategory"
                                        showSearch={false}
                                        value={fieldsData?.subCategory}
                                        initValue={this.currentData?.subCategory}
                                        withClear={true}
                                        disabled={!fieldsData?.category || !subCategoryOptions?.length}
                                        onChange={this.getInputValues}
                                        options={subCategoryOptions}/>
                        </div>
                        <div className="third-wrapper">
                            <InputGroup inputType="input"
                                        type="text"
                                        number={true}
                                        minValue={1}
                                        label="Վաճառքի գին"
                                        placeholder="Վաճառքի գին"
                                        name="priceSell"
                                        value={fieldsData?.priceSell}
                                        initValue={this.currentData?.priceSell}
                                        error={errors?.priceSell}
                                        onChange={this.getInputValues}/>
                            <InputGroup inputType="input"
                                        type="text"
                                        number={true}
                                        minValue={1}
                                        label="Վարձակալության գին"
                                        placeholder="Վարձակալության գին"
                                        name="priceRent"
                                        value={fieldsData?.priceRent}
                                        initValue={this.currentData?.priceRent}
                                        error={errors?.priceRent}
                                        onChange={this.getInputValues}/>
                            <InputGroup inputType="selectCustom"
                                        label={"Փոխարժեք"}
                                        placeholder="Փոխարժեք"
                                        name="currency"
                                        showSearch={false}
                                        value={fieldsData?.currency}
                                        initValue={this.currentData?.currency}
                                        disabled={!fieldsData?.priceSell && !fieldsData.priceRent}
                                        required={fieldsData?.priceSell && fieldsData.priceRent}
                                        error={errors?.currency}
                                        onChange={this.getInputValues}
                                        options={currencyOptions}/>
                            <InputGroup inputType="selectCustom"
                                        label={"Գնի տեսակ"}
                                        placeholder="Գնի տեսակ"
                                        name="rentPriceType"
                                        showSearch={false}
                                        value={fieldsData?.rentPriceType}
                                        disabled={!fieldsData.type || fieldsData.type === POST_TYPES.SELL.key || !fieldsData.priceRent}
                                        required={fieldsData.type === POST_TYPES.RENT.key && fieldsData?.priceRent}
                                        error={errors?.rentPriceType}
                                        initValue={this.currentData?.rentPriceType}
                                        onChange={this.getInputValues}
                                        options={priceTypesOptions}/>
                            {/*<InputGroup inputType="input"
                                        type="text"
                                        number={true}
                                        label="Հին գին"
                                        minValue={1}
                                        maxValue={9999999}
                                        placeholder="Հին գին"
                                        name="oldPrice"
                                        disabled={!fieldsData?.price}
                                        value={fieldsData?.oldPrice}
                                        initValue={this.currentData?.oldPrice}
                                        onChange={this.getInputValues}/>*/}

                        </div>
                        <div className="quarter-wrapper">
                            <InputGroup inputType="input"
                                        type="text"
                                        integerNumber={true}
                                        minValue={1}
                                        label="Մակերես"
                                        placeholder="Մակերես"
                                        name="area"
                                        required={true}
                                        value={fieldsData?.area}
                                        initValue={this.currentData?.area}
                                        error={errors?.area}
                                        onChange={this.getInputValues}/>
                            <InputGroup inputType="input"
                                        type="text"
                                        integerNumber={true}
                                        label="Սենյակների քանակ"
                                        placeholder="Սենյակների քանակ"
                                        name="roomsCount"
                                        value={fieldsData?.roomsCount}
                                        initValue={this.currentData?.roomsCount}
                                        error={errors?.roomsCount}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="quarter-wrapper">
                            {/*<InputGroup inputType="input"
                                        type="text"
                                        label="Քառակուսի մետրի գինը"
                                        placeholder="Քառակուսի մետրի գինը"
                                        regExp={/^[+\d]\d*$/}
                                        name="squareMeterPrice"
                                        value={fieldsData?.squareMeterPrice}
                                        initValue={this.currentData?.squareMeterPrice}
                                        error={errors?.squareMeterPrice}
                                        onChange={this.getInputValues}/>*/}

                        </div>
                        <div className="quarter-wrapper">
                            <InputGroup inputType="input"
                                        type="text"
                                        integerNumber={true}
                                        label="Հարկ"
                                        placeholder="Հարկ"
                                        name="floorNumber"
                                        value={fieldsData?.floorNumber}
                                        initValue={this.currentData?.floorNumber}
                                        error={errors?.floorNumber}
                                        onChange={this.getInputValues}/>

                            <InputGroup inputType="input"
                                        type="text"
                                        label="Հարկերի քանակ"
                                        placeholder="Հարկերի քանակ"
                                        name="buildingFloorsCount"
                                        integerNumber={true}
                                        value={fieldsData?.buildingFloorsCount}
                                        initValue={this.currentData?.buildingFloorsCount}
                                        error={errors?.buildingFloorsCount}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="quarter-wrapper">
                            <InputGroup inputType="selectCustom"
                                        label={"Լոգասենյակների քանակ"}
                                        placeholder="Լոգասենյակների քանակ"
                                        name="bathroomsCount"
                                        showSearch={false}
                                        value={fieldsData?.bathroomsCount}
                                        error={errors?.bathroomsCount}
                                        initValue={this.currentData?.bathroomsCount}
                                        withClear={true}
                                        onChange={this.getInputValues}
                                        options={bathroomsOptions}/>
                            <InputGroup inputType="selectCustom"
                                        label={"Պայման"}
                                        placeholder="Պայման"
                                        name="condition"
                                        showSearch={false}
                                        value={fieldsData?.condition}
                                        initValue={this.currentData?.condition}
                                        withClear={true}
                                        onChange={this.getInputValues}
                                        options={conditionOptions}/>
                        </div>
                    </div>
                    <div className="trees-wrapper">
                        <div className="tree-item">
                            <label>Ֆիլտրեր</label>
                            <Tree
                                checkable
                                checkedKeys={fieldsData.filterValues}
                                onCheck={(keys) => this.getMultiSelectData(keys, 'filterValues')}
                                showLine={true}
                                treeData={this.treeData(filtersList, "values")}
                            />
                        </div>
                        <div className="tree-item">
                            <label>Բնութագրեր</label>
                            <Tree
                                checkable
                                checkedKeys={fieldsData.attributes}
                                onCheck={(keys) => this.getMultiSelectData(keys, 'attributes')}
                                showLine={true}
                                treeData={this.treeData(attributesList, "values")}
                            >
                            </Tree>
                        </div>
                        <div className={"tree-item"}/>
                        {/*<div className="tree-item">
                            <label>Պիտակներ</label>
                            <Tree
                                checkable
                                checkedKeys={fieldsData.badges}
                                onCheck={(keys) => this.getMultiSelectData(keys, 'badges')}
                                showLine={true}
                                treeData={this.treeData(badgesOptions, "values")}
                            />
                        </div>*/}
                    </div>
                </div>
                <div className={'editors'}>
                    <div className={'editor-wrapper'}>
                        <label>Ապրանքի մասին</label>
                        <Editor
                            value={trData?.details || ""}
                            name={'details'}
                            initValue={initTrData?.details}
                            error={errors['details' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                    </div>
                    <div className={'editor-wrapper'}>
                        {/*<label>Նկարագրություն</label>*/}
                        <InputGroup inputType="textarea"
                                    type="text"
                                    label="Նկարագրություն"
                                    placeholder="Նկարագրություն"
                                    name="description"
                                    value={trData.description || ""}
                                    initValue={initTrData.description}
                                    error={errors['description' + languageTab]}
                                    onChange={this.getTranslatableInputValues}/>
                        {/*<Editor value={trData.description || ""}*/}
                        {/*        name={'description'}*/}
                        {/*        initValue={initTrData.description}*/}
                        {/*        error={errors['description' + languageTab]}*/}
                        {/*        onChange={this.getTranslatableInputValues}/>*/}
                    </div>
                </div>

                <div className={'rooms'}>
                    <label className={'block-label'}>Ավելացնել սենյակ</label>

                    {!!fieldsData?.rooms?.length && <Nestable
                        items={roomsItems}
                        maxDepth={1}
                        onChange={this.onRoomsPositionChange}
                        renderItem={this.roomCardWrapper}
                    />}
                    <LinkButton title={<MaterialIcon icon={'add'}/>}
                                className={'bg-green'} cb={this.addRoom}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                loading={loading}
                                disabled={!this.updatedDataMap.size && isEditing}
                                cb={this.addUpdatePost}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                mediaType={MEDIA_MODAL_TYPES.MEDIA_ARRAY}
                multiSelect={true}
                appendMedias={fieldsData.medias}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    AddPost,
    UpdatePost,
    GetPostById,
    GetRoomTypes,
    GetRegions,
    GetRegionResidences
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPost)

