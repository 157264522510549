export const StaticTextFields = {
    // notifications: {
    //     pageName: 'Notifications',
    //     list: [
    //         {
    //             label: "Notification default error",
    //             key: "notification_default_error"
    //         },
    //         {
    //             label: "Notification default success",
    //             key: "notification_default_success"
    //         },
    //         {
    //             label: "Notification user account blocked error",
    //             key: "notification_user_account_blocked_error"
    //         },
    //         {
    //             label: "Notification user account blocked service center",
    //             key: "notification_user_account_blocked_service_center"
    //         }
    //     ]
    // },
    // login: {
    //     pageName: 'Login',
    //     list: [
    //         {
    //             label: "Login - error invalid username",
    //             key: "login_error_invalid_username"
    //         },
    //         {
    //             label: "Login - error invalid password",
    //             key: "login_error_invalid_password"
    //         },
    //         {
    //             label: "Login - error blocked account",
    //             key: "login_error_blocked_account"
    //         },
    //         {
    //             label: "Login - error unverified account",
    //             key: "login_error_unverified_account"
    //         },
    //         {
    //             label: "Login - sign in title",
    //             key: "login_form_title"
    //         },
    //         {
    //             label: "Login - sign in header text",
    //             key: "login_header_text_signIn"
    //         },
    //         {
    //             label: "Login - with google",
    //             key: "login_with_google"
    //         },
    //         {
    //             label: "Login - with facebook",
    //             key: "login_with_facebook"
    //         },
    //         /*   {
    //                label: "Login - with apple",
    //                key: "login_with_apple"
    //            },*/
    //         {
    //             label: "Login - placeholder email",
    //             key: "login_placeholder_email"
    //         },
    //         {
    //             label: "Login - placeholder password",
    //             key: "login_placeholder_password"
    //         },
    //         {
    //             label: "Login - forgot password text",
    //             key: "login_forgot_password_text"
    //         },
    //         {
    //             label: "Login - button title login",
    //             key: "login_btn_title_login"
    //         },
    //         {
    //             label: "Login - dont have account?",
    //             key: "login_dont_have_account"
    //         },
    //         {
    //             label: "Login - create an account",
    //             key: "login_create_an_account"
    //         },
    //     ]
    // },
    // signUp: {
    //     pageName: 'Sign Up',
    //     list: [
    //         {
    //             label: "SignUp - success",
    //             key: "sign_up_success"
    //         },
    //         {
    //             label: "SignUp - error exist username",
    //             key: "sign_up_error_exist_username"
    //         },
    //         {
    //             label: "SignUp - title",
    //             key: "sign_up_form_title"
    //         },
    //         {
    //             label: "SignUp - header text",
    //             key: "sign_up_header_text_for_signUp"
    //         },
    //         {
    //             label: "SignUp - placeholder firstName",
    //             key: "sign_up_placeholder_firstName"
    //         },
    //         {
    //             label: "SignUp - placeholder lastName",
    //             key: "sign_up_placeholder_lastName"
    //         },
    //         {
    //             label: "SignUp - placeholder email",
    //             key: "sign_up_placeholder_email"
    //         },
    //         {
    //             label: "SignUp - placeholder password",
    //             key: "sign_up_placeholder_password"
    //         },
    //         {
    //             label: "SignUp - placeholder repeat password",
    //             key: "sign_up_placeholder_repeat_password"
    //         },
    //         {
    //             label: "SignUp - button title register",
    //             key: "sign_up_btn_title_register"
    //         },
    //         {
    //             label: "SignUp - already have account",
    //             key: "sign_up_already_have_account"
    //         },
    //         {
    //             label: "SignUp - navigate to account",
    //             key: "sign_up_navigate_to_account"
    //         },
    //         {
    //             label: "SignUp - agreement text",
    //             key: "sign_up_agreement_text"
    //         },
    //     ]
    // },
    // accountActivation: {
    //     pageName: 'Account Activation',
    //     list: [
    //         {
    //             label: "Activation - success",
    //             key: "activation_success"
    //         },
    //         {
    //             label: "Activation - error link date expired",
    //             key: "activation_error_link_date_expired"
    //         },
    //         {
    //             label: "Activation - error account invalid code",
    //             key: "activation_error_account_invalid_code"
    //         },
    //         {
    //             label: "Activation - error account already activated",
    //             key: "activation_error_account_already_activated"
    //         },
    //     ]
    // },
    // verifyEmail: {
    //     pageName: 'Verify Email',
    //     list: [
    //         {
    //             label: "Activation - resend email success",
    //             key: "resend_email_success"
    //         },
    //         {
    //             label: "Activation - resend email error account already activated",
    //             key: "resend_email_error_account_already_activated"
    //         },
    //         {
    //             label: "Activation - link date expired",
    //             key: "activation_link_date_expired"
    //         },
    //         {
    //             label: "Activation - link resend email text",
    //             key: "activation_link_resend_email_text"
    //         },
    //         {
    //             label: "Activation - link resend email link",
    //             key: "activation_link_resend_email_link"
    //         },
    //         {
    //             label: "Verify email - title",
    //             key: "verify_email_title"
    //         },
    //         {
    //             label: "Verify email - description",
    //             key: "verify_email_description"
    //         },
    //         {
    //             label: "Verify email - description not seen",
    //             key: "verify_email_description_not_seen"
    //         },
    //         {
    //             label: "Verify email - resend email text",
    //             key: "verify_email_resend_email_text"
    //         },
    //         {
    //             label: "Verify email - description seconds",
    //             key: "verify_email_description_seconds"
    //         },
    //         {
    //             label: "Verify email - send code btn",
    //             key: "verify_email_send_code_btn"
    //         },
    //     ]
    // },
    // forgotPassword: {
    //     pageName: 'Forgot Password',
    //     list: [
    //         {
    //             label: "Forgot password - email send success",
    //             key: "forgot_email_send_success"
    //         },
    //         {
    //             label: "Forgot password - email send error",
    //             key: "forgot_email_send_error"
    //         },
    //         {
    //             label: "Forgot password - user already exist error",
    //             key: "forgot_user_already_exist_error"
    //         },
    //         {
    //             label: "Forgot password - header title",
    //             key: "forgot_password_header_title"
    //         },
    //         {
    //             label: "Change email - header title",
    //             key: "change_email_header_title"
    //         },
    //         {
    //             label: "Forgot password - header description",
    //             key: "forgot_password_header_description"
    //         },
    //         {
    //             label: "Forgot password - placeholder username",
    //             key: "forgot_password_placeholder_username"
    //         },
    //         {
    //             label: "Forgot password - button title send",
    //             key: "forgot_password_btn_title_send"
    //         },
    //         {
    //             label: "Forgot password - can send email",
    //             key: "forgot_password_can_send_email"
    //         },
    //     ]
    // },
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Info Header - privacy",
                key: "info_header_privacy"
            },
            {
                label: "Info Header - learn more",
                key: "info_header_learnMore"
            },
            {
                label: "Info Header - action title",
                key: "info_header_actions_title"
            },
            {
                label: "Menu Header - home",
                key: "menu_header_home"
            },
            {
                label: "Menu Header - contact",
                key: "menu_header_contact"
            },
            {
                label: "Menu Header - construction",
                key: "menu_header_construction"
            },
            {
                label: "Menu Header - compare",
                key: "menu_header_compare"
            },
            {
                label: "Menu Header - favorite",
                key: "menu_header_favorite"
            },
            {
                label: "Menu Header - notification",
                key: "menu_header_notification"
            },
            {
                label: "Menu Header - log out",
                key: "menu_header_log_out"
            },
            {
                label: "Menu Header - dropdown title ",
                key: "menu_header_dropdown_title"
            },
            {
                label: "Menu Header - Urgent Btn title ",
                key: "menu_header_urgentBtn_title"
            },
            {
                label: "Menu Header - sine in Btn title ",
                key: "menu_header_sign_inBtn_title"
            },
            {
                label: "Menu Header - city Moscow",
                key: "menu_header_clock_city_Moscow"
            },
            {
                label: "Menu Header - city Yerevan",
                key: "menu_header_clock_city_Yerevan"
            },
            {
                label: "Menu Header - city Los Angelos",
                key: "menu_header_clock_city_Los_Angelos"
            },
            {
                label: "Menu Header - city Paris",
                key: "menu_header_clock_city_Paris"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - about title",
                key: "homepage_about_title"
            },
            {
                label: "Homepage - about list first title",
                key: "homepage_aboutList_firstTitle"
            },
            {
                label: "Homepage - about list second title",
                key: "homepage_aboutList_secondTitle"
            },
            {
                label: "Homepage - about list third title",
                key: "homepage_aboutList_thirdTitle"
            },
            {
                label: "Homepage - about list first description",
                key: "homepage_aboutList_firstDescription"
            },
            {
                label: "Homepage - about list second description",
                key: "homepage_aboutList_secondDescription"
            },
            {
                label: "Homepage - about list third description",
                key: "homepage_aboutList_thirdDescription"
            },
            {
                label: "Homepage - banners title",
                key: "homepage_banners_title"
            },
            {
                label: "Homepage - banners btn title",
                key: "homepage_banners_btn_title"
            },
            {
                label: "Homepage - best offers title",
                key: "homepage_bestOffers_title"
            },
            {
                label: "Homepage - best offers description",
                key: "homepage_bestOffers_description"
            },
            {
                label: "Homepage - subscribe title",
                key: "homepage_subscribe_title"
            },
            {
                label: "Homepage - subscribe placeholder",
                key: "homepage_subscribe_input_placeholder"
            },
            {
                label: "Homepage - subscribe btn title",
                key: "homepage_subscribe_btn_title"
            },
            {
                label: "Homepage - post section title",
                key: "homepage_post_section_title"
            },
            {
                label: "Homepage - post section description",
                key: "homepage_post_section_description"
            },
            {
                label: "Homepage - post empty",
                key: "homepage_post_empty"
            },
            {
                label: "Homepage - post priceType mount",
                key: "homepage_post_priceType_mount"
            },
            {
                label: "Homepage - post priceType day",
                key: "homepage_post_priceType_day"
            },
            {
                label: "Homepage - post price text",
                key: "homepage_post_price_text"
            },
            {
                label: "Homepage - post area",
                key: "homepage_post_area"
            },
            {
                label: "Homepage - post price",
                key: "homepage_post_price"
            },
            {
                label: "Homepage - post visit",
                key: "homepage_post_visit"
            },
            {
                label: "Homepage - post special offer",
                key: "homepage_post_special_offer"
            },
            {
                label: "Homepage - contact info title",
                key: "homepage_contact_info_title"
            },
            {
                label: "Homepage - contact info btn title",
                key: "homepage_contact_info_btn_title"
            },
            {
                label: "Homepage - contact info btn description",
                key: "homepage_contact_info_description"
            },
            {
                label: "Homepage - contact info subtitle",
                key: "homepage_contact_info_subtitle"
            },
            {
                label: "Homepage - contact info description firth",
                key: "homepage_contact_info_description_firth"
            },
            {
                label: "Homepage -contact info description second",
                key: "homepage_contact_info_description_second"
            },
            {
                label: "Homepage -contact info description third",
                key: "homepage_contact_info_description_third"
            },
            {
                label: "Homepage -contact info questions title",
                key: "homepage_contact_info_questions_title"
            },
            {
                label: "Homepage -apartmentsCount text",
                key: "homepage_contact_info_apartmentsCount_text"
            },
            {
                label: "Homepage -commercialsCount text",
                key: "homepage_contact_info_commercialsCount_text"
            },
            {
                label: "Homepage - realEstateCount text",
                key: "homepage_contact_info_realEstateCount_text"
            },
            {
                label: "Homepage - workersCount text",
                key: "homepage_contact_info_workersCount_text"
            },
            {
                label: "Homepage - homeFilter building label",
                key: "homepage_homeFilter_building_label"
            },
            {
                label: "Homepage -homeFilter price label",
                key: "homepage_homeFilter_price_label"
            },
            {
                label: "Homepage -homeFilter room label",
                key: "homepage_homeFilter_room_label"
            },
            {
                label: "Homepage -homeFilter building placeholder",
                key: "homepage_homeFilter_building_placeholder"
            },
            {
                label: "Homepage -homeFilter room placeholder",
                key: "homepage_homeFilter_room_placeholder"
            },
            {
                label: "Homepage -homeFilter search placeholder",
                key: "homepage_homeFilter_search_placeholder"
            },
            {
                label: "Homepage -homeFilter search btn",
                key: "homepage_homeFilter_search_btn"
            },
            {
                label: "Homepage -homeFilter clear btn",
                key: "homepage_homeFilter_clear_btn"
            },
            {
                label: "Homepage -homeFilter refine btn",
                key: "homepage_homeFilter_refine_btn"
            },
            {
                label: "Homepage -homeFilter price from",
                key: "homepage_homeFilter_price_from"
            },
            {
                label: "Homepage -homeFilter price to",
                key: "homepage_homeFilter_price_to"
            },
            {
                label: "Homepage -homeFilter residence",
                key: "homepage_homeFilter_residence"
            },
            {
                label: "Homepage -homeFilter residence input text",
                key: "homepage_homeFilter_residence_input"
            },
            {
                label: "Homepage - see oll btn title",
                key: "homepage_seeOll_btnTitle"
            },
            {
                label: "Cards - condition text",
                key: "cards_condition_text_NEW_HOME"
            },
            {
                label: "Cards - good condition text",
                key: "cards_condition_text_GOOD_CONDITION"
            },
            {
                label: "Cards - need renovation condition text",
                key: "cards_condition_text_NEED_RENOVATION"
            },
            {
                label: "Cards - building floors number text",
                key: "cards_buildingFloors_text"
            },
            {
                label: "Cards - floor number text",
                key: "cards_floorNumber_text"
            },
            {
                label: "Cards - rooms count text",
                key: "cards_roomsCount_text"
            },
        ]
    },
    apartmentsSearch: {
        pageName: 'Apartments Search',
        list: [
            {
                label: "Search - sort by update reduction",
                key: "search_page_sort_by_update_reduction"
            },
            {
                label: "Search - page sort by price",
                key: "search_page_sort_by_price_increase"
            },
            {
                label: "Search - page sort by reduction",
                key: "search_page_sort_by_price_reduction"
            },
            {
                label: "Search - page sort by area",
                key: "search_page_sort_by_area_increase"
            },
            {
                label: "Search - page sort by area reduction",
                key: "search_page_sort_by_area_reduction"
            },
            {
                label: "Search - page sort by rooms increase",
                key: "search_page_sort_by_rooms_count_increase"
            },
            {
                label: "Search - page sort by rooms count",
                key: "search_page_sort_by_rooms_count_reduction"
            },
            {
                label: "Search - page empty",
                key: "search_page_empty"
            },
            {
                label: "Search - page prevBtn",
                key: "search_page_prevBtn"
            },
            {
                label: "Search - page nextBtn",
                key: "search_page_nextBtn"
            },
            {
                label: "Search - page metaTeg home",
                key: "search_page_metaTeg_home"
            },
            {
                label: "Search - page metaTeg search",
                key: "search_page_metaTeg_search"
            },
            {
                label: "Search - page filter btn",
                key: "search_page_filterBtn"
            },
            {
                label: "Search - page dropdownMap",
                key: "search_page_dropdownMap"
            },
            {
                label: "Search - page dropdownList",
                key: "search_page_dropdownList"
            },
            {
                label: "Search - page dropdownGrid",
                key: "search_page_dropdownGrid"
            },
            {
                label: "Search - page posts list title",
                key: "search_page_posts_list_title"
            },
        ]
    },
    currency: {
        pageName: 'Currency',
        list: [
            {
                label: "Currency - title",
                key: "homepage_currency_title"
            },
            {
                label: "Currency - title",
                key: "homepage_currency_change_title"
            },
            {
                label: "Currency - placeholder from",
                key: "homepage_currency_placeholder_from"
            },
            {
                label: "Currency - placeholder amount",
                key: "homepage_currency_placeholder_amount"
            },
            {
                label: "Currency - exchange",
                key: "homepage_currency_exchange"
            },
            {
                label: "Currency - last update",
                key: "homepage_currency_last_update"
            },
        ]
    },
    filters: {
        pageName: 'Filters',
        list: [
            {
                label: "Filters - building label",
                key: " filter_building_label"
            },
            {
                label: "Filters - price label",
                key: "filter_price_label"
            },
            {
                label: "Filters - room label",
                key: "filter_room_label"
            },
            {
                label: "Filters - size label",
                key: "filter_size_label"
            },
            {
                label: "Filters - floor label",
                key: "filter_floor_label"
            },
            {
                label: "Filters - apartment label",
                key: "filter_apartment_label"
            },
            {
                label: "Filters - bathrooms label",
                key: "filter_bathrooms_label"
            },
            {
                label: "Filters - condition label",
                key: "filter_condition_label"
            },
            {
                label: "Filters - building placeholder",
                key: "filter_building_placeholder"
            },
            {
                label: "Filters - floors placeholder",
                key: "filter_floors_placeholder"
            },
            {
                label: "Filters - condition placeholder",
                key: "filter_condition_placeholder"
            },
            {
                label: "Filters - apartment placeholder",
                key: "filter_apartment_placeholder"
            },
            {
                label: "Filters - room placeholder",
                key: "filter_room_placeholder"
            },
            {
                label: "Filters - search placeholder",
                key: "filter_search_placeholder"
            },
            {
                label: "Filters - search btn",
                key: "filter_search_btn"
            },
            {
                label: "Filters - clear btn",
                key: "filter_clear_btn"
            },
            {
                label: "Filters - refine btn",
                key: "filter_refine_btn"
            },
            {
                label: "Filters - from",
                key: "filter_from"
            },
            {
                label: "Filters - to",
                key: "filter_to"
            },
            {
                label: "Filters - urgent",
                key: "filter_urgent"
            },
            {
                label: "Filters - measurement unit meter",
                key: "filter_measurement_unit_meter"
            },
            {
                label: "Filters - title buy",
                key: "filter_type_title_SELL"
            },
            {
                label: "Filters - title rent",
                key: "filter_type_title_RENT"
            },
            {
                label: "Filters - new home",
                key: "filter_new_home"
            },
            {
                label: "Filters - good condition",
                key: "filter_good_condition"
            },
            {
                label: "Filters - need renovation",
                key: "filter_need_renovation"
            },
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - A&P realty title",
                key: "footer_ap_reality"
            },
            {
                label: "Footer - about title",
                key: "footer_about"
            },
            {
                label: "Footer - apartments title",
                key: "footer_apartments"
            },
            {
                label: "Footer - cooperation title",
                key: "footer_cooperation"
            },
            {
                label: "Footer - contact title",
                key: "footer_contact"
            },
            {
                label: "Footer - faq title",
                key: "footer_faq"
            },
            {
                label: "Footer - privacy title",
                key: "footer_privacy"
            },
            {
                label: "Footer - social title",
                key: "footer_social"
            },
            {
                label: "Footer - social facebook",
                key: "footer_social_facebook"
            },
            {
                label: "Footer - social instagram",
                key: "footer_social_instagram"
            },
            {
                label: "Footer - social linkedin",
                key: "footer_social_linkedin"
            },
            {
                label: "Footer - follow us",
                key: "footer_follow_us"
            },
            {
                label: "Footer - copyright text",
                key: "footer_copyright_text"
            },
            {
                label: "Footer - developed by text",
                key: "footer_developed_by_text"
            },
        ]
    },
    about: {
        pageName: 'About',
        list: [
            {
                label: "About - contact btn",
                key: "about_page_contact_btn_title"
            },
            {
                label: "About - explore btn title",
                key: "about_page_explore_btn_title"
            },
            {
                label: "FAQ_title",
                key: "faq_page_title"
            },
            {
                label: "Section-title",
                key: "section_title"
            },
        ]
    },
   registration: {
        pageName: 'Registration',
        list: [
            {
                label: "Sign In - page title",
                key: "signIn_page_title"
            },
            {
                label: "Sign In - page label user",
                key: "signIn_page_label_user"
            },
            {
                label: "Sign In - page label password",
                key: "signIn_page_label_password"
            },
            {
                label: "Sign In - page placeholder user",
                key: "signIn_page_placeholder_user"
            },
            {
                label: "Sign In - page placeholder password",
                key: "signIn_page_placeholder_password"
            },
            {
                label: "Sign In - page forgot password",
                key: "signIn_page_forgot_password"
            },
            {
                label: "Sign In - btn title",
                key: "signIn_page_btn_title"
            },
            {
                label: "Sign In - signUp btn title",
                key: "signIn_page_signUp_btn_title"
            },
            {
                label: "Sign up - page title",
                key: "signUp_page_title"
            },
            {
                label: "Sign up - label user",
                key: "signUp_page_label_user"
            },
            {
                label: "Sign up - label password",
                key: "signUp_page_label_password"
            },
            {
                label: "Sign up - label firstName",
                key: "signUp_page_label_firstName"
            },
            {
                label: "Sign up - label lastName",
                key: "signUp_page_label_lastName"
            },
            {
                label: "Sign up - label date",
                key: "signUp_page_label_date"
            },
            {
                label: "Sign up - placeholder user",
                key: "signUp_page_placeholder_user"
            },
            {
                label: "Sign up - placeholder password",
                key: "signUp_page_placeholder_password"
            },
            {
                label: "Sign up - placeholder firstName",
                key: "signUp_page_placeholder_firstName"
            },
            {
                label: "Sign up - placeholder lastName",
                key: "signUp_page_placeholder_lastName"
            },
            {
                label: "Sign up - placeholder day",
                key: "signUp_page_placeholder_day"
            },
            {
                label: "Sign up - placeholder month",
                key: "signUp_page_placeholder_month"
            },
            {
                label: "Sign up - placeholder year",
                key: "signUp_page_placeholder_year"
            },
            {
                label: "Sign up - btn title",
                key: "signUp_page_btn_title"
            },
            {
                label: "Sign up - privacy policy",
                key: "signUp_page_privacy_policy"
            },
            {
                label: "Sign up - signIn btn title",
                key: "signUp_page_signIn_btn_title"
            },
            {
                label: "Sign up - password warning",
                key: "signUp_page_password_warning"
            },
            {
                label: "Verify Email - title",
                key: "verifyEmail_page_title"
            },
            {
                label: "Verify Email - description",
                key: "verifyEmail_page_description"
            },
            {
                label: "Verify Email - send",
                key: "verifyEmail_page_send"
            },
            {
                label: "Verify Email - confirm",
                key: "verifyEmail_page_confirm"
            },
            {
                label: "Verify Email - confirm label",
                key: "verifyEmail_confirm_label"
            },
            {
                label: "Reset - password",
                key: "reset_password"
            },
            {
                label: "Forgot - password description",
                key: "forgot_password_description"
            },
            {
                label: "Reset - password description",
                key: "reset_password_description"
            },
            {
                label: "Reset - password email label",
                key: "reset_password_email_label"
            },
            {
                label: "Reset - password email placeholder",
                key: "reset_password_email_placeholder"
            },
            {
                label: "Reset - password label",
                key: "reset_password_label"
            },
            {
                label: "Reset - password placeholder",
                key: "reset_password_placeholder"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - Page title",
                key: "contacts_page_title"
            },
            {
                label: "Contacts - Page description",
                key: "contacts_page_description"
            },
            {
                label: "Contacts - Page email",
                key: "contacts_page_email"
            },
            {
                label: "Contacts - Page address",
                key: "contacts_page_address"
            },
            {
                label: "Contacts - Page fullName",
                key: "contacts_page_fullName"
            },
            {
                label: "Contacts - Page message",
                key: "contacts_page_message"
            },
            {
                label: "Contacts - Page placeholder fullName",
                key: "contacts_page_placeholder_fullName"
            },
            {
                label: "Contacts - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - Page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - Page phone title",
                key: "contacts_page_phone_title"
            },
            {
                label: "Contacts - Page telephone title",
                key: "contacts_page_telephone_title"
            },
            {
                label: "Contacts - Page address title",
                key: "contacts_page_address_title"
            },
            {
                label: "Contacts - Page email title",
                key: "contacts_page_email_title"
            },
            {
                label: "Contacts - Page send btn title",
                key: "contacts_page_sendBtn_title"
            },
        ]
    },
    errors: {
        pageName: 'Error',
        list: [
            {
                label: "ERROR - INVALID PASSWORD",
                key: "ERROR_INVALID_PASSWORD"
            },
            {
                label: "ERROR - UNVERIFIED ACCOUNT",
                key: "ERROR_UNVERIFIED_ACCOUNT"
            },
            {
                label: "ERROR - VALIDATION",
                key: "ERROR_VALIDATION"
            },
            {
                label: "ERROR - ALREADY EXISTS",
                key: "ERROR_ALREADY_EXISTS"
            },
            {
                label: "ERROR - USER ALREADY ACTIVATED",
                key: "ERROR_USER_ALREADY_ACTIVATED"
            },
            {
                label: "ERROR - USER NOT FOUND",
                key: "ERROR_USER_NOT_FOUND"
            },
            {
                label: "ERROR - USER NOT ACTIVATED",
                key: "ERROR_USER_NOT_ACTIVATED"
            },
            {
                label: "ERROR - CODE INVALID",
                key: "ERROR_CODE_INVALID"
            },
            {
                label: "ERROR - SOMETHING WRONG",
                key: "ERROR_SOMETHING_WRONG"
            },
        ]
    },
    profile: {
        pageName: 'Profile',
        list: [
            {
                label: "Profile-link settings title",
                key: "profile_linkSettings_title"
            },
            {
                label: "Profile-link compare title",
                key: "profile_linkCompare_title"
            },
            {
                label: "Profile-link favorite title",
                key: "profile_linkFavorite_title"
            },
            {
                label: "Profile-link notification title",
                key: "profile_linkNotification_title"
            },
            {
                label: "Profile-link log out title",
                key: "profile_linkLogOut_title"
            },
            {
                label: "Profile - back title",
                key: "profile_back_title"
            },
            {
                label: "Profile - settings title",
                key: "profile_settings_title"
            },
            {
                label: "Profile - settings firstName label",
                key: "profile_settings_firstName_label"
            },
            {
                label: "Profile - settings firstName placeholder",
                key: "profile_settings_firstName_placeholder"
            },
            {
                label: "Profile - settings lastName label",
                key: "profile_settings_lastName_label"
            },
            {
                label: "Profile - settings lastName placeholder",
                key: "profile_settings_lastName_placeholder"
            },
            {
                label: "Profile - settings data label",
                key: "profile_settings_data_label"
            },
            {
                label: "Profile - settings changePassword title",
                key: "profile_settings_changePassword_title"
            },
            {
                label: "Profile - settings oldPassword label",
                key: "profile_settings_oldPassword_label"
            },
            {
                label: "Profile - settings oldPassword placeholder",
                key: "profile_settings_oldPassword_placeholder"
            },
            {
                label: "Profile - settings newPassword label",
                key: "profile_settings_newPassword_label"
            },
            {
                label: "Profile - settings newPassword placeholder",
                key: "profile_settings_newPassword_placeholder"
            },
            {
                label: "Profile - settings repeatPassword label",
                key: "profile_settings_repeatPassword_label"
            },
            {
                label: "Profile - settings repeatPassword placeholder",
                key: "profile_settings_repeatPassword_placeholder"
            },
            {
                label: "Profile - settings btn save",
                key: "profile_settings_btnSave"
            },
            {
                label: "Profile - favourite title",
                key: "profile_favourite_title"
            },
            {
                label: "Profile - favourite empty tex",
                key: "profile_favourite_empty_text"
            },
            {
                label: "Profile - notification title",
                key: "profile_notification_title"
            },
            {
                label: "Profile - notification empty text",
                key: "profile_notification_empty_text"
            },
            {
                label: "Profile - compare title",
                key: "profile_compare_title"
            },
            {
                label: "Profile - compare empty text",
                key: "profile_compare_empty_text"
            },
            {
                label: "Profile - compare area",
                key: "profile_compare_area"
            },
            {
                label: "Profile - compare price",
                key: "profile_compare_price"
            },
            {
                label: "Profile - compare rent price",
                key: "profile_compare_rent_price"
            },
            {
                label: "Profile - compare floors",
                key: "profile_compare_floors"
            },
            {
                label: "Profile - compare roomsCount",
                key: "profile_compare_roomsCount"
            },
        ]
    },
    post: {
        pageName: 'Post',
        list: [
            {
                label: "Post - details title",
                key: "post_details_title"
            },
            {
                label: "Post - location title",
                key: "post_location_title"
            },
            {
                label: "Post - updated text",
                key: "post_updated_text"
            },
            {
                label: "Post - similar slider title",
                key: "post_similarSlider_title"
            },
            {
                label: "Post - link home title",
                key: "post_linkHome_title"
            },
            {
                label: "Post - link search title",
                key: "post_linkSearch_title"
            },
            {
                label: "Post - advantages title",
                key: "post_advantages_title"
            },
            {
                label: "Post - slider see oll",
                key: "post_slider_seeOll"
            },
            {
                label: "Post - slider title",
                key: "post_slider_title"
            },
            {
                label: "Post - show number btn",
                key: "post_showNumber_btn"
            },
            {
                label: "Post - favourite remove title",
                key: "post_favouriteRemove_title"
            },
            {
                label: "Post - favourite add title",
                key: "post_favouriteAdd_title"
            },
            {
                label: "Post - link copied",
                key: "link_copied"
            },
            {
                label: "Post - link copy",
                key: "link_copy"
            },
            {
                label: "Post - area",
                key: "post_area"
            },
            {
                label: "Post - new",
                key: "homepage_post_new"
            },
        ]
    },
    modal: {
        pageName: 'Visit Modal',
        list: [
            {
                label: "Modal - title",
                key: "modal_title"
            },
            {
                label: "Modal - success message",
                key: "modal_success_message"
            },
            {
                label: "Modal - error message",
                key: "modal_error_message"
            },
            {
                label: "Modal - name label",
                key: "modal_name_label"
            },
            {
                label: "Modal - name placeholder",
                key: "modal_name_placeholder"
            },
            {
                label: "Modal - phone label",
                key: "modal_phone_label"
            },
            {
                label: "Modal - phone placeholder",
                key: "modal_phone_placeholder"
            },
            {
                label: "Modal - btn cancel",
                key: "modal_btn_cancel"
            },
            {
                label: "Modal - btn save",
                key: "modal_btn_save"
            },
            {
                label: "Modal - visit title",
                key: "visit_modal_title"
            },
            {
                label: "Modal - time placeholder",
                key: "visit_time_placeholder"
            },
            {
                label: "Modal - time picker placeholder",
                key: "visit_time_picker_placeholder"
            },
            {
                label: "Modal - date placeholder",
                key: "visit_date_placeholder"
            },
            {
                label: "Modal - date picker placeholder",
                key: "visit_date_picker_placeholder"
            },
        ]
    },
}