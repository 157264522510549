// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import assets
import '../../assets/styles/dataDisplay/notification-modal.scss';

// Import components
import {Modal} from "antd";
import {LanguageTabs} from "./Tabs";
import {InputGroup} from "./inputGroup";
import {LinkButton} from "../buttons/buttons";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    changeStateField,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField
} from "../../utils/helperFunctions";

import {SendNotification} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {iconCloseDark} from "../../assets/images";
import Nestable from "react-nestable";
import MediaSelectorModal from "../media/MediaSelectorModal";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {MEDIA_MODAL_TYPES} from "../../constants/constsTypes";
import {MediaFileCard} from "../media/MediaFileCard";

class ModalSendNotification extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['title', 'description'];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                medias: [],
            },
            languageTab: props.mainLanguage,
            notificationSend: false,
            loading: false,
            errorsTabs: [],
            errors: {},
        };
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getTranslatableInputValues.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
    }
    onMediaPositionChange (items) {
        const { fieldsData, isEditing } = this.state;
        const medias = items.map(item => item.media);
        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias
            }
        })
    }

    getMedia (mediaArray) {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.checkMediaListDifferent(mediaArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: mediaArray,
            },
        })
    }

    deleteMedia (id) {
        const { fieldsData, isEditing } = this.state;
        let medias = fieldsData.medias.filter(m => m.id !== id);

        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias,
            }
        })
    }

    mediaCardWrapper (data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia}/>
    }

    async sendNotification() {
        const {translations, medias} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description']
        const validation = validateTranslations(translations, translationRequiredFields, false);
        const {userIds, sendToAllUsers} = this.props;

        if (validation.result) {
            await this.setState({loading: true});
            const Tr_Data = getTranslationData(translations);
            Object.keys(translations).forEach(key => {
                const trData = translations[key] || {};
                Tr_Data.push({
                    languageId: key,
                    title: trData.title,
                    description: trData.description
                })
            });

            const reqData = {
                translations: Tr_Data
            };
            medias && (reqData.medias = medias.map(item => item?.id));
            !sendToAllUsers && (reqData.users = userIds);
            // console.log(reqData, 'REQ_DATA');
            await this.props.SendNotification(reqData).then(() => {
                this.setState({
                    notificationSend: true
                });
            }).catch(() => {
            });
            this.setState({loading: false});
        } else {
            this.setState({
                errors: validation.errors,
                errorsTabs: validation.errorsTabs
            })
        }
    }

    closeModal = () => {
        this.props.closeModal(this.state.notificationSend);
        setTimeout(() => {
            this.setState({
                fieldsData: {
                    translations: initTranslations(this.translationsFields, this.props.activeLanguages),
                },
                notificationSend: false,
                errorsTabs: [],
                errors: {},
            });
        }, 300)
    }

    render() {
        const {visible, mainLanguage} = this.props;
        const {languageTab, fieldsData, notificationSend, loading, errorsTabs, errors, mediaModalOpen} = this.state;
        const trData = fieldsData?.translations?.[languageTab] || {};
        const mediaItems = [];

        fieldsData?.medias?.forEach(media => {
            mediaItems.push({
                id: media?.id,
                media: media
            })
        });
        // console.log(mediaItems, 'fieldsData.medias');

        return <Modal
            visible={visible}
            wrapClassName={'custom-modal'}
            onCancel={this.closeModal}
        >
            <div className={'send-notification-modal'}>
                <span className={'close-icon'} onClick={this.closeModal}>
                    <img src={iconCloseDark}
                         className="close_icon" alt=""/>
                </span>
                <h2 className="title">Ծանուցում</h2>
                {notificationSend && <div className={'message'}>Ծանուցումը հաջողությամբ ուղարկվեց</div>}
                {!notificationSend && <section className="send-notification">
                    {/*<div className={'notifications-medias-list-wrapper '}>*/}
                    {/*    <div className={'label-and-action'}>*/}
                    {/*        <label>Նկարներ</label>*/}
                    {/*        <LinkButton title="Ընտրել Մեդիադարանից"*/}
                    {/*                    className={`media-select-btn `}*/}
                    {/*                    cb={this.toggleMediaModal}/>*/}
                    {/*    </div>*/}
                    {/*    <section className={'items-wrapper media-items'}>*/}
                    {/*        {mediaItems.length ? <Nestable*/}
                    {/*                items={mediaItems}*/}
                    {/*                maxDepth={1}*/}
                    {/*                onChange={this.onMediaPositionChange}*/}
                    {/*                renderItem={this.mediaCardWrapper}*/}
                    {/*            /> :*/}
                    {/*            <p className={'empty-text'}>Նկարներ չկան</p>}*/}
                    {/*    </section>*/}
                    {/*</div>*/}
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  activeTab={languageTab}
                                  errorsTabs={errorsTabs}
                    />
                    <div className="info-adding-fields">

                        <InputGroup inputType={"input"}
                                    type={"text"}
                                    label={"Վերնագիր"}
                                    placeholder={"Վերնագիր"}
                                    name={"title"}
                                    maxLength={200}
                                    required
                                    error={errors['title' + languageTab]}
                                    value={trData?.title}
                                    onChange={this.getInputValues}/>
                        <InputGroup inputType={"textarea"}
                                    label={"Նկարագրություն"}
                                    placeholder={"Նկարագրություն"}
                                    name={"description"}
                                    maxLength={1000}
                                    required
                                    error={errors['description' + languageTab]}
                                    value={trData?.description}
                                    onChange={this.getInputValues}/>
                    </div>

                    <div className="btn-wrapper">
                        <LinkButton title="Ուղարկել Ծանուցում"
                                    loading={loading}
                                    disabled={notificationSend}
                                    loadingWithTitle={true}
                                    cb={this.sendNotification}/>
                    </div>
                </section>}
            </div>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                mediaType={MEDIA_MODAL_TYPES.MEDIA_ARRAY}
                multiSelect={true}
                appendMedias={fieldsData.medias}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </Modal>
    }
}


const mapDispatchToProps = {
    SendNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSendNotification)
