// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import ItemsList from "../../components/ItemsList";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetBadges,
    DeleteBadgeById,
} from "../../redux/actions";


class Badges extends Component {
    componentDidMount() {
        this.props.GetBadges();
    }

    render() {
        let {badgesList, mainLanguage, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Գույն"],
            largeItems: [0, 2, 3],
            status: true,
            actions: ["edit", "delete"],
        };
        let itemListInfo = badgesList?.map((badge) => {
            const {id, translations, color, isHidden} = badge;
            const mainTranslations = translations?.find(item => item.languageId === mainLanguage);
            const {title} = mainTranslations || {};
            return {
                id,
                values: [
                    title ?? '-',
                    {
                        title: <div className="color"
                                    style={{backgroundColor: color}}/>
                    }
                ],
                status: !isHidden,
                deleteCb: deleteItem.bind(this, this.props.DeleteBadgeById, 'Պիտակը', id)
            }
        });
        return <PageWrapper pageTitle={'Պիտակներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/badges/add"}/>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       editLink={"/badges/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetBadges,
    DeleteBadgeById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Badges);


function ObjectCreator(name) {
    this.firstname = name;
}

const obj = new ObjectCreator('vasya');
