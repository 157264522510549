import React from "react";
import {Route, Redirect} from "react-router-dom";
import {ACCESS_PAGES} from "../constants/accessPages";

import {store} from "../redux/store";

export const ProtectedRoute = ({ component: Component, ...rest }) =>
    <Route {...rest}
           render={(props) => {
               const { accessPages, isAdmin } = store.getState().auth;
               const pagePath = rest.location.pathname.split('/')[1];
               return (isAdmin || pagePath === 'dashboard' || accessPages?.includes(ACCESS_PAGES?.[pagePath]?.key) ?
                   <Component id={props.match.params.id}
                              location={props.location}
                              {...rest}
                              {...props}
                   /> :
                   <Redirect to="/404"/>)
           }}/>;
