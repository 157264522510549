// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import styles
import "../assets/styles/containerStyles/contact.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetContact, UpdateContact} from "../redux/actions";
import {ContactAddingList} from "../constants/contactAddingList";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues, getTranslationData, toggleStateField
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import {Editor} from "../components/uiElements/Editor";
import Nestable from "react-nestable";
import {generateImageMediaUrl} from "../utils/generateMemberMediaUrl";
import {MaterialIcon} from "../components/utils/Icon";
import {IconUpload} from "../assets/images";
import MediaSelectorModal from "../components/media/MediaSelectorModal";
import {ACCEPT_IMAGE_TYPES} from "../constants/acceptedTypes";

const format = 'HH:mm';

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['address', 'privacyPolicy',];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                email: "",
                phoneNumber: "",
                phoneNumber1:"",
                socials: [],
                latitude: '',
                longitude: '',
                facebookUrl: '',
                instagramUrl: '',
                linkedinUrl: '',
            },
            currentUpdatedSocialIndex: null,
            mediaModalOpen: false,
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: props.mainLanguage,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteSocial = this.deleteSocial.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.updateContact = this.updateContact.bind(this);
    }

    async componentDidMount() {
        !this.props?.contactData?.id && await this.props.GetContact();
        const {contactData} = this.props;
        const {fieldsData} = this.state;

        const translationsData = {};
        Object.keys(fieldsData.translations).forEach(key => {
            const trItem = contactData?.translations?.find(item => item.languageId === key);
            translationsData[key] = {
                ...fieldsData.translations[key],
                address: trItem?.address || '',
                privacyPolicy: trItem?.privacyPolicy || '',
            };
        });

        contactData && this.setState({
            fieldsData: {
                email: contactData.email || "",
                phoneNumber: contactData.phoneNumber || "",
                phoneNumber1: contactData.phoneNumber1 || "",
                facebookUrl: contactData.facebookUrl || "",
                instagramUrl: contactData.instagramUrl || "",
                linkedinUrl: contactData.linkedinUrl || "",
                translations: translationsData,
                socials: Array.isArray(contactData.socials) ? contactData.socials : [],
                latitude: contactData?.location?.latitude || "",
                longitude: contactData?.location?.longitude || "",
            },
        });
    }

    getMedia(mediaArray) {
        const {fieldsData, currentUpdatedSocialIndex} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                socials: fieldsData.socials.map((item, pos) => {
                    return pos === currentUpdatedSocialIndex ? {
                        ...item,
                        icon: mediaArray[0],
                    } : item
                })
            }
        })
    }

    deleteSocial(index) {
        const {fieldsData, errors} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                socials: fieldsData.socials.filter((_, pos) => pos !== index)
            },
            errors: {
                ...errors,
                socials: {}
            }
        })
    }

    validateFields(constacData) {
        const translationRequiredFields = ['address', 'privacyPolicy',]
        const validationTr = validateTranslations(constacData.translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        const phoneNumberRegExp = /^[+]?[0-9]{9,20}$/;
        // if (constacData?.phoneNumber?.length && !phoneNumberRegExp.test(constacData.phoneNumber)) {
        //     errors.phoneNumber = true;
        //     result = false;
        // }
        // errors.socials = {}
        // constacData.socials && constacData.socials.forEach((social, index) => {
        //     if (!social.url) {
        //         errors.socials[index + 'url'] = true;
        //         result = false;
        //     }
        //     if (!social.icon) {
        //         errors.socials[index + 'icon'] = true;
        //         result = false;
        //     }
        // })
        if (!result) {
            this.setState({
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
        return result;
    }

    onPositionChange = (items) => {
        // console.log('items', items)
        const {fieldsData,} = this.state;
        const socials = items.map(item => item.social);
        this.setState({
            fieldsData: {
                ...fieldsData,
                socials
            }
        })
    }

    updateContact() {
        const {fieldsData} = this.state;
        const {translations} = fieldsData;
        if (this.validateFields(fieldsData)) {
            let TR_data = getTranslationData(translations)

            let reqData = {
                email: fieldsData.email,
                phoneNumber: fieldsData.phoneNumber,
                phoneNumber1: fieldsData.phoneNumber1,
                facebookUrl: fieldsData.facebookUrl || '',
                instagramUrl: fieldsData.instagramUrl || '',
                linkedinUrl: fieldsData.linkedinUrl || '',
                translations: TR_data,
                location: {
                    latitude: fieldsData?.latitude,
                    longitude: fieldsData?.longitude,
                },
            };
            // console.log('reqData', fieldsData.socials);
            // reqData.socials = fieldsData.socials.map(social => ({
            //     url: social.url,
            //     icon: social.icon.id
            // }))
            this.props.UpdateContact(reqData).then(() => {
                this.setState({
                    errors: {},
                    errorsTabs: []
                })
            })
        }
    }

    getNestableItems() {
        const {fieldsData} = this.state
        const {socials} = fieldsData
        const items = [];
        socials && !!socials.length && socials.forEach((item, index) => {
            items.push({
                id: index,
                index: index,
                social: item
            })
        });
        return items;
    }

    addSocialItem = () => {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                socials: [{
                    icon: null,
                    url: ''
                },
                    ...this.state.fieldsData.socials]
            }
        })
    }

    openMediaModal = (index) => {
        this.setState({
            currentUpdatedSocialIndex: index
        })
        this.toggleMediaModal()
    }

    getUrlValue = (index, value) => {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                socials: this.state.fieldsData.socials.map((item, pos) => {
                    return pos === index ? {
                        ...item,
                        url: value
                    } : item
                })
            }
        })
    }

    socialItem = (data) => {
        const {errors} = this.state
        const {index, social} = data.item
        return <div className={'social-item'}>
            <div className={`social-image ${errors?.socials?.[index + 'icon'] ? 'error' : ''}`}>
                {social?.icon?.path &&
                <img className={'social-icon'} src={generateImageMediaUrl(social?.icon?.path)} alt={''}/>}
                <div className={`overlay ${!social?.icon?.path ? 'show' : ''}`}
                     onClick={() => this.openMediaModal(index)}>
                    <IconUpload/>
                </div>
            </div>
            <InputGroup inputType="input"
                        type="text"
                        label="Հղում"
                        placeholder="Հղում"
                        name="url"
                        value={social?.url}
                        maxLength={100}
                        error={errors?.socials?.[index + 'url']}
                        onChange={({value}) => this.getUrlValue(index, value)}/>
            <div className={`delete-btn`} onClick={() => this.deleteSocial(index)}>
                <MaterialIcon icon="delete"/>
            </div>
        </div>
    }

    render() {
        const {requestLoading, contactData: contact} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs, mediaModalOpen} = this.state;
        // console.log(fieldsData, 'fieldsData');
        const {translations} = fieldsData;
        const initTranslations = contact?.translations?.find(lg => lg.language === languageTab) || {};
        let items = this.getNestableItems();
        const trData = translations[languageTab] || {};
        return <PageWrapper pageTitle={'Կապ'}>
            <section className="contact">
                <div className="info-adding-fields">
                    {ContactAddingList.map((item, index) => {
                        return <InputGroup key={index}
                                           {...item}
                                           error={errors[item.name]}
                                           value={fieldsData?.[item.name]}
                                           initValue={contact?.[item.name]}
                                           onChange={this.getInputValues}/>
                    })}
                    <div className="row">
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Լայնություն"
                                    placeholder="Լայնություն"
                                    regExp={/^[0-9]*(\.)?[0-9]*$/}
                                    maxValue={90}
                                    minValue={-90}
                                    name="latitude"
                                    required={true}
                                    value={fieldsData?.latitude}
                                    error={errors['latitude']}
                                    onChange={this.getInputValues}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Երկայնություն"
                                    placeholder="Երկայնություն"
                                    regExp={/^[0-9]*(\.)?[0-9]*$/}
                                    maxValue={180}
                                    minValue={-180}
                                    name="longitude"
                                    required={true}
                                    value={fieldsData?.longitude}
                                    error={errors['longitude']}
                                    onChange={this.getInputValues}/>
                    </div>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Հասցե'}
                            name="address"
                            maxLength={100}
                            value={trData?.address}
                            required={true}
                            initValue={initTranslations?.address}
                            error={errors['address' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <div className="editor-wrapper">
                    <label>Գաղտնիության քաղաքականություն <span className={'required-badge'}>*</span></label>
                    <Editor value={trData?.privacyPolicy || ''}
                            name={'privacyPolicy'}
                            initValue={initTranslations?.privacyPolicy}
                            error={errors['privacyPolicy' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                </div>
                <div className={'socials-wrapper'}>
                    <label>Սոցիալական հղումներ </label>
                    <div className={'add-row'}>
                        <LinkButton title="Ավելացնել"
                                    className={`media-select-btn`}
                                    cb={this.addSocialItem}/>
                    </div>
                    {items && items.length !== 0 &&
                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onPositionChange}
                        renderItem={this.socialItem}
                    />}
                    {/*     {!!fieldsData.socials.length && fieldsData.socials.map(social=>{
                        return <div className={'social-item'}>

                        </div>
                    })}*/}
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetContact,
    UpdateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
