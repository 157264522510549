// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {history} from "../../configs/history";

// Import Styles
import "../../assets/styles/cards/userCard.scss";
import { iconBlock, iconDoneGray, iconDone } from "../../assets/images";

// Import components
import {Checkbox, Dropdown, Menu} from "antd";
import { MoreOutlined } from '@ant-design/icons';

//import utils
import {ToggleUserBlockState} from "../../redux/actions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateUserImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {resizeImage} from "../../utils/resizeImage";

class UserCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.blockUser = this.blockUser.bind(this);
    }

    blockUser(isBlocked) {
        const {id} = this.props.data;
        let data = {
            isBlocked,
        };
        this.props.ToggleUserBlockState(id, data);
    }

    render() {
        const {data, selectingActive, checkboxData} = this.props;
        const {
            firstName, lastName, profilePicturePath, username, isVerified,
            isBlocked, isPro, id
        } = data || {};
        const userCardMenu = (<Menu className="userCard-action-menu">
            <Menu.Item key={'block'}
                       className="action"
                       onClick={() => this.blockUser(!isBlocked)}>
                <span>{isBlocked ? 'UnBlock' : 'Block'}</span>
            </Menu.Item>
        </Menu>);
        return <div className="user">
            {selectingActive && <div className="round-checkbox">
                <Checkbox className="round"
                          checked={checkboxData?.selected}
                          onChange={checkboxData?.handleSelect}/>
            </div>}
            <div className="user-card-wrapper" onClick={() => {
                history.push({
                    pathname: `/users/user/${id}`,
                })
            }}>
                <div className="upper-part">
                    <div className={'card-actions'} onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <Dropdown overlay={userCardMenu}
                                  placement={"bottomRight"}
                                  trigger={['click']}>
                            <MoreOutlined />
                        </Dropdown>
                    </div>

                    <div className="user-image">
                        <img src={resizeImage(generateUserImageMediaUrl(profilePicturePath?.path), 100, 100)}
                             alt="user"/>
                    </div>
                    <div className="username">
                        <span>{firstName} {lastName}</span>
                        {
                            isBlocked &&
                            <img className={'iz-block-badge'}
                                 src={iconBlock}
                                 alt={'block'}
                            />
                        }
                    </div>
                </div>
                <div className="user-email">
                    <img className={'iz-block-badge'} src={isVerified? iconDone :iconDoneGray}
                         alt={'done'}
                    />
                    <span>{username}</span>
                </div>
            </div>
        </div>
    }
}


const mapDispatchToProps = {
    ToggleUserBlockState,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard)
