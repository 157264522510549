// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateSlider, UpdateSliderById } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { SLIDER_RESOURCE_TYPES } from "../../constants/constsTypes";
import isMongoId from "validator/es/lib/isMongoId";
import isURL from "validator/es/lib/isURL";
import PostsSelectorModal from "../../components/PostsSelectorModal";
import PostCard from "../../components/cards/PostCard";
import Nestable from "react-nestable";

class AddEditSlider extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                mediaMainMobile: undefined,
                url: '',
                post: null,
                type: undefined,
                resourceId: '',
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            postsModalOpen: false,

        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleMediaMobileModal = toggleStateField.bind(this, 'mediaMobileModalOpen');
        this.togglePostsModal = toggleStateField.bind(this, 'postsModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getTypeValue = this.getTypeValue.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getMediaMobile = this.getMediaMobile.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditSlider = this.addEditSlider.bind(this);
        this.getPosts = this.getPosts.bind(this);
        this.deletePost = this.deletePost.bind(this);

        this.sliderResourceOptions = Object.keys(SLIDER_RESOURCE_TYPES).map(key => {
            return {
                id: SLIDER_RESOURCE_TYPES[key].key,
                name: SLIDER_RESOURCE_TYPES[key].title,
            }
        })
    }

    async componentDidMount () {
        const { isEditing, fieldsData } = this.state;
        if (isEditing) {
            const translationsData = {};
            const { sliderList } = this.props;
            const editingData = sliderList.find(slider => slider.id === this.props.id);
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                url: editingData?.url || '',
                resourceId: editingData?.resourceId || '',
                type: editingData?.type || undefined,
                mediaMain: editingData?.mediaMain,
                mediaMainMobile: editingData?.mediaMainMobile,
                isHidden: editingData?.isHidden,
                post: editingData?.post,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set("mediaMain", mediaArray[0]?.id)
                : this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    getMediaMobile (mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMainMobile?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set("mediaMainMobile", mediaArray[0]?.id)
                : this.updatedDataMap.delete("mediaMainMobile");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMainMobile: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMainMobile: false
            }
        })
    }

    deleteMedia (type) {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.[type] ?
                this.updatedDataMap.set(type, null) :
                this.updatedDataMap.delete(type);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: null
            }
        })
    }

    getTypeValue ({ value }) {
        const { fieldsData, isEditing } = this.state;

        // console.log('type', value);
        if (isEditing) {
            if (this.currentData?.type) {
                if (!value) {
                    this.updatedDataMap.set("type", null);
                } else {
                    value === this.currentData?.type ? this.updatedDataMap.delete("type") :
                        this.updatedDataMap.set("type", value);
                }
            } else {
                value ? this.updatedDataMap.set("type", value) :
                    this.updatedDataMap.delete("type");
            }
            this.updatedDataMap.set("resourceId", '');
        }

        this.setState({
            fieldsData: {
                ...fieldsData,
                type: value,
                resourceId: ''
            }
        })
    }

    getPosts (postsArray) {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.post?.id !== postsArray?.[0]?.id
                ? this.updatedDataMap.set("post", postsArray[0]?.id)
                : this.updatedDataMap.delete("post");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                post: postsArray?.[0],
            }
        })
    }

    deletePost () {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.['post'] ?
                this.updatedDataMap.set('post', null) :
                this.updatedDataMap.delete('post');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                post: null,
            }
        })
    }

    async addEditSlider () {
        await this.setState({
            requestLoading: true
        });
        const {
            translations,
            mediaMain,
            mediaMainMobile,
            url,
            isHidden,
            type,
            resourceId,
            post
        } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (!mediaMainMobile) {
            errors.mediaMainMobile = true;
            result = false;
        }

        if (type && !resourceId) {
            errors.resourceId = true;
            result = false;
        }
        // if (url && !isURL(url)) {
        //     errors.url = true;
        //     result = false;
        // }
        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    mediaMain: mediaMain.id,
                    mediaMainMobile: mediaMainMobile.id,
                    translations: getTranslationData(translations)
                };
                if (url) {
                    reqData.url = url
                } else if (type && resourceId) {
                    reqData.type = type;
                    reqData.resourceId = resourceId;
                }
                if (post) {
                    reqData.post = post?.id
                }
                this.props.CreateSlider(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                if(post){
                    reqData.post = post.id
                }
                this.props.UpdateSliderById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            mediaMobileModalOpen,
            requestLoading,
            isEditing,
            errorsTabs,
            postsModalOpen,
        } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Սլայդեր' : 'Փոփոխել Սլայդերը'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className={"images-wrapper"}>
                    <div>
                        <div className="top-side">
                            <label>Մեծ նկար <span className={'required-badge'}>*</span></label>
                            <LinkButton title="Ընտրել մեդիադարանից"
                                        className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                        cb={this.toggleMediaModal}
                            />
                        </div>
                        {fieldsData?.mediaMain && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.mediaMain}
                                           customDelete={() => this.deleteMedia("mediaMain")}
                            />
                        </div>}
                    </div>
                    <div>
                        <div className="top-side">
                            <label>Փոքր Նկար <span className={'required-badge'}>*</span></label>
                            <LinkButton title="Ընտրել մեդիադարանից"
                                        className={`media-select-btn ${errors.mediaMainMobile ? 'media-select-error' : ''}`}
                                        cb={this.toggleMediaMobileModal}
                            />
                        </div>
                        {fieldsData?.mediaMainMobile && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.mediaMainMobile}
                                           customDelete={() => this.deleteMedia("mediaMainMobile")}
                            />
                        </div>}
                    </div>
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="slider-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                label="Հղում"
                                placeholder="Հղում"
                                name="url"
                                disabled={fieldsData?.type || fieldsData?.resourceId}
                                value={fieldsData?.url}
                                initValue={this.currentData?.url}
                                error={errors?.url}
                                maxLength={100}
                                onChange={this.getInputValues}/>
                    {/* <div className={'resources'}>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Էջ"
                                    label="Էջ"
                                    name="type"
                                    showSearch={false}
                                    withClear={true}
                                    disabled={fieldsData?.url}
                                    value={fieldsData?.type}
                                    onChange={this.getTypeValue}
                                    options={this.sliderResourceOptions}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Id"
                                    placeholder="Id"
                                    name="resourceId"
                                    value={fieldsData.resourceId}
                                    disabled={fieldsData?.url}
                                    initValue={this.currentData?.resourceId}
                                    error={errors['resourceId']}
                                    maxLength={24}
                                    onChange={this.getInputValues}/>
                    </div>*/}
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Ենթավերնագիր"
                                placeholder="Ենթավերնագիր"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>
                <section className={'posts-medias-list-wrapper'}>
                    <div className={'label-and-action'}>
                        <label>Ապրանքներ <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել Ապրանքներից"
                                    className={`media-select-btn ${errors.posts ? 'media-select-error' : ''}`}
                                    cb={this.togglePostsModal}/>
                    </div>
                    <div className={'items-wrapper'}>

                        {fieldsData?.post ? <PostCard data={fieldsData?.post}
                                                      onDelete={this.deletePost}
                                                      viewCard={true}/>:
                            <p className={'empty-text'}>Կցված ապրանքներ չկան</p>}
                    </div>
                </section>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditSlider}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
            <MediaSelectorModal
                isOpen={!!mediaMobileModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMediaMobile}
                closeModal={this.toggleMediaMobileModal}/>
            <PostsSelectorModal
                isOpen={!!postsModalOpen}
                getSelectedPosts={this.getPosts}
                appendPosts={fieldsData.posts}
                closeModal={this.togglePostsModal}
                multiSelect={false}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateSlider,
    UpdateSliderById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSlider)
