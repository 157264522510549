// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/filter-attr-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {Checkbox, Switch} from "antd";
import {MaterialIcon} from "../../components/utils/Icon";

// Import utils
import {
    changeStateField,
    getInputValues,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations, toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateAttribute} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";


class AddAttribute extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                isHidden: false,
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                icon: undefined,
            },
            errors: {},
            errorsTabs: [],
            mediaModalOpen: false,
            languageTab: props.mainLanguage,
        };
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addAttributeValue = this.addAttributeValue.bind(this);
        this.removeAttribute = this.removeAttribute.bind(this);
        this.getAttributeValues = this.getAttributeValues.bind(this);
        this.addAttribute = this.addAttribute.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
    }

    getAttributeValues({name, value, index}) {
        const {languageTab} = this.state;
        const {values, isHidden} = this.state.fieldsData;
        let attributeIsHidden = isHidden;
        let newAttributeList = [...values];
        if (name === 'isHidden') {
            newAttributeList[index].isHidden = !newAttributeList[index].isHidden;
            attributeIsHidden = newAttributeList.every(f => f.isHidden) ? true : isHidden
        } else {
            newAttributeList[name].translations[languageTab].title = value;
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: newAttributeList,
                isHidden: attributeIsHidden
            }
        })
    }

    getMedia (mediaArray) {
        // console.log(mediaArray,'Array')

        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                icon: mediaArray[0],
            },
            errors: {
                ...this.state.errors,
                icon: false
            }
        })
    }

    deleteMedia () {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                icon: null
            }
        })
    }

    addAttributeValue() {
        let attributeItem = {
            translations: initTranslations(this.translationsFields, this.props.activeLanguages),
            isHidden: false,
        };
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: [...this.state.fieldsData.values, attributeItem]
            },
        })
    }

    removeAttribute(position) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: this.state.fieldsData.values.filter((_, index) => position !== index)
            }
        })
    }

    async addAttribute() {
        const {fieldsData} = this.state;
        const {translations, isHidden, values,icon} = fieldsData;
        await this.setState({
            requestLoading: true
        });
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = validationTr.errors;
        let TR_data = getTranslationData(translations);
        if (!icon) {
            errors.icon = true;
            result = false;
        }
        if (result) {
            let position = 0;
            this.props.attributesList.forEach(item => {
                if (item.position > position) {
                    position = item.position
                }
            });
            const reqData = {
                isHidden,
                position: position + 1,
                icon:icon?.id,
                translations: TR_data
            };
            await this.props.CreateAttribute(reqData)
                .finally(() => {
                    this.setState({requestLoading: false});
                });
        } else {
            this.setState({
                requestLoading: false,
                errors,
                errorsTabs: validationTr.errorsTabs
            })
        }


    }

    render() {
        const {fieldsData, languageTab, requestLoading,mediaModalOpen, errorsTabs, errors} = this.state;
        const trData = fieldsData.translations[languageTab] || {};
        return <PageWrapper pageTitle={'Ավելացնել Բնութագիր'}>
            <section className="items-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                            })}/>
                </div>

                <div className="top-side">
                    <label>պատկեր <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.icon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.icon}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                              errorsTabs={errorsTabs}
                />
                <div className="section-wrapper">
                    <div className="section-left-part">
                        <div className="w-100">
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Անվանում"
                                        placeholder="Անվանում"
                                        name="title"
                                        value={trData.title}
                                        error={errors['title' + languageTab]}
                                        maxLength={100}
                                        required={true}
                                        onChange={this.getTranslationValues}/>
                        </div>
                    </div>
                    {/*<div className="section-items">*/}
                    {/*    <label className={'label'}>Բնութագրեր <span className={'required-badge'}>*</span></label>*/}
                    {/*    {fieldsData?.values?.map((attribute, index) => {*/}
                    {/*        return <div className="item-row" key={index}>*/}

                    {/*            <InputGroup*/}
                    {/*                value={attribute?.translations[languageTab]?.title}*/}
                    {/*                error={errors['value' + index + languageTab]}*/}
                    {/*                name={`${index}`}*/}
                    {/*                inputType={"input"}*/}
                    {/*                placeholder={"Բնութագիր"}*/}
                    {/*                maxLength={100}*/}
                    {/*                onChange={this.getAttributeValues}*/}
                    {/*            />*/}
                    {/*            <div className="checkbox-wrapper">*/}
                    {/*                <Checkbox checked={!attribute.isHidden}*/}
                    {/*                          onChange={(checked) => this.getAttributeValues({*/}
                    {/*                              name: "isHidden",*/}
                    {/*                              value: !checked,*/}
                    {/*                              index,*/}
                    {/*                          })}/>*/}
                    {/*            </div>*/}
                    {/*            {fieldsData?.values?.length > 1 &&*/}
                    {/*            <LinkButton className="bg-red delete-item-btn"*/}
                    {/*                        title={<MaterialIcon icon="delete"/>}*/}
                    {/*                        cb={() => this.removeAttribute(index)}/>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    })}*/}
                    {/*    <LinkButton title={<MaterialIcon icon="add"/>}*/}
                    {/*                cb={this.addAttributeValue}/>*/}
                    {/*</div>*/}
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton
                        title={"Ավելացնել"}
                        loading={requestLoading}
                        cb={this.addAttribute}
                    />
                </div>

            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateAttribute
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAttribute)
