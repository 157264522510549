import moment from "moment";
import React from "react";
export const ordersListHeaderInfo = {
    generalInfo: ["Օգտատեր", "Հեռախոս", "Գին", "Պատվերի համար", "ՀԴՄ", "Տեսակ", "Ամսաթիվ"],
    status: true,
    largeItems: [],
};

export function ordersDataParser(ordersList, ) {
    let itemListInfo = [];
    ordersList.itemsList.forEach(item => {
        const name = item?.user ? (item?.user?.firstName + " " + item?.user?.lastName) : item?.name;

        itemListInfo.push({
            id: item.id,
            status: {
                title: item?.status?.title || '',
                color: item?.status?.color || '',
            },
            newItem: !(item?.seen ?? true),
            values: [
                name || "",
                item?.phoneNumber || item?.username,
                item?.products.reduce((sum, item)=>{return sum + item.price * item.purchasedQuantity},0) + " Դրամ",
                item.orderNumber || "-",
                item?.hdmLink ? {title: <a href={item?.hdmLink} target={"_blank"} className={`${item?.hdmReversed ? 'deleted' : ''}`} ><span>PDF</span></a>} : '-',
                item?.isPaid ? "Վճարված է" : "Վճարված չէ",
                moment(item.createdAt).format('DD.MM.YYYY HH:mm'),
            ],
        });
    });
    return itemListInfo;
}
