import { request, _urlInformation } from "../api";
import { INFORMATION_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetInformationData = () => {
    const requestData = {
        url: _urlInformation,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: INFORMATION_CONSTS.SET_INFORMATION_DATA,
                    payload: data
                });
            })
    }
};

export const UpdateInformationData = (data) => {
    // console.log(data,"PPP")
    const requestData = {
        url: `${_urlInformation}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: INFORMATION_CONSTS.SET_INFORMATION_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                return res.data
            })
    }
};

export const UpdateInformationItemData = (data) => {
    const requestData = {
        url: `${_urlInformation}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: INFORMATION_CONSTS.SET_INFORMATION_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/information')
                return res.data
            })
    }
};
