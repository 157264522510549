import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import users from "./users";
import moderators from "./moderators";
import staticTexts from "./staticTexts";
import media from "./media";
import composite from "./composite";
import filter from "./filter";
import category from "./category";
import post from "./post";
import slider from "./slider";
import badges from "./badges";
import customPages from "./customPages";
import attributes from "./attributes";
import general from "./general";
import information from "./information";
import subscribers from "./subscribers";
import notifications from "./notifications";
import roomType from "./roomType";
import banner from "./banner";
import faq from "./faq";
import reservation from "./reservation"


export default combineReducers({
    information,
    auth,
    utils,
    languages,
    users,
    media,
    composite,
    moderators,
    staticTexts,
    filter,
    category,
    post,
    slider,
    badges,
    customPages,
    attributes,
    general,
    subscribers,
    notifications,
    banner,
    roomType,
    faq,
    reservation
});
