import notificationMusic from "../assets/sound/notification-sound.mp3";
// import notificationMusic from "../assets/vasya.mp3";
import {generateFileMediaUrl} from "./generateMemberMediaUrl";

export const _Sound = () => {
    const audio = new Audio(generateFileMediaUrl('/resources/notification-sound.mp3'));
    audio.muted = false;
    // console.log("start")
    let isPlaying = false;
    const play = () => {
        if (!isPlaying) {
            isPlaying = true
            audio.play();
        }
    };

    audio.addEventListener('ended', () => {
        isPlaying = false;
    });
    return  { play }
};

export const notificationSound = _Sound();


/*
export const _Sound = () => {
    const audio = new Audio(notificationMusic);
    // const audio = new Audio(generateFileMediaUrl('/resources/notification-sound.mp3'));
    console.log('_Sound');
    audio.muted = false;
    let playing = false;
    const play = () => {
        playing = true;
        audio.play();
    };
    audio.addEventListener('ended', () => {
        //   console.log('audio ended');
        playing = false;
    });
    return function () {
        return {
            playing,
            play
        }
    }
};*/
