// Import packages
import React from "react";
import {useLocation} from "react-router-dom";

// Import Styles
import '../../assets/styles/cards/list-card.scss';

// Import components
import {LinkButton} from "../buttons/buttons";
import {MaterialIcon} from "../utils/Icon";
import {Checkbox} from "antd";
import {history} from "../../configs/history";
import {setPageOffset} from "../../redux/actions";

function ListItemCard(props) {
    const {
        data, status, actions, largeItems, largeItemsWidth, smallItemsWidth, detailsLink, editLink,
        selectingActive, getScrollPosition
    } = props;
    let location = useLocation();

    function onClick() {
        if (getScrollPosition) {
            const offset = getScrollPosition?.();
            // console.log(offset);
            const pageName = location.pathname.split('/')?.[1];
            setPageOffset({
                offset,
                pageName,
            })
        }
        history.push({
            pathname: `/${detailsLink}/${data.id}`,
        })
    }

    function onClickValueItem(e, redirect) {
        e.preventDefault();
        e.stopPropagation();
        if (getScrollPosition) {
            const offset = getScrollPosition?.();
            // console.log(offset);
            const pageName = location.pathname.split('/')?.[1];
            setPageOffset({
                offset,
                pageName,
            })
        }
        redirect &&history.push({
            pathname: redirect
        })
    }

    return <div className={`list-item-wrapper ${data?.newItem ? 'selected' : ''}`} onClick={data?.onClick}>
        {selectingActive && <span className={'selecting-checkbox'}>
                <Checkbox checked={data?.selected} onChange={data?.handleSelect}/>
            </span>}
        <div className={`general-info-content ${status ? "with-status" : ""}`} onClick={detailsLink && onClick}>
            {data?.values && !!data.values.length && data.values.map((item, index) => {
                const redirectedValue =typeof item === 'object' && item?.redirect;
                const width = largeItems.includes(index)
                    ? largeItemsWidth
                    : smallItemsWidth;

                return <span
                    onClick={(e)=>redirectedValue && onClickValueItem(e, item?.redirect)}
                    className={`general-info-item ${redirectedValue ? 'hovered':''}`}
                    style={{width: `${width}%`}}
                    key={index}>
                            {typeof item === 'object' ? item?.title : item}
                        </span>
            })}
        </div>
        <div className="item-actions-part">
            {status && <>
                {
                    typeof data?.status === 'boolean' ?
                        <div className="status">{data?.status ? 'Ակտիվ' : 'Ոչ ակտիվ'}</div>
                        : <span className={`status-badge`}
                                style={{backgroundColor: data?.status?.color + '14', color: data?.status?.color}}>
                    {data.status?.title}</span>
                }
            </>
            }
            {actions && <div className="actions">
                {!!actions.length && actions.map((item) => {
                    return (item === "edit" && editLink) ?
                        <LinkButton link={{pathname: `${editLink}/${data.id}`}}
                                    key={item}
                                    className={"edit-button"}
                                    title={<MaterialIcon icon="edit"/>}/>

                        : ((item === "delete" && data.deleteCb) ?
                            <LinkButton key={item}
                                        title={<MaterialIcon icon="delete"/>}
                                        className="bg-red"
                                        cb={data.deleteCb}/>
                            : ((item === "details" && detailsLink) ?
                                <LinkButton link={{
                                    pathname: `/${detailsLink}/${data.id}`
                                }} key={item} title={<MaterialIcon
                                    icon="keyboard_arrow_right"/>}/> : ""))
                })}
            </div>}
        </div>
    </div>

}

export default ListItemCard
